import * as React from "react";
import { Text, View, VStack, ScrollView, Heading, Divider } from "native-base";
import styles from "./styles";

export default function TermsOfServiceScreen() {
  return (
    <View flex={1} p={4} bg="white">
      <ScrollView>
        <VStack space={4}>
          <Heading size="lg" color="primary.600">Terms of Service</Heading>
          <Divider my={2} />

          <Text fontSize="md">
            Last updated: January 2025
          </Text>

          <Text fontSize="sm">
            Welcome to MyApp Inc.! These Terms of Service ("Terms") govern your use of our services.
          </Text>

          <Heading size="md" mt={4}>1. Acceptance of Terms</Heading>
          <Text fontSize="sm">
            By using our services, you agree to be bound by these Terms. If you do not agree, please do not use our services.
          </Text>

          <Heading size="md" mt={4}>2. User Responsibilities</Heading>
          <Text fontSize="sm">
            You agree not to misuse our services or engage in any illegal activities while using our platform.
          </Text>

          <Heading size="md" mt={4}>3. Account Security</Heading>
          <Text fontSize="sm">
            You are responsible for maintaining the confidentiality of your account and password.
          </Text>

          <Heading size="md" mt={4}>4. Service Availability</Heading>
          <Text fontSize="sm">
            We strive to provide continuous access to our services but do not guarantee uninterrupted availability.
          </Text>

          <Heading size="md" mt={4}>5. Changes to Terms</Heading>
          <Text fontSize="sm">
            We reserve the right to update these Terms at any time. Continued use of our services implies acceptance of the revised Terms.
          </Text>

          <Heading size="md" mt={4}>6. Contact Us</Heading>
          <Text fontSize="sm">
            If you have any questions about these Terms, contact us at support@myapp.com.
          </Text>
        </VStack>
      </ScrollView>
    </View>
  );
}
