import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  Select,
  View,
  Text,
  Center,
  useToast,
} from "native-base";
import styles from "./styles";
import {
  Buildings,
  Company,
  Email,
  Geo,
  License,
  Map,
  Parcel,
  Phone,
  User,
  World,
} from "../../assets/images/svg";
import { americanStatesShort } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateProfile } from "../../networking/profile/profileApi";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../config";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";

export default function EditProfileScreen() {
  const toast = useToast();
  // const isCompany = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const [address, setAddress] = useState({
    city: profile.address.city || "",
    state: profile.address.state || "",
    zip: profile.address.zip || "",
    county: profile.address.county || "",
    streetAddressLine1: profile.address.streetAddressLine1 || "",
    streetAddressLine2: profile.address.streetAddressLine2 || "",
  });
  const [streetLine, setStreetLine] = useState(
    profile.address.streetAddressLine1
  );

  const editProfile = async () => {
    if (
      !address.streetAddressLine1 ||
      !address.state ||
      !address.city ||
      !address.zip
    ) {
      toast.show({
        description: "Please fill in address fields.",
        ...toastErrorConfig,
      });
      return false;
    }
    try {
      dispatch(
        updateProfile({
          state: address.state,
          city: address.city,
          zip: address.zip,
          county: address.county || "",
          streetAddressLine1: address.streetAddressLine1,
          streetAddressLine2: address.streetAddressLine2,
        })
      );
      toast.show({
        description: "Profile was successfully updated",
        ...toastSuccessConfig,
      });
      navigate(-1);
    } catch (error) {
      toast.show({
        description: "Failed to update the profile",
        ...toastErrorConfig,
      });
    }
  };

  const updateAddressField = (key, value) => {
    setAddress((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <View style={styles.center}>
      <View style={styles.container} keyboardShouldPersistTaps={"handled"}>
        <Text style={styles.myProfileTitle}>Edit Address</Text>
        <View style={styles.divider} />
        <FormControl.Label>Street Address Line 1</FormControl.Label>
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 12,
              borderColor: isAddressFocused ? "#1A1A1A" : "#D9D9D9",
              backgroundColor: isAddressFocused ? "#F5F5F5" : "#FFFFFF",
              borderWidth: 1,
              height: 46,
              width: "100%",
              paddingHorizontal: 12,
              // outline: isAddressFocused ? "1px solid #1DCE80" : "none",
            },
          ]}
        >
          <View style={{ marginRight: 10 }}>
            <Company />
          </View>
          <Autocomplete
            value={streetLine || ""}
            // value={address.streetAddressLine1 || ""}
            placeholder="Street Address"
            style={{
              paddingLeft: 0,
              outline: "none",
              flex: 1,
              borderWidth: 0,
              fontSize: "16px",
              backgroundColor: isAddressFocused ? "#F5F5F5" : "#FFFFFF",
            }}
            types={["address"]}
            fields={["address_components"]}
            onChange={(e) => setStreetLine(e.target.value)}
            onPlaceSelected={(details) => {
              const streetNumber = details?.address_components.find(
                (component) => component.types.includes("street_number")
              )?.long_name;
              if (!streetNumber) return;
              const route = details?.address_components.find((component) =>
                component.types.includes("route")
              )?.long_name;
              const displayCity = details.address_components.filter(
                (f) =>
                  JSON.stringify(f.types) ===
                  JSON.stringify(["locality", "political"])
              )[0]?.long_name;
              // const displayState = details.address_components.filter(
              //   (f) =>
              //     JSON.stringify(f.types) ===
              //     JSON.stringify(["administrative_area_level_1", "political"])
              // )[0]?.long_name;
              const displayState = details.address_components.find(component => 
                component.types.includes("administrative_area_level_1")
              ).short_name
              const zip = details.address_components.filter(
                (f) =>
                  JSON.stringify(f.types) === JSON.stringify(["postal_code"])
              )[0]?.long_name;
              const formattedStreetAddress = `${streetNumber || ""} ${
                route || ""
              }`.trim();
              const county = details.address_components.find((component) =>
                component.types.includes("administrative_area_level_2")
              )?.long_name;
              updateAddressField("streetAddressLine1", formattedStreetAddress);
              updateAddressField("city", displayCity);
              updateAddressField("state", displayState);
              updateAddressField("zip", zip);
              updateAddressField("county", county)
              setStreetLine(formattedStreetAddress);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
            onFocus={() => setIsAddressFocused(true)}
            onBlur={() => setIsAddressFocused(false)}
          />
        </View>
        <View style={styles.divider} />
        <FormControl.Label>
          Street Address Line 2 (Apt, suit, other)
        </FormControl.Label>
        <Input
          type="text"
          value={address.streetAddressLine2}
          onChangeText={(value) =>
            updateAddressField("streetAddressLine2", value)
          }
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Buildings />
            </View>
          }
          placeholder="Street Address"
        />
        <View style={styles.divider} />
        <FormControl.Label>City</FormControl.Label>
        <Input
          type="text"
          value={address.city}
          onChangeText={(value) => updateAddressField("city", value)}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Geo />
            </View>
          }
          placeholder="City"
        />
        <View style={styles.divider} />
        <FormControl.Label>State</FormControl.Label>
        {/* <Select
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Map />
            </View>
          }
          onValueChange={(value) => updateAddressField("state", value)}
          selectedValue={address.state}
          minWidth="200"
          accessibilityLabel="State"
          placeholder="Registration State"
          mt="1"
        >
          {americanStatesShort.map((name) => (
            <Select.Item key={name} label={name} value={name} />
          ))}
        </Select> */}
         <Input
          type="text"
          value={address.state}
          onChangeText={(value) => updateAddressField("state", value)}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Map />
            </View>
          }
          placeholder="Registration State"
        />
        <View style={styles.divider} />
        <FormControl.Label>ZIP Code</FormControl.Label>
        <Input
          type="text"
          value={address.zip}
          onChangeText={(value) => updateAddressField("zip", value)}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <Parcel />
            </View>
          }
          placeholder="ZIP Code"
        />
        <View style={styles.divider} />
        <FormControl.Label>County (Optional)</FormControl.Label>
        <Input
          type="text"
          value={address.county}
          onChangeText={(value) => updateAddressField("county", value)}
          InputLeftElement={
            <View style={styles.leftIcon}>
              <World />
            </View>
          }
          placeholder="County"
        />
        <View style={styles.divider} />
        <View style={styles.divider} />
        <Button onPress={editProfile} style={styles.button}>
          Save
        </Button>
        <View style={styles.bottomSpace} />
        {/* {isCompany && (
          <FormControl>
            <FormControl.Label>Company Name</FormControl.Label>
            <Input
              type="text"
              isDisabled={true}
              value={"DispatchFull"}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Company stroke={"#737373"} />
                </View>
              }
            />
            <View style={styles.divider} />
            <FormControl.Label>License Number</FormControl.Label>
            <Input
              isDisabled={true}
              type="text"
              value={"122223231221"}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <License stroke={"#737373"} />
                </View>
              }
              placeholder="License Number"
            />
            <View style={styles.divider} />
            <FormControl.Label>Email</FormControl.Label>
            <Input
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              type="email"
              autoCorrect={false}
              autoCompleteType="email"
              value={""}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Email />
                </View>
              }
              placeholder="Email Address"
            />
            <View style={styles.divider} />
            <FormControl.Label>Phone Number</FormControl.Label>
            <Input
              value={"00000000000"}
              type="tel"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Phone />
                </View>
              }
              placeholder="Enter your Phone Number"
            />
            <View style={styles.divider} />
          </FormControl>
        )} */}
      </View>
    </View>
  );
}
