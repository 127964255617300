import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={36} height={36} rx={8} fill="#000000" />
      <Path
        d="M16.74 9.858l-4.158 1.567c-.959.358-1.742 1.492-1.742 2.508v6.192c0 .983.65 2.275 1.441 2.867l3.584 2.675c1.175.883 3.108.883 4.283 0l3.584-2.675c.791-.592 1.441-1.884 1.441-2.867v-6.192c0-1.025-.783-2.158-1.741-2.516l-4.159-1.559c-.708-.258-1.841-.258-2.533 0z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M15.543 17.892l1.342 1.341 3.583-3.583"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
