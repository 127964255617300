import * as React from "react";
import Svg, { Path, Defs, LinearGradient, Stop, G } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1440 503"
      width={112}
      height={40}
      fill="#fff"
    >
      <G>
        <Path
          className="st0"
          d="M110.7,201.4c-.6,3.6-.8,5-.8,7,0,3.6,2.2,6,5.8,6.8,3.8.8,14.8,1.4,25,1.4,16.8,0,33.8-.4,38.8-1,12.2-1.4,17-5.6,17-14.6s-1.8-6.6-4.8-7.6c-2.7-.9-11.1-1.5-25.2-1.6h-36.8c-18.2,0-29.4-1-35.8-3-10-3.2-15-10-15-20.6,0-15.4,6.2-34.8,13.8-43.2,11-12.2,27.8-15.4,80-15.4s49.6.8,59.4,4.6c8.2,3.2,12.4,9.8,12.4,19.8s-.6,9.6-2.6,17.4h-39.4c.4-2.2.6-3.6.6-4.6,0-7.2-4.8-8.6-28.2-8.6s-34.4.6-40.8,1.2c-8.2.8-13.2,6-13.2,13.6s1.2,5,3,6.2c3.4,2.2,3.6,2.2,19.8,2.6h37.8c2.4,0,9.2.2,19.8.6,14.8.6,19.6,1.2,25.4,4,8,3.4,11.8,10,11.8,20.2,0,15.2-5.6,33.4-13.2,42.4-10.8,12.8-27.6,16-83.2,16s-46-.6-56.4-2.8c-12.4-2.8-18-9.2-18-20.2s.6-10.2,3.2-20.6h39.8,0Z"
        />
        <Path
          className="st0"
          d="M273.9,110.6h40.6l-10.6,49.8h21.4l65.6-49.8h55.8l-90.6,66,69.6,67.4h-58.8l-49.4-51.2h-20.4l-11,51.2h-40.6s28.4-133.4,28.4-133.4Z"
        />
        <Path
          className="st0"
          d="M459.5,110.6h40.6l-28.4,133.4h-40.6s28.4-133.4,28.4-133.4Z"
        />
        <Path
          className="st0"
          d="M521.2,110.6h102c25.8,0,32,.8,39.6,5,8,4.4,12,12,12,22.6s-3.8,28.6-8.6,42.4c-5.4,15-16.2,25.4-29.6,28.4-8.2,1.6-15.6,2.2-31.2,2.2h-65.2l-7,32.8h-40.4s28.4-133.4,28.4-133.4ZM606,178.8c11.8,0,16.6-1.4,20.4-5.8,3.2-4,5.6-12,5.6-19.2s-1.6-7.6-5.2-9.2c-3.2-1.4-4.8-1.6-13.2-1.6h-59l-7.6,35.8h59Z"
        />
        <Path
          className="st0"
          d="M827,110.6h22l-93.8,77.4-12,56h-16l12-56-61-77.4h21.8l50,64.2s77-64.2,77-64.2Z"
        />
        <Path
          className="st0"
          d="M991,114.4c9,4,13.4,11.8,13.4,24.2s-10.8,65.6-17.8,80.2c-5.8,12.2-15,19.8-28.2,23.4-9.6,2.4-23.8,3.2-60.4,3.2s-54-1-63.8-5.2c-9-4-13.4-11.8-13.4-24.2s10.8-65.8,17.8-80.2c5.8-12.2,15-19.8,28.2-23.4,9.6-2.4,23.8-3.2,61.2-3.2s53.4,1,63,5.2ZM863,129.2c-8.2,5.2-11.2,11.8-17.4,38-4.6,19.8-7.8,38.4-7.8,45.2s2.6,12.8,8.4,15.2c8,3.2,16.2,3.8,54,3.8s53.8-.8,62-6,11.2-12,17.4-38c4.6-20,7.8-38.4,7.8-45.2,0-16.8-7.2-19-61.8-19s-54.8,1.2-62.6,6h0Z"
        />
        <Path
          className="st0"
          d="M1187,110.6l-19.2,90.6c-3.2,14.6-4.6,19-7.2,23.8-9.8,16.8-24.4,20.4-81.2,20.4s-48.8-1.2-57.6-4.4c-9.6-3.6-14.4-10-14.4-19.6s.6-8,3.2-20.2l19.2-90.6h16l-18.6,87.8c-2.6,11.8-2.8,13.4-2.8,16.6,0,7.2,3.4,11.8,10.6,13.8,6,1.6,22.4,2.6,47.4,2.6,42.8,0,53.8-1.6,60.8-9,4.8-5,5.6-7.4,9.2-24l18.6-87.8h16,0Z"
        />
        <Path
          className="st0"
          d="M1204.9,110.6h109.8c17,0,24.2,1.6,30.6,6.4,4.8,3.8,7.4,9.6,7.4,17s-2,23.2-5.2,32c-3,8.4-9.8,15.2-17.6,17.2-1.4.4-3,.6-7.6,1.2,3.8.6,5.4,1,7.2,1.8,4.6,2.2,8,7.2,8,12.2s-.6,6.2-1.6,11l-7.2,34.6h-16l5.4-25.4c1-5.4,1.8-11.2,1.8-14.4,0-6.4-3.2-10.8-9-12.6-3.8-1-7.6-1.4-15.4-1.4h-91.6l-11.4,53.8h-16l28.4-133.4h0ZM1294.5,177.6c17.2,0,25.2-2,31.2-7.8,3.6-3.6,5.6-7.4,7.6-15,1.6-5.6,2.4-11.2,2.4-15.6,0-11.2-8.2-15.2-30.8-15.2h-86.8l-11.4,53.6h87.8Z"
        />
        <Path
          className="st0"
          d="M245.5,260.6h120.4c15.4,0,23.4,1.2,29.4,4.8,7.2,4,10.4,9.8,10.4,18.6s-3.8,25.2-8.6,32c-4.4,6-9,8.4-19.6,10.4,13.8,1.4,20.4,8,20.4,19.8s-5.8,29-14.6,37c-8.6,8.2-18.6,10.8-40.2,10.8h-126l28.4-133.4h0ZM335.6,364.4c9.6,0,11.6-.2,14.8-2,4-2.4,6.8-8,6.8-13.4s-1.6-6.8-4.8-8c-2.2-.8-3.8-1-12.8-1h-71.6l-5,24.4h72.6ZM341.8,313.8c9.4,0,12.6-.6,15.6-2.8,3.4-2.4,5.6-7.4,5.6-11.8s-1.6-6.8-5.2-8-4.2-1-12-1h-67.2l-5,23.6h68.2Z"
        />
        <Path
          className="st0"
          d="M435.2,260.6h115c35.4,0,45.4,5.8,45.4,26.2s-4.4,34-10.2,43c-5.2,7.8-10.6,11-22.6,13.6,6,.8,7.8,1,10.6,2,4.2,1.6,6.8,5.2,6.8,10s-.2,4.8-2.6,17.6l-4.6,21h-41l3.4-16.6c.6-2.6,1-5.2,1-7.2,0-4.4-1.8-7.2-5.6-8.6-2.2-.8-6.2-1.2-14-1.2h-62.4l-7.2,33.6h-40.4l28.4-133.4h0ZM524.8,327.8c11.8,0,16.4-.8,20.4-3.4,3.2-2.2,4.6-5,6.2-11.6.8-4,1.4-8,1.4-10.2,0-4.2-2-7-6-8.2-3.2-1-4.4-1-13.2-1h-65l-7.4,34.4h63.6,0Z"
        />
        <Path
          className="st0"
          d="M810.1,260.6h40.6l-10.6,49.8h21.4l65.6-49.8h55.8l-90.6,66,69.6,67.4h-58.8l-49.4-51.2h-20.4l-11,51.2h-40.6l28.4-133.4Z"
        />
        <Path
          className="st0"
          d="M1143.3,260.6l-6.4,30.2h-107.6l-5,23h101.8l-5.6,26.4h-101.8l-5,23.6h109.2l-6.4,30.2h-149.2l28.4-133.4h147.6,0Z"
        />
        <Path
          className="st0"
          d="M1162.7,260.6h115c35.4,0,45.4,5.8,45.4,26.2s-4.4,34-10.2,43c-5.2,7.8-10.6,11-22.6,13.6,6,.8,7.8,1,10.6,2,4.2,1.6,6.8,5.2,6.8,10s-.2,4.8-2.6,17.6l-4.6,21h-41l3.4-16.6c.6-2.6,1-5.2,1-7.2,0-4.4-1.8-7.2-5.6-8.6-2.2-.8-6.2-1.2-14-1.2h-62.4l-7.2,33.6h-40.4l28.4-133.4h0ZM1252.3,327.8c11.8,0,16.4-.8,20.4-3.4,3.2-2.2,4.6-5,6.2-11.6.8-4,1.4-8,1.4-10.2,0-4.2-2-7-6-8.2-3.2-1-4.4-1-13.2-1h-65l-7.4,34.4h63.6Z"
        />
      </G>
      <Path
        className="st0"
        d="M769.7,264.4c-9.6-4.2-21.2-5.2-63-5.2s-51.6.8-61.2,3.2c-13.2,3.6-22.4,11.2-28.2,23.4-7,14.4-17.8,63.6-17.8,80.2s4.4,20.2,13.4,24.2c9.8,4.2,21.2,5.2,63.8,5.2s50.8-.8,60.4-3.2c13.2-3.6,22.4-11.2,28.2-23.4,7-14.6,17.8-63.4,17.8-80.2s-4.4-20.2-13.4-24.2h0ZM644.7,363.8l15.7-73.7,39.6,36.8-55.3,36.8h0ZM722.5,363.8h-21.5l15.6-73.7h21.4l-15.5,73.7Z"
      />
    </Svg>
  );
}

export default SvgComponent;
