import * as React from "react";
import { Text, View, VStack, ScrollView, Heading, Divider } from "native-base";
import styles from "./styles";

export default function PrivacyPolicyScreen() {
  return (
    <View flex={1} p={4} bg="white">
      <ScrollView>
        <VStack space={4}>
          <Heading size="lg" color="primary.600">Privacy Policy</Heading>
          <Divider my={2} />

          <Text fontSize="md">
            Last updated: January 2025
          </Text>

          <Text fontSize="sm">
            At MyApp Inc., we value your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data when using our services.
          </Text>

          <Heading size="md" mt={4}>1. Information We Collect</Heading>
          <Text fontSize="sm">
            We collect personal information such as your name, email, and phone number when you register. Additionally, we collect usage data to improve our services.
          </Text>

          <Heading size="md" mt={4}>2. How We Use Your Data</Heading>
          <Text fontSize="sm">
            We use your data to provide and improve our services, personalize user experience, and communicate important updates.
          </Text>

          <Heading size="md" mt={4}>3. Sharing Your Information</Heading>
          <Text fontSize="sm">
            We do not sell your personal data. We may share data with third-party services only when required for service functionality or legal compliance.
          </Text>

          <Heading size="md" mt={4}>4. Your Rights</Heading>
          <Text fontSize="sm">
            You have the right to access, update, or delete your data. Contact us at privacy@myapp.com for any concerns.
          </Text>

          <Heading size="md" mt={4}>5. Contact Us</Heading>
          <Text fontSize="sm">
            If you have any questions about this policy, contact us at privacy@myapp.com.
          </Text>
        </VStack>
      </ScrollView>
    </View>
  );
}
