import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";
import instance from "../axios";
import axios from "axios";

const handleRejectWithValue = (error, rejectWithValue) => {
  if (error.response) {
    // Return only serializable data
    return rejectWithValue({
      status: error.response.status,
      data: error.response.data,
    });
  }
  return rejectWithValue(error.message);
};

export const createOrder = createAsyncThunk(
  "order/create",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/api/v1/orders`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const editOrder = createAsyncThunk(
  "order/edit",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/api/v1/orders/${id}`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const respondToEditOrderRequestFromTMS = createAsyncThunk(
  "order/respond-to-edit-from-tms",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${id}/respond`,
        data
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const requestEditOrder = createAsyncThunk(
  "order/request-edit",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await instance.put(
        `${API_URL}/api/v1/orders/${id}/request-edit`,
        data
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const unpublishOrder = createAsyncThunk(
  "order/unpublish",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${id}/unpublish`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendOrderToTMS = createAsyncThunk(
  "order/sendToTMS",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.id}/send-to-tms`,
        data
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getOrderBids = createAsyncThunk(
  "bids/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/api/v1/orders/${id}/bids`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getInfoByVinNumber = createAsyncThunk(
  "vin/get",
  async ({ vin }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/api/v1/orders/cars/${vin}`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "order/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.delete(`${API_URL}/api/v1/orders/${id}`);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendCounterBid = createAsyncThunk(
  "bids/counterbid",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.orderID}/bids/${data.bidID}/counterbid`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const declineBid = createAsyncThunk(
  "bids/decline",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.orderID}/bids/${data.bidID}/decline`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const disputeOrder = createAsyncThunk(
  "order/dispute",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.id}/dispute`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const rateOrder = createAsyncThunk(
  "order/rate",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.id}/rate`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const acceptBid = createAsyncThunk(
  "order/accept",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.orderID}/bids/${data.bidID}/accept`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendInfo = createAsyncThunk(
  "order/sendInfo",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/sendgrid/order/${data.id}/sendinfo`,
        data
      );
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const sendSMS = createAsyncThunk(
  "order/sendSMS",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/verification/sendsms`, data);
      return res.data;
    } catch (error) {
      console.log("error", error);
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const cancelOrderWithFee = createAsyncThunk(
  "order/cancelWithFee",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${orderId}/cancel/with-fee`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const cancelOrderWithoutFee = createAsyncThunk(
  "order/cancelWithoutFee",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${orderId}/cancel/without-fee`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const setCarrierProblemStatus = createAsyncThunk(
  "order/setCarrierProblemStatus",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.put(
        `${API_URL}/api/v1/orders/${orderId}/status/carrier-problem`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getReview = createAsyncThunk(
  "order/get-review",
  async (orderId, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        `${API_URL}/api/v1/orders/${orderId}/review`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getPlaces = createAsyncThunk(
  "user/get-places",
  async (_, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/auth/profile/places`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const createPlace = createAsyncThunk(
  "user/create-place",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/auth/profile/places`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getDriver = createAsyncThunk(
  "order/get-driver",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${API_URL}/api/v1/orders/${id}/driver`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const respondToOrderCancellation = createAsyncThunk(
  "order/respond-to-order-cancellation",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.post(
        `${API_URL}/api/v1/orders/${data.id}/respond-to-cancel-order`,
        { respondType: data.respondType }
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);
