import * as React from "react";
import styles from "./styles";
import { Menu, Pressable, Text, Box, View, useToast } from "native-base";
import { ArrowDown } from "../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { globalLogout } from "../../networking/globalActions";

export default function UserProfileButton({ name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getInitials = (name) => {
    const words = name.split(" ");
    let initials = "";
    words.forEach((word, index) => {
      if (index === 0 || index === words.length - 1) {
        initials += word.charAt(0).toUpperCase();
      }
    });
    return initials;
  };
  const logOut = () => {
    dispatch(globalLogout());
    navigate("/");
  };
  const initials = getInitials(name || "");
  return (
    <Box alignItems="center">
      <Menu
        placement={"bottom right"}
        w="188"
        trigger={(triggerProps) => {
          return (
            <Pressable accessibilityLabel="More options menu" {...triggerProps}>
              <View style={styles.userProfileButtonWrapper}>
                <View style={styles.userAvatarWrapper}>
                  <Text style={styles.userAvatarText}>{initials}</Text>
                </View>
                <View>
                  <ArrowDown />
                </View>
              </View>
            </Pressable>
          );
        }}
      >
        <Text style={styles.menuName}>{name}</Text>
        <Menu.Item
          onPress={() => {
            navigate("/profile");
          }}
        >
          View Profile
        </Menu.Item>
        <Menu.Item
          onPress={() => {
            navigate("/help");
          }}
        >
          Help Center
        </Menu.Item>
        <Menu.Item
          onPress={() => {
            navigate("/privacy-policy");
          }}
        >
          Privacy Policy
        </Menu.Item>
        <Menu.Item
          onPress={() => {
            navigate("/terms");
          }}
        >
          Terms of Service
        </Menu.Item>
        <Menu.Item
          onPress={() => {
            navigate("/report-problem");
          }}
        >
          Report Problem
        </Menu.Item>
        <Menu.Item onPress={logOut}>Log out</Menu.Item>
      </Menu>
    </Box>
  );
}
