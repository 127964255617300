const styles = {
  header: {
    color: "#171717",
    fontSize: 20,
    fontWeight: "700",
    // marginTop: 20,
  },
  imagesRow: {
    marginTop: 10,
  },
  image: {
    height: 250,
    width: "100%",
    borderRadius: 8,
    marginBottom: 10,
  },
  activeImage: {
    borderColor: "#000000",
  },
  imageSmall: {
    height: 60,
    width: 90,
    marginHorizontal: 5,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: "#ddd",
  },
  badges: {
    flexDirection: "row",
    marginTop: 10,
  },
  badge: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#DAF8EA",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    borderRadius: 8,
  },
  vinBadge: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: "row",
    borderRadius: 10,
    backgroundColor: "#fff",
    marginTop: 10,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 16,
    fontWeight: "700",
    marginTop: 20,
    marginBottom: 10,
  },
  vinText: {
    marginLeft: 12,
  },
  greyText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#737373",
  },
  row: {
    flexDirection: "row",
  },
   vehicle: {
  //  borderWidth: 1,
   // borderColor: "#E5E5E5",
   // borderRadius: 12,
    padding: 10,
   // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  // marginBottom: 10
   },
   badgeText: {
    marginLeft: 4,
   }
};

export default styles;
