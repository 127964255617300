import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={32} height={32} rx={8} fill="#000000" />
      <Path
        d="M19.336 21.667h-6.667c-2 0-3.333-1-3.333-3.334v-4.666c0-2.334 1.333-3.334 3.333-3.334h6.667c2 0 3.333 1 3.333 3.334v4.666c0 2.334-1.333 3.334-3.333 3.334z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M19.33 14l-2.086 1.667c-.687.546-1.813.546-2.5 0L12.664 14"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
