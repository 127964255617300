import * as React from "react";
import { Button, Text, View, useToast, Box } from "native-base";
import styles from "./styles";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfile,
  updateProfileEmail,
  updateProfilePhone,
} from "../../networking/profile/profileApi";
import { loginUser } from "../../networking/auth/authApi";
import { setUser } from "../../networking/auth/authSlice";
import {
  updateProfileState,
  setResendCooldown,
  clearResendCooldown,
} from "../../networking/profile/profileSlice";

export default function ConfirmCodeScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, data, action } = useParams();
  const toast = useToast();
  const [code, setCode] = useState("");
  const { state, city, zip, county, streetAddressLine1, streetAddressLine2 } =
    useSelector((state) => state.profile.address);
  const cooldownEndTime = useSelector(
    (state) => state.profile.resendCooldownEndTime
  );
  const [cooldown, setCooldown] = useState(0);

  const verifyCode = async () => {
    try {
      await axios
        .post(`${API_URL}/verification/verify`, {
          to: data,
          code,
        })
        .then(async () => {
          setCode("");
          if (action === "update") {
            const updateAction =
              type === "email" ? updateProfileEmail : updateProfilePhone;
            await dispatch(updateAction({ [type]: data })).unwrap();
            toast.show({
              description: `${
                type.charAt(0).toUpperCase() + type.slice(1)
              } updated successfully.`,
              ...toastSuccessConfig,
            });

            navigate("/profile");
          } else if (action === "signin") {
            const credential =
              type === "email" ? { email: data } : { phone: data };
            await dispatch(loginUser(credential)).unwrap();
            navigate("/orders", { replace: true });
          } else if (action === "signup1" || action === "signup2") {
            // toast.show({
            //   description:
            //     "Verification successful! Proceeding to the next step",
            //   ...toastSuccessConfig,
            // });
            if (type === "email") {
              dispatch(setUser({ isEmailVerified: true, email: data }));
            } else if (type === "phone") {
              dispatch(setUser({ isPhoneVerified: true, phone: data }));
            }
            navigate("/signup/user/1");
          } else if (action === "verify") {
            const updateAction =
              type === "email" ? updateProfileEmail : updateProfilePhone;
            await dispatch(updateAction({ [type]: data })).unwrap();
            toast.show({
              description: `${
                type.charAt(0).toUpperCase() + type.slice(1)
              } verified successfully.`,
              ...toastSuccessConfig,
            });
            if (type === "email") {
              dispatch(updateProfileState({ isEmailVerified: true }));
              dispatch(
                updateProfile({
                  state,
                  city,
                  zip,
                  county,
                  streetAddressLine1,
                  streetAddressLine2,
                  isEmailVerified: true,
                  email: data,
                })
              );
            } else if (type === "phone") {
              dispatch(updateProfileState({ isPhoneVerified: true }));
              dispatch(
                updateProfile({
                  state,
                  city,
                  zip,
                  county,
                  streetAddressLine1,
                  streetAddressLine2,
                  isPhoneVerified: true,
                  phone: data,
                })
              );
            }
            navigate("/orders", { replace: true });
          }
        })
        .catch((err) => {
          toast.show({
            description: "Incorrect Verification Code",
            ...toastErrorConfig,
          });
        });
    } catch (error) {
      setCode("");
      toast.show({
        description: "Verification failed. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const resendCode = async () => {
    const now = Date.now();

    if (cooldownEndTime && now < cooldownEndTime) {
      toast.show({
        description: `Please wait ${Math.ceil(
          (cooldownEndTime - now) / 1000
        )} seconds before resending`,
        ...toastErrorConfig,
      });
      return;
    }

    try {
      const verificationData =
        type === "email" ? { email: data } : { phone: data };

      await axios.post(`${API_URL}/verification/${type}`, verificationData);

      const newCooldownEndTime = now + 60000; // 60 seconds
      dispatch(setResendCooldown(newCooldownEndTime));

      toast.show({
        description: "New code was sent successfully",
        ...toastSuccessConfig,
      });
      setCode("");
    } catch (error) {
      // Check for specific max attempts error
      const errorMessage =
        error.response?.data?.message === "Max send attempts reached"
          ? "You have reached the maximum number of resend attempts. Please try again later."
          : "Failed to resend code. Please try again.";

      toast.show({
        description: errorMessage,
        ...toastErrorConfig,
      });
    }
  };

  const backToPrevious = () => {
    navigate(-1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (cooldownEndTime) {
        const now = Date.now();
        const remainingTime = Math.max(0, cooldownEndTime - now);
        setCooldown(Math.ceil(remainingTime / 1000));

        if (remainingTime <= 0) {
          dispatch(clearResendCooldown());
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cooldownEndTime, dispatch]);

  useEffect(() => {
    if (code.length === 6) {
      verifyCode();
    }
  }, [code]);

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.container}>
        <Text fontSize="2xl" bold pb={3} textAlign={"center"}>
          Enter verification code
        </Text>
        <Text style={styles.subheader}>
          OTP has been sent to <Text bold>{data}</Text>. Please enter the code
          below.
        </Text>

        <OtpInput
          autofocus
          value={code}
          onChange={setCode}
          numInputs={6}
          inputType="tel"
          containerStyle={{ justifyContent: "center" }}
          inputStyle={styles.inputOpt}
          shouldAutoFocus={true}
          renderInput={(props) => (
            <input
              {...props}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  verifyCode(); // Trigger verification on Enter
                }
              }}
            />
          )}
        />

        <View style={{ marginVertical: 20 }}>
          <Text fontSize="sm" textAlign={"center"}>
            Didn’t receive the OTP?{" "}
            <Text onPress={resendCode} style={styles.actionText}>
              {cooldown > 0 ? `Resend in ${cooldown}s` : "Resend OTP"}
            </Text>
          </Text>
        </View>
        {/* <View style={styles.buttonContainer}>
          <Button onPress={verifyCode}>Continue</Button>
        </View> */}

        <Button
          textAlign={"center"}
          style={styles.actionText}
          onPress={backToPrevious}
          variant="outline"
        >
          <Text>Go back</Text>
        </Button>
      </Box>
    </Box>
  );
}
