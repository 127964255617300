const styles = {
  wrapper: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    paddingHorizontal: 10,
    paddingVertical: 16,
    borderTopWidth: 1,
    borderColor: "#D4D4D4",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  button: {
    minWidth: 100,
    maxWidth: 160, 
    marginHorizontal: 5,
    borderColor: "#000",
  },
};

export default styles;
