import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M1.666 18.333h16.667M14.167 1.667H5.833C3.333 1.667 2.5 3.158 2.5 5v13.333h15V5c0-1.842-.833-3.333-3.333-3.333zM5.834 13.75h2.5M11.666 13.75h2.5M5.834 10h2.5M11.666 10h2.5M5.834 6.25h2.5M11.666 6.25h2.5"
        stroke={props.stroke || '#000000'}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
