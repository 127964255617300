import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Image,
  Pressable,
  Text,
  View,
  FormControl,
  Input,
  Button,
  Checkbox,
  Popover,
  Center,
  FlatList,
  TextArea,
} from "native-base";
import Select from "react-select";
import { imageHost, API_URL } from "../../config";
import { Vin, Model, Year, Upload } from "../../assets/images/svg";
import styles from "./styles";
import { carsList } from "../../assets/data/carsList";
import ReactImageUploading from "react-images-uploading";
import { IoAlertCircleOutline } from "react-icons/io5";
import ImagePlaceholderSvg from "../../assets/images/svg/imagePlaceholder";
import instance from "../../networking/axios";
import { carsObj } from "../../assets/data/carsList";

const VehicleForm = React.memo(
  ({
    vehicle,
    index,
    handleChangeVehicleDetail,
    removeVehicle,
    validateSingleField,
    getByVin,
    isMobile,
    validationErrors,
    active,
    years,
  }) => {
    const [localVehicle, setLocalVehicle] = useState(vehicle);

    const models = useMemo(
      () => carsObj[localVehicle.brand] || [],
      [localVehicle.brand]
    );

    useEffect(() => {
      setLocalVehicle(vehicle);
    }, [vehicle]);

    const handleChange = useCallback((key, value) => {
      setLocalVehicle((prev) => ({ ...prev, [key]: value }));
    }, []);

    const handleBlur = useCallback(
      (key, value) => {
        handleChangeVehicleDetail(index, { [key]: value });
        validateSingleField(key, value, index);
      },
      [index, handleChangeVehicleDetail, validateSingleField]
    );

    const uploadResource = async ({ file }) => {
      try {
        const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

        const formData = new FormData();
        formData.append("file", file, formattedFileName);

        const result = await instance.post(
          `${API_URL}/api/v1/orders/upload`,
          formData
        );

        if (result?.data?.fileName) {
          // Return the full image URL for Redux state update
          return `${result.data.fileName}`;
        }
        return null;
      } catch (error) {
        console.error("Error uploading image: ", error);
        return null; // Return null to indicate failure
      }
    };

    const onChange = async (imageList, index) => {
      // Use Promise.all to upload images in parallel

      const uploadPromises = imageList
        .filter((image) => typeof image !== "string") // Ignore already-uploaded files
        .map((image) => uploadResource(image)); // Only upload new files
      try {
        const uploadedImages = await Promise.all(uploadPromises);

        const newImages = uploadedImages.filter((img) => img !== null);

        const updatedImages = [...localVehicle.images, ...newImages];
        handleChangeVehicleDetail(index, { images: updatedImages });
        validateSingleField("images", updatedImages, index);
      } catch (error) {
        console.error("Error uploading multiple images: ", error);
      }
    };

    return (
      <View key={index}>
        <View style={styles.vehicleForm}>
          <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}>
            Vehicle # {index + 1}
          </Text>
          <View style={styles.inputsGrid}>
            <View style={styles.inputGrid}>
              <FormControl.Label>VIN*</FormControl.Label>
              <View
                style={[
                  { borderRadius: "14px" },
                  validationErrors[index]?.vin
                    ? { borderColor: "#FF0000", borderWidth: 1 }
                    : {},
                ]}
              >
                <Input
                  autoCapitalize={"characters"}
                  isDisabled={location.pathname.includes("request-edit")}
                  type="text"
                  value={localVehicle.vin}
                  onChangeText={(value) =>
                    handleChange("vin", value.toUpperCase())
                  }
                  onKeyPress={(event) => {
                    if (event.nativeEvent.key === "Enter") {
                      const inputValue = event.target.value.trim();
                      getByVin(inputValue, index);
                    }
                  }}
                  onBlur={(e) =>
                    handleBlur("vin", e.nativeEvent.text.trim().toUpperCase())
                  }
                  InputRightElement={
                    <Button
                      style={styles.searchButton}
                      isDisabled={location.pathname.includes("request-edit")}
                      onPress={() => getByVin(localVehicle.vin, index)}
                    >
                      Search
                    </Button>
                  }
                  placeholder="VIN"
                />
              </View>
            </View>
            <View style={styles.inputGrid}>
              <FormControl.Label>Make*</FormControl.Label>
              <View
                style={[
                  { borderRadius: "14px" },
                  validationErrors[index]?.brand
                    ? { borderColor: "#FF0000", borderWidth: 1 }
                    : {},
                ]}
              >
                <Select
                  value={
                    carsList.find((car) => car === localVehicle.brand)
                      ? { label: localVehicle.brand, value: localVehicle.brand }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleBlur("brand", selectedOption.value)
                  }
                  options={carsList.map((car) => ({ label: car, value: car }))}
                  isDisabled={location.pathname.includes("request-edit")}
                  menuPortalTarget={document.body} // Fix z-index issue
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "44px",
                      borderRadius: "12px",
                      borderWidth: "1px",
                      bborderColor: validationErrors[index]?.brand
                        ? "#FF0000"
                        : state.isFocused
                        ? "#1A1A1A"
                        : "#A0A0A0",
                      backgroundColor: state.isFocused ? "#F5F5F5" : "#FFFFFF",
                      boxShadow: state.isFocused
                        ? "0 0 4px rgba(26, 26, 26, 0.5)"
                        : "none",
                      outlineOffset: state.isFocused ? "2px" : "0",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        borderColor: "#1A1A1A",
                        backgroundColor: "#F5F5F5",
                      },
                      outline: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      backgroundColor: "#FFFFFF",
                      borderRadius: "12px",
                      border: "1px solid #A0A0A0",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#1A1A1A"
                        : state.isFocused
                        ? "#F5F5F5"
                        : "#FFFFFF",
                      color: state.isSelected ? "#FFFFFF" : "#333333",
                      padding: "10px",
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        backgroundColor: "#E0E0E0",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#1A1A1A",
                      fontSize: "16px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "#A0A0A0",
                      "&:hover": { color: "#1A1A1A" },
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                  }}
                />
              </View>
            </View>
            <View style={styles.inputGrid}>
              <FormControl.Label>Model*</FormControl.Label>
              <View
                style={[
                  { borderRadius: "14px" },
                  validationErrors[index]?.model
                    ? { borderColor: "#FF0000", borderWidth: 1 }
                    : {},
                ]}
              >
                <Select
                  value={
                    models.find((model) => model === localVehicle.model)
                      ? { label: localVehicle.model, value: localVehicle.model }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleBlur("model", selectedOption.value)
                  }
                  options={models.map((model) => ({
                    label: model,
                    value: model,
                  }))}
                  isDisabled={
                    !vehicle.brand || location.pathname.includes("request-edit")
                  }
                  menuPortalTarget={document.body} // Fix z-index issue
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "44px",
                      borderRadius: "12px",
                      borderWidth: "1px",
                      bborderColor: validationErrors[index]?.model
                        ? "#FF0000"
                        : state.isFocused
                        ? "#1A1A1A"
                        : "#A0A0A0",
                      backgroundColor: state.isFocused ? "#F5F5F5" : "#FFFFFF",
                      boxShadow: state.isFocused
                        ? "0 0 4px rgba(26, 26, 26, 0.5)"
                        : "none",
                      outlineOffset: state.isFocused ? "2px" : "0",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        borderColor: "#1A1A1A",
                        backgroundColor: "#F5F5F5",
                      },
                      outline: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      backgroundColor: "#FFFFFF",
                      borderRadius: "12px",
                      border: "1px solid #A0A0A0",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#1A1A1A"
                        : state.isFocused
                        ? "#F5F5F5"
                        : "#FFFFFF",
                      color: state.isSelected ? "#FFFFFF" : "#333333",
                      padding: "10px",
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        backgroundColor: "#E0E0E0",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#1A1A1A",
                      fontSize: "16px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "#A0A0A0",
                      "&:hover": { color: "#1A1A1A" },
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                  }}
                />
              </View>
            </View>
            <View style={styles.inputGrid}>
              <FormControl.Label>Year*</FormControl.Label>
              <View
                style={[
                  { borderRadius: "14px" },
                  validationErrors[index]?.year
                    ? { borderColor: "#FF0000", borderWidth: 1 }
                    : {},
                ]}
              >
                <Select
                  value={
                    years.includes(localVehicle.year.toString())
                      ? {
                          label: localVehicle.year.toString(),
                          value: localVehicle.year.toString(),
                        }
                      : null
                  }
                  onChange={(selectedOption) =>
                    handleBlur("year", selectedOption.value)
                  }
                  options={years.map((year) => ({ label: year, value: year }))}
                  isDisabled={location.pathname.includes("request-edit")}
                  menuPortalTarget={document.body} // Fix z-index issue
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "44px",
                      borderRadius: "12px",
                      borderWidth: "1px",
                      bborderColor: validationErrors[index]?.year
                        ? "#FF0000"
                        : state.isFocused
                        ? "#1A1A1A"
                        : "#A0A0A0",
                      backgroundColor: state.isFocused ? "#F5F5F5" : "#FFFFFF",
                      boxShadow: state.isFocused
                        ? "0 0 4px rgba(26, 26, 26, 0.5)"
                        : "none",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        borderColor: "#1A1A1A",
                        backgroundColor: "#F5F5F5",
                      },
                      outline: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      backgroundColor: "#FFFFFF",
                      borderRadius: "12px",
                      border: "1px solid #A0A0A0",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#1A1A1A"
                        : state.isFocused
                        ? "#F5F5F5"
                        : "#FFFFFF",
                      color: state.isSelected ? "#FFFFFF" : "#333333",
                      padding: "10px",
                      cursor: "pointer",
                      transition: "all 0.2s ease-in-out",
                      "&:hover": {
                        backgroundColor: "#E0E0E0",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#1A1A1A",
                      fontSize: "16px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      color: "#A0A0A0",
                      "&:hover": { color: "#1A1A1A" },
                    }),
                    indicatorSeparator: () => ({
                      display: "none",
                    }),
                  }}
                />
              </View>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.row}>
            <Checkbox
              value="checkbox"
              isChecked={localVehicle.isInoperable}
              onChange={(value) => {
                handleChangeVehicleDetail(index, {
                  isInoperable: value,
                });
              }}
              accessibilityLabel="checkbox"
            />
            <Popover
              trigger={(triggerProps) => {
                return (
                  <Pressable {...triggerProps}>
                    <View style={styles.row}>
                      <Text style={styles.textContainer}>
                        The vehicle is inoperable{" "}
                      </Text>
                      <IoAlertCircleOutline
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </View>
                  </Pressable>
                );
              }}
            >
              <Popover.Content maxWidth="350px">
                <Popover.Arrow bg="gray.100" />
                <Popover.CloseButton />
                <Popover.Header bg="gray.100">
                  The vehicle is inoperable
                </Popover.Header>
                <Popover.Body bg="gray.100">
                  Confirm if the vehicle is inoperable and unable to start or
                  move under its own power.
                </Popover.Body>
              </Popover.Content>
            </Popover>
          </View>

          <View style={styles.divider} />
          <View style={styles.row}>
            <Checkbox
              value="checkbox"
              isChecked={localVehicle.isTrailerEnclosed}
              onChange={(value) => {
                handleChangeVehicleDetail(index, {
                  isTrailerEnclosed: value,
                });
              }}
              accessibilityLabel="checkbox"
            />
            <Popover
              trigger={(triggerProps) => {
                return (
                  <Pressable {...triggerProps}>
                    <View style={styles.row}>
                      <Text style={styles.textContainer}>
                        The vehicle needs enslosed trailer{" "}
                      </Text>
                      <IoAlertCircleOutline
                        size={18}
                        style={{ cursor: "pointer" }}
                      />
                    </View>
                  </Pressable>
                );
              }}
            >
              <Popover.Content maxWidth="350px">
                <Popover.Arrow bg="gray.100" />
                <Popover.CloseButton />
                <Popover.Header bg="gray.100">Trailer Type</Popover.Header>
                <Popover.Body bg="gray.100">
                  If your vehicle requires additional security during
                  transportation, we recommend our enclosed trailer option. This
                  is ideal for luxury, classic, or exotic cars as it provides a
                  closed environment, shielding the vehicle from weather and
                  road debris. In contrast, an open trailer is more economical
                  and suitable for standard vehicles, but does not offer the
                  same level of protection as an enclosed trailer. An enclosed
                  trailer typically costs $500-700 more than an open trailer.
                </Popover.Body>
              </Popover.Content>
            </Popover>
          </View>
          <View style={styles.divider} />
          <>
            <View style={styles.row}>
              <Center>
                <Checkbox
                  value="checkbox"
                  isChecked={
                    localVehicle.hasCustom || localVehicle.customChanges
                  }
                  onChange={(value) => {
                    handleChangeVehicleDetail(index, {
                        hasCustom: value,
                      });
                    if (!value) {
                      handleChangeVehicleDetail(index, {
                        customChanges: "",
                      });
                    }
                  }}
                  accessibilityLabel="checkbox"
                />
              </Center>

              <Text style={styles.textContainer}>
                The vehicle is custom/modified
              </Text>
            </View>
            {(localVehicle.hasCustom || localVehicle.customChanges) && (
              <>
                <View style={styles.divider} />
                <TextArea
                  value={localVehicle.customChanges}
                  onChangeText={(value) => handleChange("customChanges", value)}
                  //   onBlur={(e) => {
                  //     handleChangeVehicleDetail(index, {
                  //       customChanges: e.nativeEvent.text,
                  //     });
                  //   }}
                  onBlur={(e) =>
                    handleBlur("customChanges", e.nativeEvent.text.trim())
                  }
                  numberOfLines={2}
                  placeholder="Is the vehicle lifted? Does it have racks? Please describe any aftermarket modifications."
                />
              </>
            )}
          </>
          <View style={styles.divider} />
          <>
            <View style={styles.row}>
              <Center>
                <Checkbox
                  value="checkbox"
                  isChecked={
                    localVehicle.havePersonal || localVehicle.personalItems
                  }
                  onChange={(value) => {
                    handleChangeVehicleDetail(index, {
                        havePersonal: value,
                      });
                    if (!value) {
                      handleChangeVehicleDetail(index, {
                        personalItems: "",
                      });
                    }
                  }}
                  accessibilityLabel="checkbox"
                />
              </Center>

              <Text style={styles.textContainer}>
                I will have personal items in my vehicle
              </Text>
            </View>
            {(localVehicle.havePersonal || localVehicle.personalItems) && (
              <>
                <View style={styles.divider} />
                <TextArea
                  value={localVehicle.personalItems}
                  onChangeText={(value) => handleChange("personalItems", value)}
                  onBlur={(e) => {
                    handleChangeVehicleDetail(index, {
                      personalItems: e.nativeEvent.text,
                    });
                  }}
                  numberOfLines={2}
                  placeholder="Please describe the items and their approximate weight"
                />
              </>
            )}
          </>
          <View style={styles.divider} />
          <FormControl.Label>
            Vehicle images. Please add 1-10 images of this vehicle:{" "}
          </FormControl.Label>
          <ReactImageUploading
            multiple
            value={vehicle.images}
            onChange={(imageList) => onChange(imageList, index)}
            maxNumber={10}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => {
              const dragPropsCustom = {
                ...dragProps,
                onDragOver: (event) => {
                  event.preventDefault();
                  if (dragProps.onDragOver) dragProps.onDragOver(event);
                },
                onDrop: (event) => {
                  event.preventDefault();
                  if (dragProps.onDrop) dragProps.onDrop(event);
                },
              };

              return (
                <View>
                  <View style={{ marginVertical: 10 }}>
                    <FlatList
                      data={imageList}
                      horizontal
                      showsHorizontalScrollIndicator={true}
                      keyExtractor={(item, imgIndex) => imgIndex.toString()}
                      renderItem={({ item, index: imgIndex }) => {
                        const imageUrl = `${item.fileName || item}`;
                        return (
                          <View key={imgIndex} style={{ marginRight: 10 }}>
                            <Image
                              source={{ uri: `${imageHost}${imageUrl}` }}
                              style={{
                                width: isMobile ? 100 : 200,
                                height: isMobile ? 100 : 200,
                                objectFit: "cover",
                                borderRadius: 12,
                              }}
                              alt={`Image-${imgIndex + 1}`}
                            />
                            <Pressable
                              onPress={() => {
                                const newImageList = imageList.filter(
                                  (_, i) => i !== imgIndex
                                );
                                handleChangeVehicleDetail(index, {
                                  images: newImageList,
                                });
                              }}
                              style={{
                                position: "absolute",
                                top: -5,
                                right: -5,
                                backgroundColor: "white",
                                width: 30,
                                height: 30,
                                borderRadius: 12,
                                alignItems: "center",
                                justifyContent: "center",
                                shadowColor: "#000",
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                elevation: 5,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 16,
                                  fontWeight: "bold",
                                }}
                              >
                                ×
                              </Text>
                            </Pressable>
                          </View>
                        );
                      }}
                      ListEmptyComponent={() => (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "row",
                          }}
                        >
                          <ImagePlaceholderSvg
                            style={{
                              width: isMobile ? 100 : 200,
                              height: isMobile ? 100 : 200,
                              objectFit: "cover",
                              borderRadius: 12,
                            }}
                          />
                        </View>
                      )}
                    />
                  </View>
                  <div
                    style={{
                      border: isDragging
                        ? "2px dashed #2196F3"
                        : validationErrors[index]?.images
                        ? "2px dashed #FF0000"
                        : "2px dashed #ccc",
                      backgroundColor: isDragging ? "#E3F2FD" : "#F5F5F5",
                      borderRadius: "12px",
                      padding: "20px",
                      textAlign: "center",
                      transition: "all 0.3s ease",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column", // Stack items vertically
                      alignItems: "center", // Center items horizontally
                      justifyContent: "center", // Center items vertically within the container
                    }}
                    onClick={onImageUpload}
                    {...dragPropsCustom}
                  >
                    <Upload style={{ marginBottom: "10px" }} />
                    <Text
                      style={{
                        fontSize: "16px",
                        color: "#333",
                        marginBottom: "5px",
                      }}
                    >
                      Drag & drop files or Browse
                    </Text>
                    <Text style={{ fontSize: "14px", color: "#777" }}>
                      Supported formats: JPEG, PNG
                    </Text>
                  </div>
                </View>
              );
            }}
          </ReactImageUploading>

          <View style={styles.divider} />
          <View style={{ width: 127 }}>
            <Button
              isDisabled={location.pathname.includes("request-edit") || !active}
              onPress={() => removeVehicle(index)}
              variant="outline"
            >
              Remove
            </Button>
          </View>
        </View>
        <View style={styles.divider} />
      </View>
    );
  }
);

export default VehicleForm;
