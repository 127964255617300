import * as React from "react";
import { useState } from "react";
import {
  Text,
  View,
  VStack,
  Heading,
  Divider,
  Input,
  TextArea,
  Button,
  useToast,
} from "native-base";
import {
  toastErrorConfig,
  toastSuccessConfig,
} from "../../../config/constants";
import axios from "axios";
import { API_URL } from "../../../config";

export default function ReportProblemScreen() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async () => {
    if (!title || !description) {
      toast.show({
        description: "Please fill in all required fields",
        ...toastErrorConfig,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const data = { title, description };
      await axios.post(`${API_URL}/admins/report-problem`, data);

      toast.show({
        description: "Problem reported successfully!",
        ...toastSuccessConfig,
      });

      setTitle("");
      setDescription("");
    } catch (error) {
      console.error(error);
      toast.show({
        description: "Failed to submit. Try again later.",
        ...toastErrorConfig,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <View flex={1} p={4} bg="white">
      <VStack space={4}>
        <Heading size="lg" color="primary.600">
          Report a Problem
        </Heading>

        <Divider my={2} />

        <Text fontSize="md">Let us know if you're facing any issues.</Text>

        <VStack space={4} mt={4}>
          <Input
            placeholder="Problem Title"
            value={title}
            onChangeText={setTitle}
            size="md"
          />

          <TextArea
            placeholder="Describe the issue in detail..."
            value={description}
            onChangeText={setDescription}
            totalLines={5}
            autoCompleteType={undefined}
          />

          <Button
            onPress={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={!title || !description}
            colorScheme="primary"
          >
            Submit
          </Button>
        </VStack>
      </VStack>
    </View>
  );
}
