const styles = {
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    flex: 1,
  },
  container: {
    padding: 20,
    justifyContent: "center",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    boxShadow: "0px 1px 2.22px rgba(0, 0, 0, 0.25)",
  },
  actionText: {
    marginLeft: 10,
    fontWeight: "600",
  },
  buttonContainer: {
    marginTop: 40,
    marginBottom: 20,
    width: "100%",
  },
  inputOpt: {
    fontSize: 16,
    height: 32,
    width: 32,
    border: "1px solid #E5E5E5",
    borderRadius: 12,
    appearance: "none",
    margin: 4,
  },
  root: {
    flex: 1,
    padding: 20,
  },
  codeFieldRoot: {
    marginTop: 20,
  },
  cell: {
    width: 46,
    height: 46,
    lineHeight: 40,
    fontSize: 18,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    textAlign: "center",
  },
  focusCell: {
    borderColor: "#1DCE80",
    borderRadius: 12,
  },
  subheader: {
    textAlign: "center",
  },
};

export default styles;
