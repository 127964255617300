import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#000000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z" />
        <Path d="M6.667 2.5H7.5a23.686 23.686 0 000 15h-.833M12.5 2.5a23.686 23.686 0 010 15" />
        <Path d="M2.5 13.333V12.5a23.686 23.686 0 0015 0v.833M2.5 7.5a23.686 23.686 0 0115 0" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
