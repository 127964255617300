import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  View,
  Box,
  Text,
  useToast,
  Pressable,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setUser } from "../../../networking/auth/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  toastErrorConfig,
  toastWarningConfig,
} from "../../../config/constants";
import PhoneInput from "react-phone-number-input/input";
import axios from "axios";
import {
  checkUser,
  verifyCredentialToEdit,
} from "../../../networking/auth/authApi";

export default function URFFirstScreen({ navigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userInfo } = useSelector((state) => state.auth);
  const [phone, setPhone] = useState(userInfo.phone);
  const [email, setEmail] = useState(userInfo.email);
  const [fullName, setFullName] = useState(userInfo.fullName);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const goToNextPage = () => {
    if (!fullName) {
      toast.show({
        description: "Please enter your full name",
        ...toastErrorConfig,
      });
      return;
    }

    const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
    if (!englishOnlyRegex.test(email)) {
      toast.show({
        description: "Email must contain English characters only",
        ...toastErrorConfig,
      });
      return;
    }
    if (!email.includes("@") || !email.includes(".")) {
      toast.show({
        description: "Email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

    if (!isValidPhone) {
      toast.show({
        description: "Please enter a valid 10-digit phone number.",
        ...toastErrorConfig,
      });
      return;
    }

    dispatch(checkUser({ email, phone }))
      .unwrap()
      .then((response) => {
        if (response.exists) {
          toast.show({
            description:
              "User with this credential already exists. Please try a different one.",
            ...toastErrorConfig,
          });
          return;
        }
        dispatch(
          setUser({
            email,
            phone,
            fullName,
          })
        );
        navigate("/signup/user/2");
      })
      .catch((error) => {
        toast.show({
          description: "An unexpected error occurred. Please try again later.",
          ...toastErrorConfig,
        });
      });
  };

  const handlePhoneVerify = async () => {
    const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

    if (!isValidPhone) {
      toast.show({
        description: "Please enter a valid 10-digit phone number.",
        ...toastErrorConfig,
      });
      return;
    }
    dispatch(
      setUser({
        fullName,
      })
    );

    const dataType = "phone";

    const verificationData = {};

    verificationData.phone = phone;

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        navigate(`/confirm/signup2/phone/${phone}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const handleEmailVerify = async () => {
    const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
    if (!englishOnlyRegex.test(email)) {
      toast.show({
        description: "Email must contain English characters only",
        ...toastErrorConfig,
      });
      return;
    }
    if (!email.includes("@") || !email.includes(".")) {
      toast.show({
        description: "Email is invalid",
        ...toastErrorConfig,
      });
      return;
    }
    dispatch(
      setUser({
        fullName,
      })
    );
    const dataType = "email";

    const verificationData = {};

    verificationData.email = email.trim();

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        navigate(`/confirm/signup2/email/${email}`);
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.show({
            description: `The ${dataType} provided is not valid. Please check and try again.`,
            ...toastErrorConfig,
          });
        } else if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };
  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold textAlign={"center"}>
          Registration
        </Text>
        <FormControl>
          <FormControl.Label>Full Name</FormControl.Label>
          <Input
            value={fullName}
            onChangeText={setFullName}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Email</FormControl.Label>
          <Input
            value={email}
            isDisabled={!!userInfo.isEmailVerified}
            onChangeText={setEmail}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            autoCorrect={false}
            autoCompleteType="email"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            InputRightElement={
              !userInfo.isEmailVerified && email.includes("@") && email.includes(".") ? ( // Show "Verify" button only for valid email
                <Pressable onPress={handleEmailVerify}>
                  <Text style={{ color: "#006400", marginRight: 12 }}>
                    Verify
                  </Text>
                </Pressable>
              ) : userInfo.isEmailVerified ? (
                <Text style={{ color: "#006400", marginRight: 12 }}>
                  Verified
                </Text>
              ) : null
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isPhoneFocused ? "#1A1A1A" : "#A0A0A0",
                backgroundColor: isPhoneFocused ? "#F5F5F5" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                opacity: userInfo.isPhoneVerified ? 0.5 : 1,
                // outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <Text>+1 </Text>
            <PhoneInput
              value={phone}
              onChange={setPhone}
              disabled={!!userInfo.isPhoneVerified}
              type="tel"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: "transparent",
                color: userInfo.isPhoneVerified ? "#A9A9A9" : "#000000", // Gray text for disabled state
                fontSize: 16,
              }}
              onFocus={() => setIsPhoneFocused(true)}
              onBlur={() => setIsPhoneFocused(false)}
            />
            {phone &&
              phone.replace(/\D/g, "").length === 11 &&
              !userInfo.isPhoneVerified && ( // Show "Verify" button only for valid phone
                <Pressable onPress={handlePhoneVerify}>
                  <Text style={{ color: "#006400" }}>
                    Verify
                  </Text>
                </Pressable>
              )}
            {userInfo.isPhoneVerified && (
              <Text style={{ color: "#006400" }}>Verified</Text>
            )}
          </View>
          <View style={styles.divider} />
          <View style={styles.divider} />
        </FormControl>
        <Button style={styles.button} onPress={goToNextPage}>
          Continue
        </Button>
      </View>
    </Box>
  );
}
