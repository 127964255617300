import { headerHeight } from "../../config/constants";

const styles = {
  header: {
    position: "fixed",
    width: "100%",
    backgroundColor: "#000",
    height: headerHeight,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    flexDirection: "row",
    zIndex: 10,
  },
  userProfileButtonWrapper: {
    height: 44,
    width: 72,
    backgroundColor: "#323232",
    borderRadius: 12,
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userAvatarWrapper: {
    backgroundColor: "#FFFFFF",
    height: 28,
    width: 28,
    borderRadius: 14,
    justifyContent: "center",
    alignItems: "center",
  },
  userAvatarText: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 21,
    color: "#000000",
  },
  menuName: {
    marginLeft: 12,
    color: "#737373",
    fontSize: 14,
    fontWeight: "500",
  },
};

export default styles;
