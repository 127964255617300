import * as React from "react";
import { Logo } from "../../assets/images/svg";
import styles from "./styles";
import { View, Pressable, Text } from "native-base";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import UserProfileButton from "./userProfileButton";

export default function Header() {
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth);
  const { fullName, credits } = useSelector((state) => state.profile);

  return (
    <View>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            userToken ? navigate("/orders") : navigate("/");
          }}
        >
          <Logo />
        </Pressable>
        {!!userToken && (
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={{ color: "white", marginRight: 4 }}>
              Balance: ${credits ? credits.toFixed(2) : "0.00"}
            </Text>
            <UserProfileButton name={fullName} />
          </View>
        )}
      </View>
      <View style={{ marginTop: 90 }}>
        <Outlet />
      </View>
    </View>
  );
}
