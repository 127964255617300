import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M18.308 15.275c0 .3-.067.608-.209.908-.141.3-.325.584-.566.85-.409.45-.859.775-1.367.984-.5.208-1.042.316-1.625.316-.85 0-1.758-.2-2.717-.608a14.644 14.644 0 01-2.866-1.65 23.953 23.953 0 01-2.734-2.333A23.673 23.673 0 013.9 11.017c-.683-.95-1.233-1.9-1.633-2.842-.4-.95-.6-1.858-.6-2.725 0-.567.1-1.108.3-1.608.2-.509.517-.975.958-1.392.534-.525 1.117-.783 1.734-.783.233 0 .466.05.675.15.216.1.408.25.558.466l1.933 2.725c.15.209.259.4.334.584.075.175.116.35.116.508 0 .2-.058.4-.175.592a2.834 2.834 0 01-.466.591l-.634.659a.446.446 0 00-.133.333c0 .067.008.125.025.192.025.066.05.116.067.166.15.275.408.634.775 1.067.375.433.775.875 1.208 1.317.45.441.883.85 1.325 1.225.433.366.792.616 1.075.766.042.017.092.042.15.067a.575.575 0 00.208.033c.142 0 .25-.05.342-.141l.633-.625a2.56 2.56 0 01.6-.467 1.11 1.11 0 01.592-.175c.158 0 .325.033.508.108.184.075.375.184.584.325l2.758 1.959c.217.15.367.325.458.533.084.208.134.417.134.65z"
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <Path
        d="M15.417 7.5c0-.5-.392-1.266-.975-1.891-.534-.575-1.242-1.026-1.942-1.026M18.333 7.5A5.829 5.829 0 0012.5 1.667"
        stroke="#000000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
