import * as React from "react";
import {
  Button,
  ScrollView,
  Text,
  View,
  Image,
  AlertDialog,
  Pressable,
  Spinner,
  useToast,
  Modal,
  HStack,
  FormControl,
  TextArea,
  Select,
  Box,
  Divider,
  Tooltip,
} from "native-base";
import styles from "./styles";
import { useEffect, useState, useRef } from "react";
import {
  AddImage,
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  LocationGreen,
  EyeSlash,
  Eye,
} from "../../assets/images/svg";
import AddressGrey from "../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import { formatStringToDate } from "../../utils";
import BidStatus from "../../components/BidStatus";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateOrder,
  setOrderChanges,
  clearOrderChanges,
  updateOrderReview,
} from "../../networking/profile/profileSlice";
import {
  sendOrderToTMS,
  deleteOrder,
  disputeOrder,
  rateOrder,
  sendSMS,
  sendInfo,
  respondToEditOrderRequestFromTMS,
  unpublishOrder,
} from "../../networking/orders/ordersApi";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { getOrder, getOrderChanges } from "../../networking/profile/profileApi";
import ReactImageUploading from "react-images-uploading";
import { API_URL } from "../../config";
import instance from "../../networking/axios";
import {
  cancelOrderWithFee,
  cancelOrderWithoutFee,
  setCarrierProblemStatus,
  getReview,
} from "../../networking/orders/ordersApi";
import { isFeeApplied } from "../../utils";
import {
  FaThumbsUp,
  FaThumbsDown,
  FaTruckMoving,
  FaComment,
} from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa6";
import { BsCurrencyDollar } from "react-icons/bs";
import VehicleDetails from "../../components/VehicleDetails";
import { MdOutlineContentCopy } from "react-icons/md";
import { imageHost } from "../../config";
import { respondToOrderCancellation } from "../../networking/orders/ordersApi";

export default function OrderScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const { orders, active } = useSelector((state) => state.profile);
  const order = orders.find((order) => order.id === id);
  const dispatch = useDispatch();
  const [shownDeleteModal, setShownDeleteModal] = useState(false);
  const cancelRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const [selectReasonModalOpen, setSelectReasonModalOpen] = useState(false);
  const [cancelWithoutFeeAlertOpen, setCancelWithoutFeeAlertOpen] =
    useState(false);
  const [cancelWithFeeAlertOpen, setCancelWithFeeAlertOpen] = useState(false);
  const [isSendInfoModalOpen, setSendInfoModalOpen] = useState(false);
  const [infoType, setInfoType] = useState("");
  const [shownDisclaimerModal, setShownDisclaimerModal] = useState(false);
  const [expandedVehicles, setExpandedVehicles] = useState([]);
  const [isTakeActionAlertOpen, setIsTakeActionAlertOpen] = useState(false);
  const toggleVehicleDetails = (index) => {
    setExpandedVehicles((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index]; // Toggle the state for this specific vehicle
      return newState;
    });
  };

  let oldValue;
  if (order.orderChanges?.oldValue) {
    oldValue = order.orderChanges.oldValue;
  }
  const {
    orderName,
    vehicles,
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    status,
    senderContactName,
    senderContactPhone,
    senderContactEmail,
    recipientContactName,
    recipientContactEmail,
    recipientContactPhone,
    bidAmount,
    carrierEmail,
    carrierName,
    carrierPhone,
    carrierSince,
    numberOfTrucks,
    statusToTMS,
    pickupSenderPIN,
    deliveryReceiverPIN,
    flexiblePickupDate,
    flexibleDeliveryDate,
    pickUpPINRequired,
    deliveryPINRequired,
  } = order;

  const handleCopy = (text, name, email, phone) => {
    const details = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}`;
    navigator.clipboard.writeText(details).then(
      () => {
        toast.show({
          description: `${text} information copied to clipboard!`,
          ...toastSuccessConfig,
        });
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  const handleCopyAddress = (text, location) => {
    navigator.clipboard.writeText(location).then(
      () => {
        toast.show({
          description: `${text} address copied to clipboard!`,
          ...toastSuccessConfig,
        });
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    const cleaned = phone.replace(/\D/g, "");

    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phone;
  };

  const fetchOrder = async () => {
    try {
      const fetchedOrder = await dispatch(getOrder({ id })).unwrap();

      if (
        fetchedOrder.status === "waitingPickUpReviewChanges" ||
        fetchedOrder.status === "pickedUpReviewChanges"
      ) {
        const changes = await dispatch(getOrderChanges({ id })).unwrap();

        if (changes?.oldValue) {
          dispatch(setOrderChanges({ id, changes }));
        } else {
          dispatch(clearOrderChanges(id));
        }
      } else if (fetchedOrder.status === "completedAndRated") {
        const review = await dispatch(getReview(id)).unwrap();
        dispatch(updateOrderReview({ id, review }));
      } else if (fetchedOrder.status === "cancelledByCarrier") {
        setIsTakeActionAlertOpen(true);
      }
    } catch (error) {
      console.error("Error fetching order:", error);
    }
  };

  const handleSendInfo = (type) => {
    setSendInfoModalOpen(true);
    setInfoType(type); // 'pickup' or 'delivery'
  };

  const closeSendInfoModal = () => setSendInfoModalOpen(false);

  const handleSendSms = (phone, infoType) => {
    const text = `Order details: https://skipyourbroker.com/order/${id}.
    Delivery company: ${carrierName}
        Phone: ${carrierPhone}
        Email: ${carrierEmail}
        ${
          infoType === "pickup"
            ? `Sender PIN: ${order.pickupSenderPIN}`
            : `Recipient PIN: ${order.deliveryReceiverPIN}`
        }
    `;
    dispatch(sendSMS({ phoneNumber: phone, text }));
    setSendInfoModalOpen(false);
    toast.show({
      description: `${infoType} info sent via SMS.`,
      ...toastSuccessConfig,
    });
  };

  const handleSendEmail = (email, infoType) => {
    dispatch(sendInfo({ id, infoType }));
    setSendInfoModalOpen(false);
    toast.show({
      description: `Information sent via Email.`,
      ...toastSuccessConfig,
    });
  };

  const acceptChanges = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        respondToEditOrderRequestFromTMS({ id, data: { accepted: true } })
      ).unwrap();

      toast.show({
        description: "Changes accepted successfully.",
        ...toastSuccessConfig,
      });
      fetchOrder();
      dispatch(clearOrderChanges(id));
    } catch (error) {
      console.error("Error accepting changes:", error);

      toast.show({
        description:
          error?.message || "Failed to accept changes. Please try again.",
        ...toastErrorConfig,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const declineChanges = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(
        respondToEditOrderRequestFromTMS({ id, data: { accepted: false } })
      ).unwrap();

      toast.show({
        description: "Changes declined successfully.",
        ...toastSuccessConfig,
      });
      fetchOrder();
      dispatch(clearOrderChanges(id));
    } catch (error) {
      console.error("Error declining changes:", error);

      toast.show({
        description:
          error?.message || "Failed to decline changes. Please try again.",
        ...toastErrorConfig,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const publishOrder = (id) => {
    const today = new Date().toISOString().split("T")[0];
    // Check if the pickup date is before today
    if (pickUpDate1 < today) {
      toast.show({
        description: `Pickup date cannot be in the past. Please edit the order and select a valid date`,
        ...toastErrorConfig,
      });
      return;
    }
    setIsLoading(true);
    dispatch(updateOrder({ id, statusToTMS: "sending" }));
    dispatch(sendOrderToTMS({ data: order }))
      .unwrap()
      .then((sentOrder) => {
        if (sentOrder.success) {
          dispatch(updateOrder({ id, statusToTMS: "sent", status: "auction" }));
          toast.show({
            description: `Order was successfully published`,
            ...toastSuccessConfig,
          });
        } else {
          dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
          toast.show({
            description: sentOrder.message || `Order couldn't be processed`,
            ...toastErrorConfig,
          });
        }
      })
      .catch((err) => {
        dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
        toast.show({
          description: `Something went wrong on our side, your order wasn't published`,
          ...toastErrorConfig,
        });
        console.log("err", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const editOrder = (id) => {
    navigate(`/edit/1/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const performUnpublishOrder = (id) => {
    setIsLoading(true);
    dispatch(unpublishOrder({ id }))
      .unwrap()
      .then(() => {
        dispatch(updateOrder({ id, statusToTMS: "draft", status: "new" }));
        toast.show({
          description: "Order has been unpiblished successfully.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch((err) => {
        toast.show({
          description: "Failed to unpublish the order.",
          ...toastErrorConfig,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelClick = () => {
    if (isFeeApplied(order.pickUpDate1)) {
      console.log("Fee should be applied");
      setSelectReasonModalOpen(true);
    } else {
      console.log("No fee needed");
      setCancelWithoutFeeAlertOpen(true);
    }
  };

  const handleReasonSelect = (reason) => {
    if (!reason) {
      toast.show({
        description: "Please select the reason",
        ...toastErrorConfig,
      });
      return;
    }
    setSelectReasonModalOpen(false);
    if (reason === "Change of plans") {
      setCancelWithFeeAlertOpen(true);
    } else if (reason === "Carrier didn't arrive") {
      handleCarrierNotArrived();
    }
  };

  const handleCarrierNotArrived = () => {
    try {
      dispatch(setCarrierProblemStatus(order.id));
      dispatch(updateOrder({ id: order.id, status: "carrierProblem" }));
      toast.show({
        description: "Support agent will take care if it soon",
        ...toastSuccessConfig,
      });
    } catch (error) {
      console.error("Error setting carrier problem status:", error);
      toast.show({
        description: "Failed to update order status. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleCancelWithFee = () => {
    setIsLoading(true);
    setCancelWithFeeAlertOpen(false);
    dispatch(cancelOrderWithFee(order.id))
      .unwrap()
      .then(() => {
        toast.show({
          description: "Order canceled successfully with a fee.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch(() => {
        toast.show({
          description: "We couldn't cancel your order",
          ...toastErrorConfig,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelWithoutFee = () => {
    setIsLoading(true);
    setCancelWithoutFeeAlertOpen(false);
    dispatch(cancelOrderWithoutFee(order.id))
      .unwrap()
      .then(() => {
        toast.show({
          description: "Order canceled successfully.",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch(() => {
        toast.show({
          description: "We couldn't cancel your order",
          ...toastErrorConfig,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const CancelOrderFlow = () => {
    const [reason, setReason] = useState("");
    return (
      <>
        <Modal
          isOpen={selectReasonModalOpen}
          onClose={() => setSelectReasonModalOpen(false)}
          useRNModal={true}
        >
          <Modal.Content>
            <Modal.Header>{`Cancel Order #${order.orderName}`}</Modal.Header>
            <Modal.Body>
              <FormControl>
                <Text>Please select the reason for cancellation</Text>
                <FormControl.Label>Reason</FormControl.Label>
                <Select
                  placeholder="Select reason"
                  accessibilityLabel="reason"
                  selectedValue={reason}
                  onValueChange={(reason) => setReason(reason)}
                >
                  <Select.Item
                    label="Change of plans"
                    value="Change of plans"
                  />
                  <Select.Item
                    label="Carrier didn't arrive"
                    value="Carrier didn't arrive"
                  />
                </Select>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!reason}
                onPress={() => {
                  handleReasonSelect(reason);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setSelectReasonModalOpen(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        <AlertDialog
          isOpen={cancelWithoutFeeAlertOpen}
          onClose={() => setCancelWithoutFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              {`Are you sure you want to cancel the order #${order.orderName}?`}
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as draft and carrier will not be able
                to complete it for you. You will receive funds paid for order in
                credits. You can withdraw that funds at any time.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithoutFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithoutFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>

        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithFeeAlertOpen}
          onClose={() => setCancelWithFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>Final Confirmation</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as a draft, and the carrier will not be
                able to complete it. The funds you paid for the order will be
                refunded to you as credits, excluding $200, which was deducted
                as a fee. You can withdraw the remaining balance at any time.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithFeeAlertOpen(false)}
                ref={cancelRef}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </>
    );
  };

  const onDelete = async () => {
    setIsLoading(true);
    setShownDeleteModal(false);
    dispatch(deleteOrder({ id }))
      .unwrap()
      .then(() => {
        toast.show({
          description: "Order was successfully deleted",
          ...toastSuccessConfig,
        });
        navigate("/");
      })
      .catch((error) => {
        toast.show({
          description: "Failed to delete order",
          ...toastErrorConfig,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [images, setImages] = useState([]);

    const onClose = () => {
      setDisputeText("");
      setImages([]);
      setDisputeModalIsOpen(false);
    };

    const sendDispute = () => {
      if (disputeText.trim().length < 20 || disputeText.trim().length > 180) {
        toast.show({
          description: "The text should be at least 20 symbols",
          ...toastErrorConfig,
        });
        return;
      }
      if (!images.length) {
        toast.show({
          description: "Please upload at least one image.",
          ...toastErrorConfig,
        });
        return;
      }
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: order.id,
            disputeText,
            disputeImages: images,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id, status: "dispute" }));
          toast.show({
            description: "Order was successfully disputed.",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to dispute order.",
            ...toastErrorConfig,
          });
          console.error("Error disputing order:", error);
        });
    };

    const onChange = async (imageList) => {
      if (images.length + imageList.length > 10) {
        toast.show({
          description: "You can only upload up to 10 images.",
          ...toastErrorConfig,
        });
        return;
      }

      const uploadedImages = await Promise.all(
        imageList.map(async ({ file }) => {
          try {
            const formattedFileName = file.name.replace(/\s+/g, "-");
            const formData = new FormData();
            formData.append("file", file, formattedFileName);

            const result = await instance.post(
              `${API_URL}/api/v1/orders/upload`,
              formData
            );

            if (result?.data?.fileName) {
              return result.data.fileName;
            }
          } catch (error) {
            console.error("Error uploading image:", error);
            toast.show({
              description: "Failed to upload image. Please try again.",
              ...toastErrorConfig,
            });
          }
          return null; // Return null for failed uploads
        })
      );

      // Filter out failed uploads and update state
      const validImages = uploadedImages.filter((url) => url !== null);
      setImages((prev) => [...prev, ...validImages]);
    };

    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header style={{ alignItems: "center" }}>
            Dispute Request
          </AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Reason</FormControl.Label>
            <TextArea
              value={disputeText}
              onChangeText={setDisputeText}
              h={20}
              placeholder="Please describe what happened"
            />
            <Text style={{ fontSize: 12, color: "#888", marginTop: 5 }}>
              {disputeText.trim().length}/180 characters
            </Text>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row" }}
            >
              {images.map((image, imgIndex) => {
                const imageUrl = `${image.fileName || image}`;
                return (
                  <View
                    key={imgIndex}
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                    }}
                  >
                    <Image
                      source={{ uri: `${imageHost}${imageUrl}` }}
                      alt={`uploaded-${imgIndex}`}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "cover",
                        borderRadius: 12,
                        filter: "blur(0.5px)", // Helps with display on smaller resolutions
                      }}
                    />
                    <Pressable
                      onPress={() => {
                        const newImageList = images.filter(
                          (_, i) => i !== imgIndex
                        ); // Filter out the removed image
                        setImages(newImageList);
                      }}
                      style={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        borderRadius: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        shadowColor: "#000",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        ×
                      </Text>
                    </Pressable>
                  </View>
                );
              })}
            </ScrollView>
            <ReactImageUploading
              multiple
              value={images.map((image) => ({
                data_url: `${imageHost}${image}`,
              }))}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({ onImageUpload, dragProps }) => (
                <>
                  <Pressable onPress={onImageUpload} {...dragProps}>
                    <View
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <AddImage />
                      <Text style={{ marginLeft: 10 }}>Add Image</Text>
                    </View>
                  </Pressable>
                </>
              )}
            </ReactImageUploading>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  disputeText.trim().length < 20 ||
                  disputeText.trim().length > 180
                }
                style={styles.acButton}
                onPress={sendDispute}
              >
                Send Dispute
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = ({ review }) => {
    const onClose = () => {
      setRateModalIsOpen(false);
    };
    const rateRef = React.useRef(null);
    const [selected, setSelected] = useState(
      review?.rating === 1 ? "up" : review?.rating === 0 ? "down" : null
    );
    const [reviewText, setReviewText] = useState(review?.text || "");

    const handleSubmit = () => {
      if (selected === "down" && !reviewText.trim()) {
        toast.show({
          description: "Please provide a reason for your negative feedback",
          ...toastErrorConfig,
        });
        return;
      }
      onClose();
      const reviewData = {
        id: order.id,
        rating: selected === "down" ? 0 : 1,
        carrierID: order.carrierID,
        review: reviewText.trim(),
      };
      setIsLoading(true);
      dispatch(
        rateOrder({
          data: reviewData,
        })
      )
        .unwrap()
        .then(() => {
          if (order.status !== "completedAndRated") {
            dispatch(updateOrder({ id, status: "completedAndRated" }));
          }
          toast.show({
            description: "Thank you for your feedback!",
            ...toastSuccessConfig,
          });
          fetchOrder();
        })
        .catch((err) => {
          toast.show({
            description: "Failed to submit rating. Please try again.",
            ...toastErrorConfig,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    return (
      <AlertDialog isOpen={rateModalIsOpen} onClose={onClose} useRNModal={true}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header style={{ alignItems: "center" }}>
            Complete Order
          </AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to mark the order as completed? Dispute
            options will no longer be available. We'd welcome your feedback!
            Please rate the carrier and the service.
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <View style={styles.buttonsWrapper}>
              {/* Thumbs Up Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "up" && styles.selectedButtonUp,
                ]}
                onPress={() => setSelected("up")}
                _hover={{ bg: "#D1FAE5" }}
                _pressed={{ bg: "#10B981" }}
              >
                <FaThumbsUp
                  size={24}
                  color={selected === "up" ? "white" : "#10B981"}
                />
              </Button>

              {/* Spacer */}
              <View style={styles.spacer}></View>

              {/* Thumbs Down Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "down" && styles.selectedButtonDown,
                ]}
                onPress={() => setSelected("down")}
                _hover={{ bg: "#FEE2E2" }}
                _pressed={{ bg: "#EF4444" }}
              >
                <FaThumbsDown
                  size={24}
                  color={selected === "down" ? "white" : "#EF4444"}
                />
              </Button>
            </View>
            {selected === "down" && (
              <View>
                <TextArea
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  placeholder={
                    "Please provide a reason for your negative feedback..."
                  }
                />
                <Text style={{ fontSize: 12, color: "#888", marginTop: 5 }}>
                  {reviewText.trim().length}/180 characters
                </Text>
              </View>
            )}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  (selected === "down" &&
                    (reviewText?.trim().length < 20 ||
                      reviewText?.trim().length > 180)) ||
                  !selected
                }
                style={styles.acButton}
                onPress={handleSubmit}
              >
                Submit
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const TakeActionAlert = () => {
    const handleSubmit = (respondType) => {
      setIsLoading(true);
      setIsTakeActionAlertOpen(false);
      dispatch(
        respondToOrderCancellation({
          id,
          respondType,
        })
      )
        .unwrap()
        .then(() => {
          if (respondType === "cancel") {
            toast.show({
              description: "Order was cancelled successfully.",
              ...toastSuccessConfig,
            });
          } else if (respondType === "repost") {
            toast.show({
              description: "Order was reposted successfully.",
              ...toastSuccessConfig,
            });
          }
          navigate("/orders");
        })
        .catch((err) => {
          toast.show({
            description: "Failed to respond. Please try again.",
            ...toastErrorConfig,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    return (
      <AlertDialog
        isOpen={isTakeActionAlertOpen}
        onClose={() => {}}
        useRNModal={true}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <AlertDialog.Content width="90%" maxWidth="400px">
          <AlertDialog.Header style={{ alignItems: "center" }}>
            Carrier canceled your order #{orderName}
          </AlertDialog.Header>
          <AlertDialog.Body>
            The carrier has canceled this order. Please choose how you’d like to
            proceed:
            {"\n"}
            <Text fontWeight="bold">1) Confirm Cancellation: </Text>Your order
            will be moved to drafts, and your funds will be credited to your
            account.{"\n"}
            <Text fontWeight="bold">2) Repost Order: </Text>Your order will be
            reopened for bidding immediately, allowing new carriers to place
            bids. You will receive notifications as new bids arrive. You can use
            the credited amount to accept a new bid and secure a carrier
            quickly. {"\n"}
            You may also withdraw this credits back to your original payment
            method at any time.{"\n"}
            Do you want to repost your order now?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <HStack space={3} justifyContent="center" width="100%">
              <Button
                variant="outline"
                flex={1}
                onPress={() => handleSubmit("cancel")}
              >
                No, cancel Order
              </Button>
              <Button flex={1} onPress={() => handleSubmit("repost")}>
                Yes, repost Order
              </Button>
            </HStack>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const SendInfoModal = ({
    isOpen,
    onClose,
    infoType,
    onSendSms,
    onSendEmail,
  }) => {
    const email =
      infoType === "pickup" ? senderContactEmail : recipientContactEmail;
    const phone =
      infoType === "pickup" ? senderContactPhone : recipientContactPhone;
    return (
      <Modal isOpen={isOpen} onClose={onClose} useRNModal={true}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>
            <Text>
              {`Send ${
                infoType === "pickup" ? "Pickup" : "Delivery"
              } Informatiion`}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Text>
              {`Do you want to send the ${
                infoType === "pickup" ? "Pickup" : "Delivery"
              } information via SMS or Email?`}
            </Text>
            <Text>{`Email: ${email}`}</Text>
            <Text>{`Phone: ${phone}`}</Text>
          </Modal.Body>
          <Modal.Footer>
            <HStack space={3} justifyContent="center" width="100%">
              <Button flex={1} onPress={() => onSendSms(phone, infoType)}>
                Send SMS
              </Button>
              <Button flex={1} onPress={() => onSendEmail(email, infoType)}>
                Send Email
              </Button>
            </HStack>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setSendInfoModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    );
  };

  const PublishOrderAlert = () => {
    const onClose = () => {
      setShownDisclaimerModal(false);
    };
    return (
      <AlertDialog
        isOpen={shownDisclaimerModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header style={{ alignItems: "center" }}>
            Publish Order
          </AlertDialog.Header>
          <AlertDialog.Body>
            Attention! If there is no activity and confirmation of your order
            within 24 hours, your listing will be removed from the board.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={{ flexDirection: "row", flex: 2 }}>
              <View style={styles.cancelButton}>
                <Button variant="outline" onPress={onClose}>
                  Cancel
                </Button>
              </View>
              <View style={styles.publishButton}>
                <Button
                  onPress={() => {
                    setShownDisclaimerModal(false);
                    publishOrder(id);
                  }}
                >
                  Publish
                </Button>
              </View>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DeleteAlert = () => {
    const onClose = () => {
      setShownDeleteModal(false);
    };
    return (
      <AlertDialog
        isOpen={shownDeleteModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header style={{ alignItems: "center" }}>
            Delete Order
          </AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to delete the order?
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={() => setShownDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="fatal"
                style={styles.dcButton}
                onPress={onDelete}
              >
                Delete
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const LocationInfo = () => {
    const [isPickupPinVisible, setPickupPinVisible] = useState(false);
    const [isDeliveryPinVisible, setDeliveryPinVisible] = useState(false);

    // Toggle functions for each PIN visibility
    const togglePickupPinVisibility = () =>
      setPickupPinVisible(!isPickupPinVisible);
    const toggleDeliveryPinVisibility = () =>
      setDeliveryPinVisible(!isDeliveryPinVisible);

    return (
      <>
        <Box
          style={styles.locationPinWrapper}
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent={{ base: "flex-start", sm: "space-between" }}
        >
          <View style={styles.locationDetailsWrapper}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 10 }}
            >
              <LocationGreen />
              <Text style={styles.locationText}>Pickup Location</Text>
            </View>
            <View style={styles.locationTextWrapper}>
              {oldValue?.pickUpLocation && (
                <View style={styles.dateRow}>
                  <Text
                    style={{
                      textDecorationLine: "line-through",
                      color: "red",
                      marginLeft: 26,
                    }}
                  >
                    {oldValue.pickUpLocation}
                  </Text>
                </View>
              )}
              <View style={[styles.dateRow, { marginLeft: 2 }]}>
                <AddressGrey />
                <Pressable
                  style={{ flex: 1 }}
                  onPress={() => handleCopyAddress("Pickup", pickUpLocation)}
                >
                  <Text style={{ flex: 1, marginLeft: 4 }}>
                    {pickUpLocation}
                    <Text
                      style={{
                        color: "#a0aec0",
                        fontWeight: 500,
                        marginLeft: 4,
                      }}
                    >
                      <Tooltip
                        placement="top left"
                        label="Copy"
                        openDelay={300}
                      >
                        <Text style={{ color: "#a0aec0", fontWeight: 500 }}>
                          <MdOutlineContentCopy />
                        </Text>
                      </Tooltip>
                    </Text>
                  </Text>
                </Pressable>
              </View>
              {(oldValue?.pickUpDate1 || oldValue?.flexiblePickupDate) && (
                <View style={styles.dateRow}>
                  <Text
                    style={{
                      textDecorationLine: "line-through",
                      color: "red",
                      marginLeft: 28,
                    }}
                  >
                    {oldValue?.pickUpDate1 &&
                      formatStringToDate(oldValue.pickUpDate1)}{" "}
                    {oldValue?.flexiblePickupDate && oldValue.flexiblePickupDate
                      ? " (Flexible)"
                      : "(Exact date)"}
                  </Text>
                </View>
              )}
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>
                  {formatStringToDate(pickUpDate1)}
                  {flexiblePickupDate ? " (Flexible)" : " (Exact date)"}
                </Text>
              </View>
              {pickUpPINRequired ? (
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#006400",
                    marginTop: 4,
                  }}
                  onPress={() => {
                    status === "waitingPickUp" &&
                      navigate(`/order/${id}/verify-driver`);
                  }}
                >
                  PIN verification required
                </Text>
              ) : (
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#8B0000",
                    marginTop: 4,
                  }}
                >
                  PIN verification is not required
                </Text>
              )}
            </View>
          </View>
          {/* PIN and Buttons Section */}
          {(status === "waitingPickUp" || status === "pickedUp") &&
            pickUpPINRequired && (
              <View>
                <View style={styles.sendViewPin}>
                  <View style={{ flexDirection: "row", gap: 20, marginTop: 5 }}>
                    <Text style={styles.headerBlock}>PIN:</Text>
                    <HStack alignItems="center">
                      <Text style={styles.pinText}>
                        {isPickupPinVisible ? pickupSenderPIN : "****"}
                      </Text>
                      <Pressable onPress={togglePickupPinVisibility}>
                        <View style={styles.iconWrapper}>
                          {isPickupPinVisible ? <EyeSlash /> : <Eye />}
                        </View>
                      </Pressable>
                    </HStack>
                  </View>
                  <View style={styles.sendButton}>
                    <Button
                      isDisabled={!active}
                      onPress={() => handleSendInfo("pickup")}
                      variant="outline"
                    >
                      Send Info
                    </Button>
                  </View>
                </View>
              </View>
            )}
        </Box>
        <View my={2}>
          <Divider bg="gray.300" thickness="1" />
        </View>
        <Box
          style={styles.locationPinWrapper}
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent={{ base: "flex-start", sm: "space-between" }}
        >
          <View style={styles.locationDetailsWrapper}>
            <View
              style={{ flexDirection: "row", alignItems: "center", gap: 10 }}
            >
              <GeoGreen />
              <Text style={styles.locationText}>Delivery Location</Text>
            </View>
            <View style={styles.locationTextWrapper}>
              {oldValue?.deliveryLocation && (
                <View style={styles.dateRow}>
                  <Text
                    style={{
                      textDecorationLine: "line-through",
                      color: "red",
                      marginLeft: 26,
                    }}
                  >
                    {oldValue.deliveryLocation}
                  </Text>
                </View>
              )}
              <View style={[styles.dateRow, { marginLeft: 2 }]}>
                <AddressGrey />
                <Pressable
                  style={{ flex: 1 }}
                  onPress={() =>
                    handleCopyAddress("Delivery", deliveryLocation)
                  }
                >
                  <Text style={{ flex: 1, marginLeft: 4 }}>
                    {deliveryLocation}
                    <Text
                      style={{
                        color: "#a0aec0",
                        fontWeight: 500,
                        marginLeft: 4,
                      }}
                    >
                      <Tooltip
                        placement="top left"
                        label="Copy"
                        openDelay={300}
                      >
                        <Text style={{ color: "#a0aec0", fontWeight: 500 }}>
                          <MdOutlineContentCopy />
                        </Text>
                      </Tooltip>
                    </Text>
                  </Text>
                </Pressable>
              </View>
              {(oldValue?.deliveryDate1 || oldValue?.flexibleDeliveryDate) && (
                <View style={styles.dateRow}>
                  <Text
                    style={{
                      textDecorationLine: "line-through",
                      color: "red",
                      marginLeft: 28,
                    }}
                  >
                    {oldValue?.deliveryDate1 &&
                      formatStringToDate(oldValue.deliveryDate1)}{" "}
                    {oldValue?.flexibleDeliveryDate &&
                    oldValue.flexibleDeliveryDate
                      ? " (Flexible)"
                      : "(Exact date)"}
                  </Text>
                </View>
              )}
              <View style={styles.dateRow}>
                <View style={styles.calendarIcon}>
                  <CalendarGrey />
                </View>
                <Text>
                  {formatStringToDate(deliveryDate1)}
                  {flexibleDeliveryDate ? " (Flexible)" : " (Exact date)"}
                </Text>
              </View>
              {deliveryPINRequired ? (
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#006400",
                    marginTop: 4,
                  }}
                  onPress={() => {
                    status === "pickedUp" &&
                      navigate(`/order/${id}/verify-driver`);
                  }}
                >
                  PIN verification required
                </Text>
              ) : (
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#8B0000",
                    marginTop: 4,
                  }}
                >
                  PIN verification is not required
                </Text>
              )}
            </View>
          </View>
          {/* PIN and Buttons Section */}
          {(status === "waitingPickUp" || status === "pickedUp") &&
            deliveryPINRequired && (
              <View>
                <View style={styles.sendViewPin}>
                  <View style={{ flexDirection: "row", gap: 20, marginTop: 5 }}>
                    <Text style={styles.headerBlock}>PIN:</Text>
                    <HStack alignItems="center">
                      <Text style={styles.pinText}>
                        {isDeliveryPinVisible ? deliveryReceiverPIN : "****"}
                      </Text>
                      <Pressable onPress={toggleDeliveryPinVisibility}>
                        <View style={styles.iconWrapper}>
                          {isDeliveryPinVisible ? <EyeSlash /> : <Eye />}
                        </View>
                      </Pressable>
                    </HStack>
                  </View>
                  <View style={styles.sendButton}>
                    <Button
                      isDisabled={!active}
                      onPress={() => handleSendInfo("delivery")}
                      variant="outline"
                    >
                      Send Info
                    </Button>
                  </View>
                </View>
              </View>
            )}
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (!active) {
      toast.show({
        description: "Your account is inactive. Please contact support.",
        ...toastErrorConfig,
      });
    }
    fetchOrder();
  }, []);

  return (
    <>
      <ScrollView style={styles.container}>
        <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}>
          Order: #{orderName}
        </Text>
        <View style={styles.status}>
          <BidStatus
            status={order.statusToTMS === "notsent" ? "notSent" : status}
          />
        </View>
        {vehicles.map((vehicle, index) => (
          <View key={index} style={styles.vehicleWrapper}>
            {/* Toggle Button */}
            <Pressable onPress={() => toggleVehicleDetails(index)}>
              <Text style={styles.toggleButton}>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    marginBottom: 5,
                  }}
                >
                  Vehicle # {index + 1}: {vehicle.year} {vehicle.brand}{" "}
                  {vehicle.model}
                </Text>
              </Text>
            </Pressable>

            {/* Vehicle Details */}
            <View
              style={[
                styles.vehicleDetails,
                { maxHeight: expandedVehicles[index] ? "1000px" : "0px" },
              ]}
            >
              <VehicleDetails vehicle={vehicle} index={index} />
            </View>
          </View>
        ))}

        <LocationInfo />
        <View my={2}>
          <Divider bg="gray.300" thickness="1" />
        </View>
        <>
          <Text style={styles.headerBlock}>
            Sender information{" "}
            <Pressable
              onPress={() =>
                handleCopy(
                  "Sender",
                  senderContactName,
                  senderContactEmail,
                  senderContactPhone
                )
              }
            >
              <Tooltip placement="top left" label="Copy" openDelay={300}>
                <Text style={{ color: "#a0aec0", fontWeight: 500 }}>
                  <MdOutlineContentCopy />
                </Text>
              </Tooltip>
            </Pressable>
          </Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>{senderContactName}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>
              <a href={`mailto:${senderContactEmail}`}>{senderContactEmail}</a>
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>
              <a href={`tel:${senderContactPhone}`}>
                +1 {formatPhoneNumber(senderContactPhone)}
              </a>
            </Text>
          </View>
          <View my={2}>
            <Divider bg="gray.300" thickness="1" />
          </View>
          <Text style={styles.headerBlock}>
            Recipient information{" "}
            <Pressable
              onPress={() =>
                handleCopy(
                  "Recipient",
                  recipientContactName,
                  recipientContactEmail,
                  recipientContactPhone
                )
              }
            >
              <Tooltip placement="top left" label="Copy" openDelay={300}>
                <Text style={{ color: "#a0aec0", fontWeight: 500 }}>
                  <MdOutlineContentCopy />
                </Text>
              </Tooltip>
            </Pressable>
          </Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>
              {recipientContactName}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>
              <a href={`mailto:${recipientContactEmail}`}>
                {recipientContactEmail}
              </a>
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>
              <a href={`tel:${recipientContactPhone}`}>
                +1 {formatPhoneNumber(recipientContactPhone)}
              </a>
            </Text>
          </View>
        </>
        {carrierName &&
          carrierEmail &&
          carrierPhone &&
          carrierSince &&
          bidAmount && (
            <>
              <View my={2}>
                <Divider bg="gray.300" thickness="1" />
              </View>
              <View style={styles.carrierDetails}>
                <Text style={styles.headerBlock}>
                  Carrier Information{" "}
                  <Pressable
                    onPress={() =>
                      handleCopy(
                        "Carrier",
                        carrierEmail,
                        carrierEmail,
                        carrierPhone
                      )
                    }
                  >
                    <Tooltip placement="top left" label="Copy" openDelay={300}>
                      <Text style={{ color: "#a0aec0", fontWeight: 500 }}>
                        <MdOutlineContentCopy />
                      </Text>
                    </Tooltip>
                  </Pressable>
                </Text>
                <View style={styles.recipientRow}>
                  <GreenUser />
                  <Text style={styles.locationTextWrapper}>{carrierName}</Text>
                </View>
                <View style={styles.recipientRow}>
                  <GreenEmail />
                  <Text style={styles.locationTextWrapper}>
                    <a href={`mailto:${carrierEmail}`}>{carrierEmail}</a>
                  </Text>
                </View>
                <View style={styles.recipientRow}>
                  <GreenPhone />
                  <Text style={styles.locationTextWrapper}>
                    <a href={`tel:${carrierPhone}`}>
                      +1 {formatPhoneNumber(carrierPhone)}
                    </a>
                  </Text>
                </View>
                <View style={styles.recipientRow}>
                  <View style={styles.iconContainer}>
                    <FaTruckMoving style={styles.icon} />
                  </View>
                  <Text style={styles.locationTextWrapper}>
                    Number of trucks: {numberOfTrucks}
                  </Text>
                </View>
                <View style={styles.recipientRow}>
                  <View style={styles.iconContainer}>
                    <FaBusinessTime style={styles.icon} />
                  </View>
                  <Text style={styles.locationTextWrapper}>
                    Carrier since: {carrierSince}
                  </Text>
                </View>
                <View style={styles.recipientRow}>
                  <View style={styles.iconContainer}>
                    <BsCurrencyDollar style={styles.icon} />
                  </View>
                  <Text style={styles.locationTextWrapper}>
                    Delivery cost: ${bidAmount}
                  </Text>
                </View>
              </View>
            </>
          )}
        {status === "auction" && statusToTMS === "sent" && (
          <View style={styles.blockWrapper}>
            <Button
              onPress={() => {
                navigate(`bids`);
              }}
            >
              View Bids
            </Button>
          </View>
        )}

        {((status === "waitingPickUp" && order.pickUpPINRequired) ||
          (status === "pickedUp" && order.deliveryPINRequired)) && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => {
                navigate(`/order/${id}/verify-driver`);
              }}
            >
              Verify driver
            </Button>
          </View>
        )}

        {status === "waitingPickUp" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => {
                navigate(`/request-edit/1/${order.id}`);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              variant="outline"
            >
              Request Changes
            </Button>
          </View>
        )}

        {status === "pickedUp" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => {
                navigate(`/request-edit/3/${order.id}`);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              variant="outline"
            >
              Request Changes
            </Button>
          </View>
        )}

        {(status === "waitingPickUpReviewChanges" ||
          status === "pickedUpReviewChanges") && (
          <View style={styles.blockWrapper}>
            <Button isDisabled={!active} onPress={acceptChanges}>
              Accept Changes
            </Button>
          </View>
        )}

        {(status === "waitingPickUpReviewChanges" ||
          status === "pickedUpReviewChanges") && (
          <View style={styles.blockWrapper}>
            <Button
              variant="fatal"
              isDisabled={!active}
              onPress={declineChanges}
            >
              Decline Changes
            </Button>
          </View>
        )}

        {status === "delivered" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => {
                setRateModalIsOpen(true);
              }}
            >
              Complete Order
            </Button>
          </View>
        )}

        {/* {status === "cancelledByCarrier" && (
          <View style={styles.blockWrapper}>
            <Text style={styles.header}>Take Action</Text>

            <Button
              isDisabled={!active}
              onPress={() => {
                setIsTakeActionAlertOpen(true);
              }}
            >
              Take Action
            </Button>
          </View>
        )} */}
        {status === "delivered" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => {
                setDisputeModalIsOpen(true);
              }}
              variant="fatal"
            >
              Start dispute
            </Button>
          </View>
        )}
        {status === "auction" && statusToTMS === "sent" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              variant="outline"
              onPress={() => performUnpublishOrder(id)}
            >
              Unpublish Order
            </Button>
          </View>
        )}

        {status === "waitingPickUp" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              variant="fatal"
              onPress={() => handleCancelClick()}
            >
              Cancel Order
            </Button>
          </View>
        )}

        {status === "new" && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              onPress={() => setShownDisclaimerModal(true)}
            >
              Publish Order
            </Button>
          </View>
        )}
        {status === "new" &&
          (statusToTMS === "draft" || statusToTMS === "notsent") && (
            <View style={styles.blockWrapper}>
              <Button
                variant="outline"
                isDisabled={!active}
                onPress={() => editOrder(id)}
              >
                Make Changes
              </Button>
            </View>
          )}

        {(status === "auction" || status === "new") && (
          <View style={styles.blockWrapper}>
            <Button
              isDisabled={!active}
              variant="fatal"
              onPress={() => {
                setShownDeleteModal(true);
              }}
            >
              Delete Order
            </Button>
          </View>
        )}

        {order?.review && (
          <>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            {order?.review && (
              <View style={styles.reviewContainer}>
                <Text style={styles.headerBlock}>Review</Text>
                <View style={styles.reviewRow}>
                  {order?.review.rating === 0 ? (
                    <View style={styles.badReviews}>
                      <FaThumbsDown />
                    </View>
                  ) : (
                    <View style={styles.goodReviews}>
                      <FaThumbsUp />
                    </View>
                  )}
                  <Text style={styles.ratingText}>
                    Your rating:
                    {order?.review.rating === 0 ? " Negative" : " Positive"}
                  </Text>
                </View>
                {order.review?.text && (
                  <View style={styles.reviewRow}>
                    <View style={styles.iconContainer}>
                      <FaComment style={styles.icon} />
                    </View>
                    <Text style={styles.commentText}>
                      Your comment: {order.review.text}
                    </Text>
                  </View>
                )}
                {order?.review.rating === 0 && (
                  <View style={{ marginTop: 12 }}>
                    <Button
                      isDisabled={!active}
                      onPress={() => setRateModalIsOpen(true)}
                      variant="outline"
                    >
                      Change Review
                    </Button>
                  </View>
                )}
              </View>
            )}
          </>
        )}
        <View style={styles.divider} />
      </ScrollView>
      <DeleteAlert />
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#ffffff" />
        </View>
      </Modal>
      <PublishOrderAlert />
      <DisputeAlert />
      <RateAlert />
      <TakeActionAlert />
      <CancelOrderFlow />
      <SendInfoModal
        isOpen={isSendInfoModalOpen}
        onClose={closeSendInfoModal}
        infoType={infoType}
        onSendSms={handleSendSms}
        onSendEmail={handleSendEmail}
      />
    </>
  );
}
