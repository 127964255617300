const styles = {
  container: {
    flex: 1,
    paddingHorizontal: 20,
    // paddingTop: 20,
    paddingBottom: 20,
  },
  fullImage: {
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },
  header: {
    color: "#171717",
    fontSize: 20,
    fontWeight: "700",
    marginVertical: 10,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 18,
    fontWeight: "700",
    marginVertical: 10,
  },
  recipientRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
  },
  greyText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#737373",
  },
  locationWrapper: {
    flexDirection: "row",
    marginTop: 12,
  },
  locationTextWrapper: {
    marginLeft: 6,
    flex: 1,
  },
  locationText: {
    color: "#737373",
    fontSize: 18,
    fontWeight: "500",
  },
  calendarIcon: {
    marginRight: 8,
  },
  addressIcon: {
    marginHorizontal: 2,
    flexShrink: 0,
  },
  button: {
    marginTop: 16,
  },
  blockWrapper: {
    paddingBottom: 8,
    marginTop: 24,
  },
  divider: {
    height: 100,
  },
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    marginRight: 3,
    width: 116,
  },
  acButton: {
    flex: 1,
    marginLeft: 3,
  },
  dcButton: {
    flex: 1,
    marginLeft: 3,
    width: 116,
  },
  status: {
    flexDirection: "row",
    marginBottom: 15,
    gap: 6,
  },
  row: {
    flexDirection: "row",
    gap: 5,
  },
  sendOrderButton: {
    backgroundColor: "#FFA500",
    marginTop: 16,
  },
  inactiveContainer: {
    opacity: 0.5, // Semi-transparent to show it's inactive
    backgroundColor: "#F3F4F6", // Muted background color
  },
  sendingOrderContainer: {
    marginTop: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  editOrderButton: {
    marginTop: 16,
    backgroundColor: "#00C879",
  },
  activeImage: {
    borderColor: "#1DCE80", // Highlight the selected image with green border
  },
  locationPinWrapper: {
    marginVertical: 10,
  },
  locationDetailsWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
    flexWrap: "wrap",
    paddingRight: 10,
  },
  pinText: {
    fontSize: 18,
    letterSpacing: 4,
    width: 70,
  },
  iconWrapper: {
    marginLeft: 8,
  },
  sendButton: {
    width: 127,
    borderRadius: 6,
    alignItems: "center",
  },
  sendButtonContainer: {
    flexDirection: "column",
  },
  publishButton: {
    flex: 1,
    marginLeft: 3,
  },
  cancelButton: {
    flex: 1,
    marginRight: 3,
  },
  thumbButton: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F3F4F6",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    cursor: "pointer",
  },
  selectedButtonUp: {
    backgroundColor: "#1DCE80",
    borderColor: "#1DCE80",
  },
  selectedButtonDown: {
    backgroundColor: "#B91C1C",
    borderColor: "#B91C1C",
  },
  spacer: {
    width: 16,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 14,
  },
  iconContainer: {
    width: 32,
    height: 32,
    backgroundColor: "#000000",
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: "#fff",
    fontSize: 16,
  },
  reviewRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
  },
  goodReviews: {
    width: 32,
    height: 32,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    color: "#1DCE80",
  },
  badReviews: {
    width: 32,
    height: 32,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    color: "#B91C1C",
  },
  commentText: {
    marginLeft: 6,
  },
  ratingText: {
    marginLeft: 6,
  },
  vehicleWrapper: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  },
  toggleButton: {
    padding: 10,
    borderRadius: 5,
    cursor: "pointer",
  },
  vehicleDetails: {
    overflow: "hidden",
    transition: "max-height 0.3s ease-out",
    maxHeight: "0px", // Default collapsed state
  },
};

export default styles;
