import * as React from "react";
import { extendTheme, NativeBaseProvider, Pressable } from "native-base";
import { Provider } from "react-redux";
import store, { persistor } from "./networking/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "./navigator";
export default function App() {
  const newTheme = {
    components: {
      Button: {
        baseStyle: {
          borderRadius: "12px",
          width: "full",
          fontSize: 300,
          borderColor: "#000",
          borderWidth: "1px",
        },
        defaultProps: {
          _text: {
            fontWeight: "600",
            fontSize: "14px",
          },
        },
        variants: {
          solid: {
            bg: "black",
            _hover: {
              bg: "#333333",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          fatal: {
            bg: "#8B0000",
            color: "#FFFFFF",
            _hover: {
              bg: "#991A1A",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          warning: {
            bg: "#FFA500",
            _hover: {
              bg: "#E59400",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          cancel: {
            bg: "#FFFFFF",
            color: "#A0A0A0",
            _hover: {
              bg: "#F0F0F0",
            },
            _text: {
              color: "#A0A0A0",
            },
          },
        },
      },
      Pressable: {
        baseStyle: {
          _hover: {
            color: "#1A1A1A",
          },
          fontWeight: "700",
          fontSize: 16,
        },
        defaultProps: {
          _text: {
            fontWeight: "600",
            fontSize: "14px",
          },
        },
        variants: {
          option: {
            color: "black",
            borderRadius: 4,
            fontWeight: "500",
            fontSize: 14,
          },
        },
      },
      Input: {
        baseStyle: {
          height: 44,
          borderRadius: "12px",
          borderWidth: "1px",
          borderColor: "#A0A0A0", // Default border color
          _hover: {
            borderColor: "#1A1A1A", // Darker border on hover
            backgroundColor: "#F5F5F5", // Slightly gray background
          },
          _focus: {
            backgroundColor: "#F5F5F5",
          },
        },
        defaultProps: {
          fontSize: "16px",
        },
      },
      Checkbox: {
        baseStyle: {
          borderRadius: "4px",
          borderWidth: "2px",
          borderColor: "#A0A0A0", // Default border color
          _checked: {
            borderColor: "#000000", // Black border when checked
            bg: "#000000", // Black fill when checked
          },
          _hover: {
            borderColor: "#1A1A1A", // Darker border on hover
            backgroundColor: "#333333",
          },
          _focus: {
            outline: "2px solid #1A1A1A",
            outlineOffset: "2px",
            boxShadow: "0 0 4px rgba(26, 26, 26, 0.5)",
          },
        },
        defaultProps: {
          size: "md",
          colorScheme: "black",
        },
      },
      Text: {
        defaultProps: {
          fontSize: "16px",
        },
      },
      TextArea: {
        defaultProps: {
          fontSize: "16px",
        },
      },
    },
    fontSizes: {
      "2xs": 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      "2xl": 24,
      "3xl": 30,
      "4xl": 36,
      "5xl": 48,
      "6xl": 60,
      "7xl": 72,
      "8xl": 96,
      "9xl": 128,
    },
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
      extraBlack: 950,
    },
    colors: {
      primary: {
        800: "#333333",
        600: "#black",
      },
      secondary: {
        600: "#000000",
      },
    },
    breakpoints: {
      base: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  };
  const theme = extendTheme({ ...newTheme });
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="801571357118-7lntc55noadtf2nsa6v0n21a9ek38fps.apps.googleusercontent.com">
        <PersistGate loading={null} persistor={persistor}>
          <NativeBaseProvider theme={theme}>
            <Router />
          </NativeBaseProvider>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  );
}
