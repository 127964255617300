const styles = {
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
    flex: 1,
  },
  stepWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  stepContainer: {
    alignItems: "center",
    width: 60,
  },
  step: {
    width: 35,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
  },
  stepLabel: {
    fontSize: 16,
    marginTop: 4,
    textAlign: "center",
  },
  dashedLine: {
    width: 30,
    marginHorizontal: 5,
    marginBottom: 15,
  },
};

export default styles;
