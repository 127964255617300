const styles = {
  inputOpt: {
    fontSize: 16,
    height: 46,
    width: 46,
    border: "1px solid #E5E5E5",
    borderRadius: 12,
    appearance: "none",
    margin: 4,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: "#000000",
  },
};

export default styles;
