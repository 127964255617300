import * as React from "react";
import {
  Center,
  Checkbox,
  FormControl,
  Input,
  Button,
  Select,
  Spinner,
  Text,
  TextArea,
  View,
  useToast,
  ScrollView,
  Pressable,
  Popover,
  Modal,
  useBreakpointValue,
  FlatList,
  Image,
} from "native-base";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import {
  Vin,
  Model,
  Year,
  Length,
  Height,
  Width,
  Weight,
  Upload,
} from "../../../assets/images/svg";
import { carsList, carsObj } from "../../../assets/data/carsList";
import { useEffect, useState, useCallback, useMemo } from "react";
import instance from "../../../networking/axios";
import { API_URL, imageHost } from "../../../config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ReactImageUploading from "react-images-uploading";
import { toastErrorConfig } from "../../../config/constants";
import Stepper from "../../../components/stepper";
import { getInfoByVinNumber } from "../../../networking/orders/ordersApi";
import { IoAlertCircleOutline } from "react-icons/io5";
import ImagePlaceholderSvg from "../../../assets/images/svg/imagePlaceholder";
import VehicleForm from "../../../components/VehicleForm";
export default function FirstStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state.order);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { orders, active } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;
  const defaultVehicle = {
    vin: "",
    brand: "",
    model: "",
    year: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    isInoperable: false,
    isTrailerEnclosed: false,
    images: [],
    personalItems: "",
    havePersonal: false,
    hasCustom: false,
    customChanges: "",
  };
  const [localInputs, setLocalInputs] = useState({
    vehicles:
      orderState?.vehicles.length > 0
        ? orderState.vehicles
        : profileOrder?.vehicles.length > 0
        ? profileOrder.vehicles
        : [defaultVehicle],
    orderName: profileOrder?.orderName || orderState?.orderName || "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  const isMobile = useBreakpointValue({
    base: true, // represents mobile
    sm: false, // represents tablet or larger screens
  });

  useEffect(() => {
    if (!active) {
      navigate("/orders");
    }
  }, [active]);

  useEffect(() => {
    if (profileOrder) {
      // Set general order fields
      Object.entries(profileOrder).forEach(([key, value]) => {
        if (key !== "vehicles") {
          dispatch(setInputValue({ key, value }));
        }
      });
    }
  }, [profileOrder]);

  const handleChangeVehicleDetail = useCallback((index, changes) => {
    setLocalInputs((prevInputs) => {
      const updatedVehicles = [...prevInputs.vehicles];
      updatedVehicles[index] = { ...updatedVehicles[index], ...changes };
      return {
        ...prevInputs,
        vehicles: updatedVehicles,
      };
    });
  }, []);

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear() + 1;
    return Array.from({ length: currentYear - 1899 }, (_, i) =>
      (currentYear - i).toString()
    );
  }, []);

  const validateVehicles = () => {
    let allValid = true;
    let newValidationErrors = {};

    localInputs.vehicles.forEach((vehicle, index) => {
      let errors = {}; // Store errors per vehicle

      if (!vehicle.vin || vehicle.vin.length !== 17) {
        errors.vin = true;
        allValid = false;
      }

      if (!vehicle.brand) {
        errors.brand = true;
        allValid = false;
      }

      if (!vehicle.model) {
        errors.model = true;
        allValid = false;
      }

      if (!vehicle.year) {
        errors.year = true;
        allValid = false;
      }

      if (!vehicle.images || vehicle.images.length === 0) {
        errors.images = true;
        allValid = false;
      }

      if (Object.keys(errors).length > 0) {
        newValidationErrors[index] = errors;
      }
    });

    if (Object.keys(newValidationErrors).length > 0) {
      toast.show({
        description: "Please fill in all required fields",
        ...toastErrorConfig,
      });
    }

    // Update validation state
    setValidationErrors(newValidationErrors);

    return allValid;
  };

  const checkForDuplicateVINs = () => {
    const vins = localInputs.vehicles.map((vehicle) => vehicle.vin);
    const uniqueVins = new Set(vins);
    return uniqueVins.size !== vins.length;
  };

  const nextStep = () => {
    if (checkForDuplicateVINs()) {
      toast.show({
        description:
          "Duplicate VIN detected. Each vehicle must have a unique VIN.",
        ...toastErrorConfig,
      });
      return; // Stop if duplicates are found
    }

    if (validateVehicles()) {
      let updatedOrderName = localInputs.orderName;
      if (localInputs.orderName === "") {
        updatedOrderName = localInputs.vehicles[0].vin.slice(-8);

        setLocalInputs((prevInputs) => ({
          ...prevInputs,
          orderName: updatedOrderName,
        }));
      }
      // Dispatch local state to Redux
      Object.entries({ ...localInputs, orderName: updatedOrderName }).forEach(
        ([key, value]) => {
          dispatch(setInputValue({ key, value }));
        }
      );

      navigate(
        location.pathname.includes("request-edit")
          ? `/request-edit/2/${id}`
          : id
          ? `/edit/2/${id}`
          : "/create/2"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const cancel = () => {
    navigate("/orders");
  };

  const getByVin = async (vin, index) => {
    if (!vin || vin.length !== 17) {
      return;
    }
    setIsLoading(true);
    try {
      const res = await dispatch(getInfoByVinNumber({ vin })).unwrap();

      if (res) {
        handleChangeVehicleDetail(index, {
          brand: res.brand || "",
          model: res.model || "",
          year: res.year || "",
          height: res.height || "",
          width: res.width || "",
          weight: res.weight || "",
          length: res.length || "",
        });
        setValidationErrors((prevErrors) => {
          let errors = { ...prevErrors[index] };

          // Only remove errors if the field has a valid value in `res`
          if (res.brand) delete errors.brand;
          if (res.model) delete errors.model;
          if (res.year) delete errors.year;

          return {
            ...prevErrors,
            [index]: errors,
          };
        });
      }
    } catch (error) {
      toast.show({
        description: "Unable to retrieve car info using the provided VIN",
        ...toastErrorConfig,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const validateSingleField = (fieldKey, fieldValue, index) => {
    setValidationErrors((prevErrors) => {
      let errors = { ...prevErrors[index] }; // Preserve other errors

      switch (fieldKey) {
        case "vin":
          if (!fieldValue || fieldValue.length !== 17) {
            errors.vin = true;
            toast.show({
              description: `Please enter a valid 17-character VIN`,
              ...toastErrorConfig,
            });
          } else {
            delete errors.vin;
          }
          break;

        case "brand":
          if (!fieldValue) {
            errors.brand = true;
            toast.show({
              description: `Please select a car brand`,
              ...toastErrorConfig,
            });
          } else {
            delete errors.brand;
          }
          break;

        case "model":
          if (!fieldValue) {
            errors.model = true;
            toast.show({
              description: `Please select a car model`,
              ...toastErrorConfig,
            });
          } else {
            delete errors.model;
          }
          break;

        case "year":
          if (!fieldValue) {
            errors.year = true;
            toast.show({
              description: `Please select a car year`,
              ...toastErrorConfig,
            });
          } else {
            delete errors.year;
          }
          break;

        case "images":
          if (!fieldValue || fieldValue.length === 0) {
            errors.images = true;
            toast.show({
              description: `Please upload at least one image of the vehicle`,
              ...toastErrorConfig,
            });
          } else {
            delete errors.images;
          }
          break;

        default:
          break;
      }

      return {
        ...prevErrors,
        [index]: errors,
      };
    });
  };

  const addVehicle = useCallback(() => {
    if (validateVehicles()) {
      setLocalInputs((prevInputs) => ({
        ...prevInputs,
        vehicles: [...prevInputs.vehicles, { ...defaultVehicle }],
      }));
    }
  }, [localInputs, defaultVehicle]);

  const removeVehicle = useCallback(
    (index) => {
      setLocalInputs((prevInputs) => {
        if (index === 0 && prevInputs.vehicles.length === 1) {
          return { ...prevInputs, vehicles: [defaultVehicle] }; // Reset to default state
        } else {
          return {
            ...prevInputs,
            vehicles: prevInputs.vehicles.filter((_, i) => i !== index),
          };
        }
      });
    },
    [setLocalInputs, defaultVehicle]
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={1} />
        </View>
        <View>
          <FormControl.Label>Order name/ID (optional)</FormControl.Label>
          <View
            style={[
              styles.inputGrid,
              { backgroundColor: "#F5F5F5", borderRadius: 12 },
            ]}
          >
            <Input
              key="orderName"
              isDisabled={location.pathname.includes("request-edit")}
              // value={localInputs.orderName}
              // onChangeText={(value) => {
              //   setLocalInputs((prevInputs) => ({
              //     ...prevInputs,
              //     orderName: value,
              //   }));
              // }}
              defaultValue={localInputs.orderName}
              onBlur={(e) => {
                const newValue = e.nativeEvent.text.trim();
                if (newValue !== localInputs.orderName) {
                  setLocalInputs((prevInputs) => ({
                    ...prevInputs,
                    orderName: e.nativeEvent.text,
                  }));
                }
              }}
              type="text"
              placeholder="Enter order name or ID"
            />
          </View>
          <View style={styles.divider} />

          <FlatList
            data={localInputs.vehicles}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => (
              <VehicleForm
                key={index}
                vehicle={item}
                index={index}
                handleChangeVehicleDetail={handleChangeVehicleDetail}
                removeVehicle={removeVehicle}
                validateSingleField={validateSingleField}
                getByVin={getByVin} // Pass getByVin to each VehicleForm
                isMobile={isMobile}
                validationErrors={validationErrors}
                active={active}
                years={years}
              />
            )}
          />

          {localInputs.vehicles.length < 10 && (
            <View style={{ maxWidth: 180 }}>
              <Button
                isDisabled={
                  location.pathname.includes("request-edit") || !active
                }
                onPress={addVehicle}
              >
                + Add Another Vehicle
              </Button>
            </View>
          )}
          <View style={styles.divider} />
          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={cancel}
            prevText={"Cancel"}
          />
        </View>
      </ScrollView>
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#ffffff" />
        </View>
      </Modal>
    </>
  );
}
