import React from "react";
import { Box, Text, Center } from "native-base";

const NotFoundScreen = () => {
  return (
    <Center flex={1} justifyContent="center" alignItems="center" bg="white" mt={40}>
      <Box
        borderWidth={2}
        borderColor="red.500"
        px={6}
        py={4}
        rounded="lg"
        shadow={2}
      >
        <Text fontSize="2xl" fontWeight="bold" color="red.500">
          404
        </Text>
        <Text fontSize="lg" color="gray.600">
          Page Not Found
        </Text>
      </Box>
    </Center>
  );
};

export default NotFoundScreen;

