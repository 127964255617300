const styles = {
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    minHeight: "90vh",
  },
  containerWrapper: {
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,
  },
  container: {
    alignItems: "center",
    paddingHorizontal: 20,
  },
  logo: {
    marginTop: 24,
    marginBottom: 40,
  },
  button: {
    marginTop: 10,
  },
  smallText: {
    color: "#A3A3A3",
    fontWeight: "400",
    marginLeft: 5,
    marginRight: 5,
  },
  dividerContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  dividerWrapper: {
    width: 80,
  },
  divider: {
    backgroundColor: "#A3A3A3",
    height: 1,
  },
  black: {
    borderColor: "#000000",
  },
  changePage: {
    paddingTop: 24,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
};

export default styles;
