import * as React from 'react';
import Svg, {G, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G
        stroke="#000000"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round">
        <Path d="M2.643 6.2L10 10.46l7.308-4.234M10 18.009V10.45" />
        <Path d="M8.275 2.067l-4.45 2.475C2.818 5.1 1.993 6.5 1.993 7.65v4.708c0 1.15.825 2.55 1.834 3.109l4.45 2.475c.95.525 2.508.525 3.458 0l4.45-2.475c1.008-.559 1.833-1.959 1.833-3.109V7.65c0-1.15-.825-2.55-1.833-3.108l-4.45-2.475c-.959-.534-2.508-.534-3.459 0z" />
        <Path d="M14.166 11.033v-3.05L6.258 3.417" />
      </G>
    </Svg>
  );
}

export default SvgComponent;
