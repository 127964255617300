import * as React from "react";
import {
  Text,
  View,
  FlatList,
  AlertDialog,
  Button,
  Select,
  FormControl,
  Input,
  Pressable,
  useToast,
  Box,
  Spinner,
  Modal,
  Divider,
} from "native-base";
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from "react-square-web-payments-sdk";

import styles from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  acceptBid,
  declineBid,
  getOrderBids,
} from "../../networking/orders/ordersApi";
import { Dollar } from "../../assets/images/svg";
import { useNavigate, useParams } from "react-router-dom";
import { SquareAppId, SquareLocationId } from "../../config";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { sendCounterBid } from "../../networking/orders/ordersApi";
import { updateOrder } from "../../networking/profile/profileSlice";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { formatDistanceToNow } from "date-fns";
import { FaChevronUp, FaChevronDown } from "react-icons/fa6";
import { formatStringToDate } from "../../utils";
import { EmptyOrdersImage } from "../../assets/images/svg";
import { getProfile } from "../../networking/profile/profileApi";

export default function BidsScreen() {
  const { id } = useParams();
  const toast = useToast();
  const { orders, active, credits } = useSelector((state) => state.profile);
  const order = orders.find((order) => order.id === id);
  // const declineRef = React.useRef(null);
  // const counterBidRef = React.useRef(null);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  // const [declineModalIsOpen, setDeclineModalIsOpen] = useState(false);
  const [counterBidModalIsOpen, setCounterBidModalIsOpen] = useState(false);
  const [bids, setBids] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [sorting, setSorting] = useState("");
  const [currentBid, setCurrentBid] = useState({});
  const { fullName, email, phone } = useSelector((state) => state.profile);
  //const [counterPrice, setCounterPrice] = useState("");
  const [reviews, setReviews] = useState([]);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let counterPrice = 0;

  const getBids = () => {
    setIsLoading(true);
    setSorting("");
    dispatch(getOrderBids({ id: order.id }))
      .then(({ payload }) => {
        const groupedBids = Object.values(
          payload.reduce((acc, bid) => {
            const key = `${bid.orderID}-${bid.carrierID}`;
            if (!acc[key]) {
              acc[key] = { carrierName: bid.carrierName, bids: [] };
            }
            acc[key].bids.push(bid); // Collect all bids in order
            return acc;
          }, {})
        );

        // Sort each group's bids by creation date (latest last)
        groupedBids.forEach((group) => {
          group.bids.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
        });

        setBids(groupedBids);
      })
      .catch((err) => {
        console.log("Couldn't get bids", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (order.status !== "auction") {
      navigate("/orders", { replace: true });
    }
    getBids();
  }, [order]);

  const sortList = [
    { name: "Price", value: "bidAmount" },
    { name: "Rating", value: "carrierRating" },
  ];

  const EmptyBids = () => {
    return (
      // <View style={styles.center}>
      //   <Text style={styles.centerText}>Your didn't receive bids yet</Text>
      // </View>
      <Box style={styles.wrapper}>
        <View style={styles.emptyContainer}>
          <EmptyOrdersImage />
          <Text style={styles.emptyText}>
            You haven't received any bids on this order yet. Hang tight,
            carriers can see your order and will start bidding soon!
          </Text>
        </View>
        <View style={{ width: "50%" }}>
          <Button onPress={backToOrders}>Back to Orders</Button>
        </View>
      </Box>
    );
  };

  const backToOrders = () => {
    navigate("/orders");
  };

  const handleReviewClick = (reviews) => {
    setReviews(reviews);
    setReviewModalOpen(true);
  };

  const ReviewModal = ({ isOpen, onClose, reviews }) => {
    return (
      <AlertDialog isOpen={isOpen} onClose={onClose} useRNModal={true}>
        <AlertDialog.Content maxHeight="50vh" overflow="hidden">
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            Carrier Reviews
            <Text>{currentBid.carrierName}</Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            {reviews.length ? (
              reviews.map((review, index) => (
                <Box key={index} mb="3" style={styles.reviewItem}>
                  <Text>{review.name}</Text>
                  {review.reviewText !== "" && (
                    <Text style={styles.reviewText}>"{review.reviewText}"</Text>
                  )}
                  <Text style={styles.reviewMeta}>
                    Rating:{" "}
                    {review.rating === 0 ? (
                      <FaThumbsDown style={styles.badReviews} />
                    ) : (
                      <FaThumbsUp style={styles.goodReviews} />
                    )}{" "}
                    Date: {formatStringToDate(review.date.split("T")[0])}
                  </Text>
                </Box>
              ))
            ) : (
              <Text>No reviews available for this carrier yet.</Text>
            )}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button onPress={onClose}>Close</Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const Bid = ({ group }) => {
    const { carrierName, bids } = group;
    const lastBid = bids[bids.length - 1]; // Last (latest) bid
    const previousBids = bids.slice(0, -1).reverse(); // All previous bids
    const [isPreviousBidsVisible, setPreviousBidsVisible] = useState(false);

    const {
      carrierSince,
      completedOrdersAmount,
      numberOfTrucks,
      goodReviewsAmount,
      badReviewsAmount,
    } = lastBid;

    // Check if the last bid was placed by the carrier
    const isCarrierBid = lastBid.kind === "carrierBid";
    const isAcceptedBid = lastBid.kind === "bidAcceptedByCustomer";
    const isCounterBid = lastBid.kind === "customerCounterBid";
    const isDeclinedBid = lastBid.kind === "bidDeclinedByCustomer";

    // Calculate good/bad review percentages
    const totalReviews = goodReviewsAmount + badReviewsAmount;

    return (
      <>
        {!isDeclinedBid ? (
          <View style={styles.bidWrapper}>
            {isCarrierBid && <Text style={styles.newBidText}>New Bid</Text>}

            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <View style={styles.headerWrapper}>
                <Text style={styles.bidName}>*Carrier name is hidden*</Text>
                <Text style={styles.detailsWrapper}>
                  Completed Orders: {completedOrdersAmount}
                </Text>
                <Text style={styles.detailsWrapper}>
                  Number of Trucks: {numberOfTrucks}
                </Text>
              </View>

              <View style={styles.ratingWrapper}>
                <Text style={styles.carrierSince}>Since {carrierSince}</Text>
                {lastBid.goodReviewsAmount > 0 &&
                lastBid.badReviewsAmount > 0 ? (
                  <View style={{ alignItems: "flex-end" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: 10,
                      }}
                    >
                      <Text style={styles.goodReviews}>
                        <FaThumbsUp /> {goodReviewsAmount}
                      </Text>
                      <Text style={styles.badReviews}>
                        <FaThumbsDown /> {badReviewsAmount}
                      </Text>
                    </View>
                    {lastBid.badReviewsAmount > 0 && (
                      <Pressable
                        onPress={() => {
                          setCurrentBid(lastBid);
                          handleReviewClick(lastBid.reviews);
                        }}
                      >
                        <Text style={styles.reviewsButton}>Reviews</Text>
                      </Pressable>
                    )}
                  </View>
                ) : (
                  <Text>No Rating yet</Text>
                )}
              </View>
            </View>

            <View style={styles.bidPrice}>
              {isCarrierBid ? (
                <Text style={styles.bidText}>
                  Bid:
                  <Text style={styles.bidAmount}> ${lastBid.bidAmount}</Text>
                </Text>
              ) : isCounterBid ? (
                <Text>
                  Counter-bid sent for ${lastBid.bidAmount}, waiting for
                  carrier's response.
                </Text>
              ) : isDeclinedBid ? (
                <Text>Bid from for ${lastBid.bidAmount} was declined</Text>
              ) : (
                <></>
              )}
            </View>

            {isCarrierBid ? (
              <View style={styles.buttonsWrapper}>
                <Button
                  isDisabled={!active}
                  variant="outline"
                  onPress={() => {
                    setCurrentBid(lastBid);
                    setCounterBidModalIsOpen(true);
                  }}
                  style={styles.button}
                >
                  Counter Bid
                </Button>
                <View style={styles.divider} />

                <Button
                  isDisabled={!active}
                  onPress={() => {
                    setCurrentBid(lastBid);
                    setAcceptModalIsOpen(true);
                  }}
                  style={styles.button}
                >
                  Accept
                </Button>

                {/* <View style={styles.divider} /> */}

                {/* <Button
                  isDisabled={!active}
                  variant="fatal"
                  onPress={() => {
                    setCurrentBid(lastBid);
                    setDeclineModalIsOpen(true);
                  }}
                  style={styles.button}
                >
                  Decline
                </Button> */}
              </View>
            ) : (
              <Text style={{ marginTop: 10 }}>
                No action needed for this bid.
              </Text>
            )}

            {previousBids.length > 0 && (
              <Pressable
                onPress={() => setPreviousBidsVisible(!isPreviousBidsVisible)}
                style={styles.foldButton}
              >
                <Text style={styles.foldButtonText}>
                  {isPreviousBidsVisible
                    ? "Hide Previous Bids  "
                    : "Show Previous Bids "}
                  {isPreviousBidsVisible ? <FaChevronUp /> : <FaChevronDown />}
                </Text>
              </Pressable>
            )}

            {isPreviousBidsVisible && (
              <View style={styles.previousBidsWrapper}>
                {previousBids.map((prevBid, index) => (
                  <View
                    key={index}
                    style={[
                      styles.inactiveBidWrapper,
                      prevBid.kind === "customerCounterBid" && styles.disabled,
                    ]}
                  >
                    <Text style={styles.bidName}>
                      {prevBid.kind === "bidDeclinedByCustomer"
                        ? `Bid declined by user`
                        : prevBid.kind === "customerCounterBid"
                        ? `Counter-bid placed by user`
                        : `Bid placed by: ${
                            prevBid.kind === "customerCounterBid"
                              ? "User"
                              : "Carrier"
                          }`}
                    </Text>
                    {prevBid.kind === "bidDeclinedByCustomer" ? (
                      ""
                    ) : (
                      <Text style={styles.bidPrice}>
                        Bid: ${prevBid.bidAmount}
                      </Text>
                    )}
                    <Text style={styles.timestamp}>
                      {formatDistanceToNow(new Date(prevBid.createdAt), {
                        addSuffix: true,
                      })}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
        ) : (
          <></>
        )}
        <ReviewModal
          isOpen={isReviewModalOpen}
          onClose={() => setReviewModalOpen(false)}
          reviews={reviews || []}
        />
      </>
    );
  };

  const CounterBidAlert = () => {
    const onClose = () => {
      setCounterBidModalIsOpen(false);
    };
    const onSend = () => {
      // Ensure counterPrice is a valid number
      const isValidNumber = /^\d+(\.\d+)?$/.test(counterPrice);
      if (
        !isValidNumber ||
        !(
          Number.isFinite(parseInt(counterPrice, 10)) &&
          parseInt(counterPrice, 10) > 0.01
        )
      ) {
        toast.show({
          description: "Please enter a valid counter-bid amount",
          ...toastErrorConfig,
        });
        return;
      }
      setCounterBidModalIsOpen(false);
      dispatch(
        sendCounterBid({
          data: {
            bidID: currentBid.bidID,
            bidAmount: +counterPrice,
          },
        })
      )
        .unwrap()
        .then(() => {
          getBids();
        })
        .catch((error) => {
          toast.show({
            description: "Failed to send a counter bid. Please try again.",
            ...toastErrorConfig,
          });
        });
    };
    return (
      <AlertDialog
        isOpen={counterBidModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Counter Bid</AlertDialog.Header>
          <AlertDialog.Body>
            Please, offer your price for the Order
            <FormControl.Label>Counter-bid</FormControl.Label>
            <Input
              onChangeText={(e) => {
                counterPrice = e;
              }}
              type="number"
              keyboardType="numeric"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Dollar />
                </View>
              }
              placeholder="Enter price"
              onKeyPress={(event) => {
                if (event.nativeEvent.key === "Enter") {
                  onSend();
                }
              }}
            />
            {/* <Input
              onChangeText={(value) => {
                // Ensure only numeric values are accepted
                let numericValue = value.replace(/[^0-9]/g, "");
                counterPrice = numericValue;
              }}
              value = {counterPrice.toString()}
              keyboardType="numeric" // Numeric keyboard for mobile devices
              placeholder="Enter price"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Dollar />
                </View>
              }
            /> */}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button style={styles.cancel} variant="cancel" onPress={onClose}>
                Cancel
              </Button>
              <Button
                isDisabled={!active}
                style={styles.acButton}
                onPress={onSend}
              >
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const AcceptAlert = () => {
    const onClose = () => {
      setAcceptModalIsOpen(false);
    };
    const [remainingCharge, setRemainingCharge] = useState(0);
    const [creditsUsage, setCreditsUsage] = useState(0);

    useEffect(() => {
      if (currentBid?.bidAmount) {
        const bidAmount = Number(currentBid.bidAmount);
        const creditsDeduction = Math.min(bidAmount, credits);
        const remainingChargeAmount = bidAmount - creditsDeduction;

        setCreditsUsage(creditsDeduction);
        setRemainingCharge(remainingChargeAmount);
      }
    }, [currentBid, credits]);

    const handlePayment = (token = null) => {
      if (isLoading || isPaying) return;
      setIsPaying(true);

      dispatch(
        acceptBid({
          data: {
            bidID: currentBid.bidID,
            orderID: currentBid.orderID,
            nonce: token?.token || null, // Send token if charging a card, otherwise null
          },
        })
      )
        .unwrap()
        .then(() => {
          // dispatch(updateOrder({ id, status: "waitingPickUp" }));
          toast.show({
            description: "Order was paid successfully",
            ...toastSuccessConfig,
          });
          setAcceptModalIsOpen(false);
          setTimeout(() => {
            navigate("/orders", { replace: true });
          }, 100);
        })
        .catch((error) => {
          dispatch(getProfile({})).unwrap();
          console.error("Error accepting bid:", error);
          toast.show({
            description: "Something went wrong. Please try again.",
            ...toastErrorConfig,
          });
        })
        .finally(() => {
          setIsPaying(false);
        });
    };

    return (
      <AlertDialog
        isOpen={acceptModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content width="90%" minHeight="30%" maxHeight="90%">
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Accept Bid</AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              Are you sure you want to accept the bid? If you accept this bid,
              all other bids will be automatically declined. You will receive an
              order confirmation via email and SMS.
            </Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            {credits > 0 && (
              <View>
                <Text fontWeight="bold">
                  Total: ${Number(currentBid.bidAmount).toFixed(2)}
                </Text>
                <Text mt={2} color="green.500">
                  - ${creditsUsage.toFixed(2)} will be deducted from your
                  credits
                </Text>
                {remainingCharge === 0 && (
                  <View>
                    <Text mt={2} color="green.500">
                      ✅ Fully covered by credits
                    </Text>
                    <Text mt={2}>
                      Your new credits balance will be:{" "}
                      <Text fontWeight="bold">${credits - creditsUsage}</Text>
                    </Text>
                  </View>
                )}
              </View>
            )}
            <Text mt={2} fontWeight="bold">
              Total Due: ${remainingCharge.toFixed(2)}
            </Text>

            {remainingCharge > 0 ? (
              <PaymentForm
                applicationId={SquareAppId}
                locationId={SquareLocationId}
                cardTokenizeResponseReceived={(token, verifiedBuyer) =>
                  handlePayment(token)
                }
                createPaymentRequest={() => ({
                  countryCode: "US",
                  currencyCode: "USD",
                  total: {
                    amount: remainingCharge.toString(),
                    label: currentBid?.carrierName || "",
                  },
                })}
                createVerificationDetails={() => ({
                  amount: remainingCharge.toString(),
                  currencyCode: "USD",
                  intent: "CHARGE",
                  billingContact: {
                    familyName: fullName,
                    email: email,
                    phone: phone,
                  },
                })}
              >
                <View style={{ height: 10 }} />
                <ApplePay />
                <View style={{ height: 10 }} />
                <GooglePay />
                <View style={{ height: 10 }} />
                <CreditCard
                  buttonProps={{
                    css: {
                      borderRadius: "12px",
                      backgroundColor: isPaying ? "#ccc" : "#000000",
                      fontSize: "14px",
                      color: "#fff",
                      cursor: isPaying ? "not-allowed" : "pointer",
                      "&:hover": {
                        backgroundColor: isPaying ? "#ccc" : "#F5F5F5",
                      },
                    },
                    isDisabled: isPaying || !active,
                    children: isPaying ? (
                      <Spinner color="white" size="sm" />
                    ) : (
                      "Pay Now"
                    ),
                    onClick: () => {
                      if (isPaying) return;
                    },
                  }}
                />
              </PaymentForm>
            ) : (
              <Button
                mt={4}
                isDisabled={isPaying}
                onPress={() => handlePayment()}
              >
                Confirm Payment
              </Button>
            )}
          </AlertDialog.Body>

          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button style={styles.cancel} variant="outline" onPress={onClose}>
                Cancel
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  // const DeclineAlert = () => {
  //   const onClose = () => {
  //     setDeclineModalIsOpen(false);
  //   };
  //   const onDecline = () => {
  //     setDeclineModalIsOpen(false);
  //     dispatch(
  //       declineBid({
  //         data: {
  //           orderID: id,
  //           bidID: currentBid.bidID,
  //         },
  //       })
  //     ).then(() => {
  //       getBids();
  //     });
  //   };
  //   return (
  //     <AlertDialog
  //       isOpen={declineModalIsOpen}
  //       onClose={onClose}
  //       useRNModal={true}
  //     >
  //       <AlertDialog.Content>
  //         <AlertDialog.CloseButton />
  //         <AlertDialog.Header>Decline Bid</AlertDialog.Header>
  //         <AlertDialog.Body>
  //           Are you sure you want to decline the bid? This carrier won't be able
  //           to place any more bids for this order.
  //         </AlertDialog.Body>
  //         <AlertDialog.Footer>
  //           <View style={styles.buttonsRow}>
  //             <Button
  //               style={styles.cancel}
  //               variant="cancel"
  //               onPress={onClose}
  //             >
  //               Cancel
  //             </Button>
  //             <Button
  //               isDisabled={!active}
  //               style={styles.dcButton}
  //               onPress={onDecline}
  //               variant="fatal"
  //             >
  //               Decline
  //             </Button>
  //           </View>
  //         </AlertDialog.Footer>
  //       </AlertDialog.Content>
  //     </AlertDialog>
  //   );
  // };

  const sortBy = (key) => {
    const sortedBids = [...bids].sort((a, b) => {
      const getLastBidAmount = (group) =>
        group.bids[group.bids.length - 1].bidAmount;
      const getGoodReviewPercentage = (group) => {
        const { goodReviewsAmount, badReviewsAmount } =
          group.bids[group.bids.length - 1];
        const totalReviews = goodReviewsAmount + badReviewsAmount;
        return totalReviews > 0 ? (goodReviewsAmount / totalReviews) * 100 : 0;
      };

      if (key === "bidAmount") {
        // Sort by price (ascending)
        return getLastBidAmount(a) - getLastBidAmount(b);
      } else if (key === "carrierRating") {
        // Sort by good review percentage (descending)
        return getGoodReviewPercentage(b) - getGoodReviewPercentage(a);
      }

      return 0; // Default, no sorting
    });

    setBids(sortedBids);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.pageName}>Bids for order: #{order.orderName}</Text>
      <Box style={styles.selectContainer}>
        <Select
          minWidth="200"
          accessibilityLabel="State"
          placeholder="Sort By"
          mt="1"
          selectedValue={sorting}
          onValueChange={(itemValue) => {
            setSorting(itemValue);
            sortBy(itemValue);
          }}
          mb="5"
        >
          {sortList.map((item) => (
            <Select.Item
              key={item.value}
              label={item.name}
              value={item.value}
            />
          ))}
        </Select>
      </Box>
      {!bids?.length && !isLoading && <EmptyBids />}
      {!!bids?.length && (
        <FlatList
          refreshing={isLoading}
          data={bids}
          keyExtractor={(item, index) => `${item.carrierName}-${index}`}
          renderItem={({ item }) => <Bid group={item} />}
          onRefresh={getBids}
        />
      )}
      <AcceptAlert />
      {/* <DeclineAlert /> */}
      <CounterBidAlert />
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#1DCE80" />
        </View>
      </Modal>
    </View>
  );
}
