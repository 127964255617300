import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";
import instance from "../axios";
import { clearProfile } from "./profileSlice";

const handleRejectWithValue = (error, rejectWithValue) => {
  if (error.response) {
    // Return only serializable data
    return rejectWithValue({
      status: error.response.status,
      data: error.response.data,
    });
  }
  return rejectWithValue(error.message);
};

export const getProfile = createAsyncThunk(
  "profile/get",
  async ({}, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/auth/profile`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getOrder = createAsyncThunk(
  "order/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/api/v1/orders/${id}`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getOrderChanges = createAsyncThunk(
  "order/changes/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await instance.get(`${API_URL}/api/v1/orders/${id}/changes`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const updateProfilePhone = createAsyncThunk(
  "profile/update/phone",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile/phone`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const updateProfileEmail = createAsyncThunk(
  "profile/update/email",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile/email`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/update",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(`${API_URL}/auth/profile`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const profileAddFCM = createAsyncThunk(
  "profile/fcm",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/auth/profile/fcm`, data);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const deletePlace = createAsyncThunk(
  "user/delete-place",
  async (id, { rejectWithValue }) => {
    try {
      const res = await instance.delete(`${API_URL}/auth/profile/places/${id}`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const updatePlace = createAsyncThunk(
  "user/update-place",
  async (data, { rejectWithValue }) => {
    try {
      const res = await instance.put(
        `${API_URL}/auth/profile/places/${data.id}`,
        data
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const withdrawCredits = createAsyncThunk(
  "user/credits-withdraw",
  async (_, { rejectWithValue }) => {
    try {
      const res = await instance.post(`${API_URL}/api/v1/square/credits/withdraw`);
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const previewCreditsWithdrawal = createAsyncThunk(
  "user/credits-preview",
  async (_, { rejectWithValue }) => {
    try {
      const res = await instance.get(
        `${API_URL}/api/v1/square/credits/preview`
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const autoClearProfile = createAsyncThunk(
  "profile/autoClear",
  async (_, { dispatch }) => {
    setTimeout(() => {
      dispatch(clearProfile());
    }, 5 * 60 * 1000); // Clear profile after 5 minutes
  }
);
