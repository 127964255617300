import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={32} height={32} rx={8} fill="#000000" />
      <Path
        d="M16.002 16.953a2.08 2.08 0 100-4.16 2.08 2.08 0 000 4.16z"
        stroke="#fff"
        strokeWidth={1.5}
      />
      <Path
        d="M10.412 13.66c1.313-5.773 9.867-5.767 11.173.007.767 3.386-1.34 6.253-3.187 8.026a3.462 3.462 0 01-4.806 0c-1.84-1.773-3.947-4.646-3.18-8.033z"
        stroke="#fff"
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export default SvgComponent;
