import * as React from 'react';
import Svg, {Rect, Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Rect width={32} height={32} rx={8} fill="#000000" />
      <Path
        d="M22.65 20.22c0 .24-.054.487-.167.727a2.75 2.75 0 01-.454.68c-.326.36-.686.62-1.093.786-.4.167-.833.254-1.3.254-.68 0-1.407-.16-2.173-.487a11.702 11.702 0 01-2.294-1.32 19.167 19.167 0 01-2.186-1.867 18.942 18.942 0 01-1.86-2.18 11.88 11.88 0 01-1.307-2.273c-.32-.76-.48-1.487-.48-2.18 0-.453.08-.887.24-1.287.16-.406.413-.78.767-1.113.426-.42.893-.627 1.386-.627.187 0 .374.04.54.12.174.08.327.2.447.374l1.547 2.18c.12.166.206.32.266.466.06.14.094.28.094.407 0 .16-.047.32-.14.473a2.27 2.27 0 01-.374.474l-.506.526a.357.357 0 00-.107.267c0 .053.007.1.02.153.02.054.04.094.053.134.12.22.327.506.62.853.3.347.62.7.967 1.053.36.354.707.68 1.06.98.347.294.633.494.86.614.033.013.073.033.12.053a.46.46 0 00.167.027c.113 0 .2-.04.273-.114l.507-.5c.166-.166.326-.293.48-.373a.888.888 0 01.473-.14c.127 0 .26.027.407.087.146.06.3.146.466.26l2.207 1.566c.173.12.293.26.367.427.066.167.106.333.106.52z"
        stroke="#fff"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <Path
        d="M20.333 14c0-.4-.313-1.013-.78-1.513-.426-.46-.993-.82-1.553-.82M22.667 14A4.663 4.663 0 0018 9.333"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
