import * as React from "react";
import {
  Text,
  View,
  AlertDialog,
  Button,
  Input,
  useToast,
  Center,
  Modal,
  Pressable,
  VStack,
  Box,
  HStack,
  Spinner,
  FormControl,
  ScrollView,
} from "native-base";
import styles from "./styles";
import { Building, Logout, Person, Trash } from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { globalLogout } from "../../networking/globalActions";
import { useState, useEffect } from "react";
import instance from "../../networking/axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import {
  toastErrorConfig,
  toastSuccessConfig,
  toastWarningConfig,
} from "../../config/constants";
import { logOutAction } from "../../networking/auth/authSlice";
import { checkUser } from "../../networking/auth/authApi";
import PhoneInput from "react-phone-number-input/input";
import { verifyCredentialToEdit } from "../../networking/auth/authApi";
import { MdManageSearch } from "react-icons/md";
import { getPlaces } from "../../networking/orders/ordersApi";
import {
  deletePlace,
  updatePlace,
  withdrawCredits,
} from "../../networking/profile/profileApi";
import Autocomplete from "react-google-autocomplete";
import { Geo } from "../../assets/images/svg";
import { formatPhone } from "../../utils";
import { passkeyRegister } from "../../networking/auth/authApi";
import { setWebauthnSuccess } from "../../networking/auth/authSlice";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { updateProfileState } from "../../networking/profile/profileSlice";
import { previewCreditsWithdrawal } from "../../networking/profile/profileApi";

export default function MyProfileScreen() {
  const navigate = useNavigate();
  const editProfile = () => {
    navigate("/profile/edit");
  };
  const isCompany = false;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const {
    fullName,
    email,
    phone,
    address,
    isEmailVerified,
    isPhoneVerified,
    active,
    places,
    credits,
  } = useSelector((state) => state.profile);
  const cancelRef = React.useRef(null);
  const toast = useToast();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editType, setEditType] = useState(""); // "phone" or "email"
  const [newValue, setNewValue] = useState("");
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [dataType, setDataType] = useState(""); // "email" or "phone"
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [isPlacesModalOpen, setIsPlacesModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [currentPlace, setCurrentPlace] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPlaceModalOpen, setIsEditPlaceModalOpen] = useState(false);
  const [isWithdrawConfirmOpen, setIsWithdrawConfirmOpen] = useState(false);
  const [editPlaceData, setEditPlaceData] = useState({
    id: null,
    name: "",
    address: "",
    fullName: "",
    phone: "",
    email: "",
  });
  const [isDeleted, setIsDeleted] = React.useState(false);
  const webauthnSuccess = useSelector((state) => state.auth.webauthnSuccess);

  const fullAddress =
    address?.streetAddressLine1 &&
    address?.city &&
    address?.state &&
    address?.zip
      ? `${address.streetAddressLine1} ${address?.streetAddressLine2} ${address.city}, ${address.state} ${address.zip}`
      : "";

  useEffect(() => {
    if (!active) {
      toast.show({
        description: "Your account is inactive. Please contact support.",
        ...toastErrorConfig,
      });
    }
  }, []);

  const onClose = () => {
    if (isDeleted) {
      onDeleted();
    } else {
      setIsOpen(false);
    }
  };
  const onDelete = async () => {
    await instance
      .delete(`${API_URL}/auth/profile`)
      .then((res) => {
        setIsDeleted(true);
        toast.show({
          description: "Your account was successfully deleted.",
          ...toastSuccessConfig,
        });
      })
      .catch((err) => {
        console.log("err", err);
        toast.show({
          description: "Sorry, we couldn't delete your account.",
          ...toastErrorConfig,
        });
      });
  };

  const logOut = () => {
    dispatch(globalLogout());
    navigate("/");
  };

  const editValueModal = (type) => {
    setEditType(type);
    setNewValue(type === "email" ? email : phone);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async () => {
    if (
      (editType === "email" && newValue === email) ||
      (editType === "phone" && newValue === phone)
    ) {
      toast.show({
        description: `Please enter new ${editType}`,
        ...toastErrorConfig,
      });
      return;
    }

    const verificationData = {};
    let dataType = "";
    if (editType === "email") {
      const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
      if (!englishOnlyRegex.test(newValue)) {
        toast.show({
          description: "Email must contain English characters only",
          ...toastErrorConfig,
        });
        return;
      }
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.email = newValue;
      dataType = "email";
    }
    if (editType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;
      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.phone = newValue;
      dataType = "phone";
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setIsEditModalOpen(false);
        navigate(`/profile/confirm/update/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const handleVerifySubmit = () => {
    if (dataType === "email") {
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (dataType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;

      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }
    const verificationData =
      dataType === "email" ? { email: newValue } : { phone: newValue };

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setVerificationModalOpen(false);
        navigate(`/profile/confirm/verify/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const handleVerifyClick = () => {
    if (!isEmailVerified || !isPhoneVerified) {
      setDataType(!isEmailVerified ? "email" : "phone");
      setNewValue(!isEmailVerified ? email : phone);
      setVerificationModalOpen(true);
    }
  };

  const onDeleted = () => {
    dispatch(globalLogout());
    navigate("/");
  };

  const handleDelete = async () => {
    try {
      const response = await dispatch(checkUser({ email })).unwrap();
      if (response.deleted) {
        dispatch(globalLogout());
        toast.show({
          description: "Your profile is already deleted",
          ...toastErrorConfig,
        });
        return;
      }
      if (response.hasActiveOrders) {
        toast.show({
          description:
            "You can't delete profile, because you have orders that may need your attention",
          ...toastErrorConfig,
        });
        return;
      }
      if (response.hasPendingPayments) {
        toast.show({
          description:
            "You can't delete profile, because you have pending payments",
          ...toastErrorConfig,
        });
        return;
      }
      setIsOpen(!isOpen);
    } catch (error) {
      toast.show({
        description: "Couldn't check user",
        ...toastErrorConfig,
      });
    }
  };

  const handleOpenPlacesModal = () => {
    if (places.length > 0) {
      setIsPlacesModalOpen(true);
    } else {
      toast.show({
        description: "Your don't have saved places yet",
        ...toastWarningConfig,
      });
    }
  };

  const handleOpenDeleteConfirm = (place) => {
    setCurrentPlace(place);
    setIsPlacesModalOpen(false);
    setIsDeleteConfirmOpen(true);
  };

  const handleOpenEditPlaceConfirm = (place) => {
    setEditPlaceData({
      id: place.id,
      placeName: place.placeName,
      address: place.address,
      fullName: place.fullName,
      phone: place.phone,
      email: place.email,
    });
    setIsPlacesModalOpen(false);
    setIsEditPlaceModalOpen(true);
  };

  const handleDeletePlace = async () => {
    if (currentPlace) {
      try {
        await dispatch(deletePlace(currentPlace.id)).unwrap();
        setIsDeleteConfirmOpen(false);
        toast.show({
          description: "Place deleted successfully",
          ...toastSuccessConfig,
        });
      } catch (error) {
        console.log("error", error);
        toast.show({
          description: "Failed to delete place",
          ...toastErrorConfig,
        });
      }
    }
  };

  const handleEditPlace = async () => {
    if (
      !editPlaceData.placeName ||
      !editPlaceData.address ||
      !editPlaceData.fullName ||
      !editPlaceData.phone ||
      !editPlaceData.email
    ) {
      toast.show({
        description: "Please fill in all inputs to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !editPlaceData.email.includes("@") ||
      !editPlaceData.email.includes(".")
    ) {
      toast.show({
        description: "Email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidSenderPhone =
      editPlaceData.phone &&
      editPlaceData.phone.replace(/\D/g, "").length === 11;

    if (!isValidSenderPhone) {
      toast.show({
        description: "Please enter a valid 10-digit phone number",
        ...toastErrorConfig,
      });
      return;
    }

    try {
      await dispatch(updatePlace(editPlaceData)).unwrap();
      setIsEditPlaceModalOpen(false);
      toast.show({
        description: "Place updated successfully",
        ...toastSuccessConfig,
      });
      // Optionally refresh the places list here
    } catch (error) {
      toast.show({
        description: "Failed to update place",
        ...toastErrorConfig,
      });
    }
  };

  const ManagePlacesModal = () => {
    return (
      <>
        <Modal
          isOpen={isPlacesModalOpen}
          useRNModal={true}
          onClose={() => setIsPlacesModalOpen(false)}
        >
          <Modal.Content maxWidth="400px" maxHeight="60vh">
            <Modal.CloseButton />
            <Modal.Header>Saved Places</Modal.Header>
            <Modal.Body>
              <VStack space={4}>
                {places.map((place, index) => (
                  <Box
                    key={index}
                    p="5"
                    shadow={2}
                    borderWidth="1"
                    borderColor="coolGray.300"
                    borderRadius="md"
                  >
                    <Text bold>{place.placeName}</Text>
                    <Text>Address: {place.address}</Text>
                    <Text>Full Name: {place.fullName}</Text>
                    <Text>Phone: {place.phone}</Text>
                    <Text>Email: {place.email}</Text>
                    <HStack space={2} mt="2">
                      <Pressable
                        onPress={() => handleOpenEditPlaceConfirm(place)}
                      >
                        <Text style={styles.actionText}>Edit</Text>
                      </Pressable>
                      <Pressable onPress={() => handleOpenDeleteConfirm(place)}>
                        <Text style={styles.actionText}>Delete</Text>
                      </Pressable>
                    </HStack>
                  </Box>
                ))}
              </VStack>
            </Modal.Body>
            <Modal.Footer>
              <Button onPress={() => setIsPlacesModalOpen(false)}>Close</Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </>
    );
  };

  const onPasskeyRegister = async () => {
    try {
      await dispatch(passkeyRegister()).unwrap();
      toast.show({
        description: "Passkey registration successful",
        ...toastSuccessConfig,
      });
    } catch (error) {
      if (error === "Passkey is already registered for this device") {
        dispatch(setWebauthnSuccess(true));
        toast.show({
          description: error,
          ...toastErrorConfig,
        });
      }
      // {
      //   toast.show({
      //     description: error || "Passkey registration failed",
      //     ...toastErrorConfig,
      //   });
      // }
    }
  };

  const WithdrawCreditsDialog = () => {
    const [isFetching, setIsFetching] = useState(false);
    const [refundPreview, setRefundPreview] = useState(null);

    // Fetch refund preview when dialog opens
    useEffect(() => {
      if (isWithdrawConfirmOpen) {
        setIsFetching(true);
        dispatch(previewCreditsWithdrawal())
          .unwrap()
          .then((data) => {
            setRefundPreview(data);
          })
          .catch((error) => {
            toast.show({
              description: "Failed to load refund details.",
              ...toastErrorConfig,
            });
            setIsWithdrawConfirmOpen(false);
          })
          .finally(() => setIsFetching(false));
      }
    }, [isWithdrawConfirmOpen, dispatch]);

    const handleWithdrawCredits = () => {
      if (isLoading) return;
      setIsWithdrawConfirmOpen(false);
      setIsLoading(true);
      dispatch(withdrawCredits())
        .unwrap()
        .then(() => {
          dispatch(updateProfileState({ credits: 0 }));
          toast.show({
            description: "Credits withdrawn successfully",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => console.log("error", error))
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <AlertDialog
        isOpen={isWithdrawConfirmOpen}
        onClose={() => setIsWithdrawConfirmOpen(false)}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Confirm Withdrawal</AlertDialog.Header>

          <AlertDialog.Body>
            {isFetching ? (
              <Spinner color="#000000" size="lg" />
            ) : refundPreview ? (
              <ScrollView style={{ maxHeight: 300, padding: 10 }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 10 }}
                >
                  Total Refund: ${refundPreview.totalRefundAmount}
                </Text>

                {refundPreview.refundBreakdown.map((refund, index) => (
                  <View
                    key={index}
                    style={{
                      padding: 10,
                      marginBottom: 8,
                      backgroundColor: "#f5f5f5",
                      borderRadius: 6,
                    }}
                  >
                    <Text>
                      📌 Payment Method: {refund.paymentMethod || "Unknown"}
                    </Text>
                    <Text>
                      💵 Card:{" "}
                      {refund.cardBrand
                        ? `${refund.cardBrand} ****${refund.last4}`
                        : "N/A"}
                    </Text>
                    <Text>🔁 Refund Amount: ${refund.refundAmount}</Text>
                  </View>
                ))}

                <Text style={{ marginTop: 10, fontSize: 14 }}>
                  You will receive a refund to your original payment method(s).
                  Do you want to proceed?
                </Text>
              </ScrollView>
            ) : (
              <Text>No refund details available.</Text>
            )}
          </AlertDialog.Body>

          <AlertDialog.Footer>
            <View style={{ flexDirection: "row", flex: 2 }}>
              <View style={{ flex: 1, marginRight: 3 }}>
                <Button
                  variant="outline"
                  onPress={() => setIsWithdrawConfirmOpen(false)}
                >
                  Cancel
                </Button>
              </View>
              <View style={{ flex: 1, marginLeft: 3 }}>
                <Button onPress={handleWithdrawCredits}>Confirm</Button>
              </View>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  return (
    <View style={{ flex: 1, minHeight: "93vh" }}>
      <Center style={styles.center}>
        <View style={styles.container}>
          <View style={styles.subHeaderWrapper}>
            <Text style={styles.myProfileTitle}>My Profile</Text>
            {/* <Text style={styles.actionText} onPress={editProfile}>
              Edit
            </Text> */}
          </View>
          {!isCompany && (
            <View style={styles.infoContainer}>
              <View style={[styles.row, { marginBottom: 12 }]}>
                <Person style={styles.icon} />
                <Text style={styles.rowHeader}>Profile info</Text>
              </View>
              <Text style={styles.rowHeader}>Full Name</Text>
              <Text style={styles.rowText}>{fullName}</Text>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Email</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>{email}</Text>
                {!isEmailVerified ? (
                  <Pressable isDisabled={!active} onPress={handleVerifyClick}>
                    <Text style={styles.actionText}>Verify</Text>
                  </Pressable>
                ) : (
                  <Pressable
                    isDisabled={!active}
                    onPress={() => editValueModal("email")}
                  >
                    <Text style={styles.actionText}>Edit</Text>
                  </Pressable>
                )}
              </View>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Phone</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>+1 {formatPhone(phone)}</Text>
                {!isPhoneVerified ? (
                  <Pressable isDisabled={!active} onPress={handleVerifyClick}>
                    <Text style={styles.actionText}>Verify</Text>
                  </Pressable>
                ) : (
                  <Pressable
                    isDisabled={!active}
                    onPress={() => editValueModal("phone")}
                  >
                    <Text style={styles.actionText}>Edit</Text>
                  </Pressable>
                )}
              </View>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Address</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>{fullAddress}</Text>
                <Pressable isDisabled={!active} onPress={editProfile}>
                  <Text style={styles.actionText}>Edit</Text>
                </Pressable>
              </View>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Balance</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>
                  ${credits ? credits.toFixed(2) : "0.00"}
                </Text>
                <Pressable
                  isDisabled={!active || credits <= 0}
                  onPress={() => setIsWithdrawConfirmOpen(true)}
                >
                  <Text style={styles.actionText}>Withdraw</Text>
                </Pressable>
              </View>
            </View>
          )}
          <View>
            {browserSupportsWebAuthn() && !webauthnSuccess && (
              <View style={{ marginBottom: 20 }}>
                <FormControl.Label>Enhance security</FormControl.Label>
                <Button
                  onPress={!webauthnSuccess && onPasskeyRegister}
                  isDisabled={webauthnSuccess || !active}
                >
                  Register Passkey
                </Button>
              </View>
            )}
          </View>

          {isCompany && (
            <View style={styles.infoContainer}>
              <View mb={"10px"} style={styles.row}>
                <Building />
                <Text style={styles.header}>Company information</Text>
              </View>

              <Text style={styles.rowHeader}>Company Name</Text>
              <Text style={styles.rowText}>DispatchFull</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>License Number</Text>
              <Text style={styles.rowText}>1222 2323 1221</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Email</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Phone</Text>
              <Text style={styles.rowText}>+1 (000)00000000</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Address</Text>
              <Text style={styles.rowText}>
                One Apple Park Way Cupertino, CA 95014
              </Text>
              <View style={styles.divider} />
              <View mt={"16px"} mb={"10px"} style={styles.row}>
                <Person />
                <Text style={styles.header}>Contact person</Text>
              </View>

              <Text style={styles.rowHeader}>Full Name</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Position</Text>
              <Text style={styles.rowText}>CFO</Text>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Email</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Phone</Text>
              <Text style={styles.rowText}>+1 (000)00000000</Text>
            </View>
          )}
          {/* <Pressable onPress={handleOpenPlacesModal}>
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <MdManageSearch size={20} style={styles.icon} />
                <Text style={styles.rowHeader}>Manage saved places</Text>
              </View>
            </View>
          </Pressable> */}
          <Pressable onPress={handleDelete}>
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <Trash style={styles.icon} />
                <Text style={styles.rowHeader}>Delete account</Text>
              </View>
            </View>
          </Pressable>
          <Pressable onPress={logOut}>
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <Logout style={styles.icon} />
                <Text style={styles.rowHeader}>Log out</Text>
              </View>
            </View>
          </Pressable>
          <View style={styles.emptySpace} />
        </View>
      </Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Delete Account</AlertDialog.Header>
          <AlertDialog.Body>
            {isDeleted
              ? "Your account was successfully deleted"
              : "Are you sure you want to delete your account? This action is " +
                "irreversible and will result in the permanent deletion of all your " +
                "account information, including your posts, comments, and personal " +
                "settings. Once deleted, your profile cannot be recovered."}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            {isDeleted ? (
              <Button style={styles.deleteButton} onPress={onDeleted}>
                Ok
              </Button>
            ) : (
              <View style={styles.buttonsRow}>
                <Button
                  style={styles.cancel}
                  variant="cancel"
                  onPress={onClose}
                  ref={cancelRef}
                >
                  Cancel
                </Button>
                <Button
                  style={styles.deleteButton}
                  variant="fatal"
                  onPress={onDelete}
                >
                  Delete
                </Button>
              </View>
            )}
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <Modal
        isOpen={isEditModalOpen}
        useRNModal={true}
        onClose={() => setIsEditModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Edit {editType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>Please enter new value</Text>
            {editType === "email" ? (
              <Input
                placeholder={`Enter new ${editType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType="email-address"
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1A1A1A" : "#A0A0A0",
                    backgroundColor: isPhoneFocused ? "#F5F5F5" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    // outline: isPhoneFocused ? "1px solid #1A1A1A" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="tel"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                    fontSize: 16,
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleEditSubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="outline"
              onPress={() => setIsEditModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={verificationModalOpen}
        useRNModal={true}
        onClose={() => setVerificationModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Verify your {dataType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>
              Please confirm or edit {dataType} to proceed.
            </Text>
            {editType === "email" ? (
              <Input
                placeholder={`Please verify ${dataType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType="email-address"
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1A1A1A" : "#A0A0A0",
                    backgroundColor: isPhoneFocused ? "#F5F5F5" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    // outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="tel"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                    fontSize: 16,
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleVerifySubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setVerificationModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <ManagePlacesModal />
      <AlertDialog
        isOpen={isDeleteConfirmOpen}
        useRNModal={true}
        onClose={() => setIsDeleteConfirmOpen(false)}
      >
        <AlertDialog.Content maxWidth="400px">
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{`Delete ${currentPlace?.placeName}`}</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to delete this place? This action cannot be
            undone.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={() => {
                  setIsDeleteConfirmOpen(false);
                  setIsPlacesModalOpen(true);
                }}
              >
                Cancel
              </Button>
              <Button
                style={styles.deleteButton}
                variant="fatal"
                onPress={handleDeletePlace}
              >
                Delete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <Modal
        isOpen={isEditPlaceModalOpen}
        onClose={() => setIsEditPlaceModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>{`Edit ${editPlaceData?.placeName}`}</Modal.Header>
          <Modal.Body>
            <VStack>
              <FormControl.Label>Place Name</FormControl.Label>
              <Input
                value={editPlaceData.placeName}
                onChangeText={(text) =>
                  setEditPlaceData((prevState) => ({
                    ...prevState,
                    placeName: text,
                  }))
                }
                placeholder="Name"
              />
              <View style={styles.divider} />

              <FormControl.Label>Address</FormControl.Label>
              <View style={styles.addressContainer}>
                <>
                  {/* <Geo /> */}
                  <style>
                    {`
                    .pac-container {
                        z-index: 10000 !important;
                    }
                `}
                  </style>
                  <Autocomplete
                    style={{
                      width: "100%",
                      border: 0,
                      paddingLeft: 14,
                      outline: "none",
                      marginBottom: 8,
                      fontSize: "16px",
                      backgroundColor: "transparent",
                    }}
                    types={["address"]}
                    fields={["address_components"]}
                    placeholder={editPlaceData.address || "Search"}
                    onPlaceSelected={(details) => {
                      if (details?.geometry?.location) {
                        setEditPlaceData((prevState) => ({
                          ...prevState,
                          address: details?.formatted_address,
                        }));
                      }
                    }}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "us" },
                    }}
                  />
                </>
              </View>
              <View style={styles.divider} />

              <FormControl.Label>Name</FormControl.Label>
              <Input
                value={editPlaceData.fullName}
                onChangeText={(text) =>
                  setEditPlaceData((prevState) => ({
                    ...prevState,
                    fullName: text,
                  }))
                }
                placeholder="Full Name"
              />
              <View style={styles.divider} />

              <FormControl.Label>Phone Number</FormControl.Label>
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1A1A1A" : "#A0A0A0",
                    backgroundColor: isPhoneFocused ? "#F5F5F5" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    // outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={editPlaceData.phone}
                  onChange={(data) => {
                    setEditPlaceData({ ...editPlaceData, phone: data });
                  }}
                  type="tel"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                    fontSize: 16,
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
              <View style={styles.divider} />
              <FormControl.Label>Email</FormControl.Label>
              <Input
                value={editPlaceData.email}
                onChangeText={(text) =>
                  setEditPlaceData((prevState) => ({
                    ...prevState,
                    email: text,
                  }))
                }
                placeholder="Email"
                keyboardType="email-address"
              />
            </VStack>
          </Modal.Body>
          <Modal.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={() => {
                  setIsEditPlaceModalOpen(false);
                  setIsPlacesModalOpen(true);
                }}
              >
                Cancel
              </Button>
              <Button style={styles.deleteButton} onPress={handleEditPlace}>
                Confirm
              </Button>
            </View>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <WithdrawCreditsDialog />
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#ffffff" />
        </View>
      </Modal>
    </View>
  );
}
