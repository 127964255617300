import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function SvgComponent(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M5.583 15H3.458c-1.192 0-1.792-.6-1.792-1.792v-9.75c0-1.191.6-1.791 1.792-1.791H7.04c1.192 0 1.792.6 1.792 1.791V5"
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M14.476 7.017v9.3c0 1.341-.667 2.016-2.009 2.016H7.601c-1.342 0-2.017-.675-2.017-2.016v-9.3C5.584 5.675 6.259 5 7.601 5h4.866c1.342 0 2.009.675 2.009 2.017z"
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.166 5V3.458c0-1.191.6-1.791 1.792-1.791h3.583c1.192 0 1.792.6 1.792 1.791v9.75c0 1.192-.6 1.792-1.792 1.792h-2.067M8.334 9.167h3.333M8.334 11.667h3.333M10 18.333v-2.5"
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
