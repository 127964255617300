import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useParams, useNavigate } from "react-router-dom";
import {
  useToast,
  View,
  Box,
  Button,
  Image,
  Text,
  VStack,
  HStack,
  Menu,
  useBreakpointValue,
  Divider,
  Modal,
  Spinner,
  Center,
} from "native-base";
import { toastErrorConfig } from "../../config/constants";
import OtpInput from "react-otp-input";
import styles from "./styles";
import { useDispatch } from "react-redux";
import { getDriver } from "../../networking/orders/ordersApi";
import { formatPhone } from "../../utils";
import { getOrder } from "../../networking/profile/profileApi";

export default function VerifyLink() {
  const [code, setCode] = useState("");
  const { linkId, orderId } = useParams();
  const toast = useToast();
  const [isVerified, setIsVerified] = useState(false);
  const [PIN, setPIN] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [phone, setPhone] = useState("");
  const [isTrailerEnclosed, setIsTrailerEnclosed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [carrierName, setCarrierName] = useState("");
  const [carrierPhone, setCarrierPhone] = useState("");
  const [pickUpPINRequired, setPickUpPINRequired] = useState(false);
  const [deliveryPINRequired, setDeliveryPINRequired] = useState(false);
  const [status, setStatus] = useState("");

  const boxStyles = useBreakpointValue({
    base: {}, // Default styles for smaller screens
    md: {
      bg: "white",
      borderWidth: 1,
      borderRadius: 12,
      shadow: 2,
      borderColor: "gray.300",
      marginTop: 40,
    }, // Styles for medium and larger screens
  });

  useEffect(() => {
    fetchInfo();
  }, [orderId]);

  const fetchInfo = async () => {
    try {
      if (orderId) {
        const order = await dispatch(getOrder({ id: orderId })).unwrap();
        setStatus(order.status);
        setCarrierName(order.carrierName);
        setCarrierPhone(order.carrierPhone);
        setPickUpPINRequired(order.pickUpPINRequired);
        setDeliveryPINRequired(order.deliveryPINRequired);
        if (order.status === "waitingPickUp") {
          setPIN(order.pickupSenderPIN);
        } else if (order.status === "pickedUp") {
          setPIN(order.deliveryReceiverPIN);
        }
        const driver = await dispatch(getDriver({ id: orderId })).unwrap();

        if (!driver) return;
        setFullName(driver.fullName);
        setProfileImage(driver.profileImage);
        setPhone(driver.phone);
        setIsTrailerEnclosed(driver.isTrailerEnclosed);
      } else if (linkId) {
        const data = await axios.get(
          `${API_URL}/api/v1/link/${linkId}/get-order`
        );
        const order = data.data;
        console.log("order", order);
        setStatus(order.status);
        setCarrierName(order.carrierName);
        setCarrierPhone(order.carrierPhone);
        setPickUpPINRequired(order.pickUpPINRequired);
        setDeliveryPINRequired(order.deliveryPINRequired);
        if (order.status === "waitingPickUp") {
          setPIN(order.pickupSenderPIN);
        } else if (order.status === "pickedUp") {
          setPIN(order.deliveryReceiverPIN);
        }
        const driver = await dispatch(getDriver({ id: order.id })).unwrap();
        if (!driver) return;
        setFullName(driver.fullName);
        setProfileImage(driver.profileImage);
        setPhone(driver.phone);
        setIsTrailerEnclosed(driver.isTrailerEnclosed);
      }
    } catch (error) {
      console.log("Error fetching driver", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (orderId) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${API_URL}/api/v1/orders/${orderId}/verify-driver`,
          {
            code,
          }
        );
        if (response.data) {
          setIsVerified(true);
        } else {
          toast.show({
            description: "Invalid code provided",
            ...toastErrorConfig,
          });
        }
      } catch (error) {
        console.log("error", error);
        if (error.status === 409 || error.status === 404) {
          toast.show({
            description: "Link is not valid",
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description: "Something went wrong",
            ...toastErrorConfig,
          });
        }
      } finally {
        setCode("");
        setIsLoading(false);
      }
    } else if (linkId) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${API_URL}/api/v1/link/${linkId}/verify-driver`,
          {
            code,
          }
        );
        if (response.data) {
          setIsVerified(true);
        } else {
          toast.show({
            description: "Invalid code provided",
            ...toastErrorConfig,
          });
        }
      } catch (error) {
        console.log("error", error);
        if (error.status === 409 || error.status === 404) {
          toast.show({
            description: "Link is not valid",
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description: "Something went wrong",
            ...toastErrorConfig,
          });
        }
      } finally {
        setCode("");
        setIsLoading(false);
      }
    }
  };

  const returnToOrder = () => {
    navigate(`/order/${orderId}`);
  };

  useEffect(() => {
    if (code.length === 4) {
      handleSubmit();
    }
  }, [code]);

  if (!PIN && !isLoading) {
    return (
      <Center
        flex={1}
        justifyContent="center"
        alignItems="center"
        bg="white"
        mt={40}
      >
        <Box
          borderWidth={2}
          borderColor="red.500"
          px={6}
          py={4}
          rounded="lg"
          shadow={2}
        >
          <Text fontSize="2xl" fontWeight="bold" color="red.500"></Text>
          <Text fontSize="lg" color="gray.600">
            Sorry, this link is not active
          </Text>
        </Box>
      </Center>
    );
  }

  return (
    <>
      <Box px={6} maxW="600px" mx="auto" display="flex" flexDirection="column">
        {/* Driver Info Section */}
        {fullName && phone && profileImage && (
          <Box>
            <Text fontSize="md" mt={4}>
              Please verify your driver before proceeding. Contact carrier or
              support if needed.
            </Text>
            <HStack space={4} alignItems="center">
              <Image
                source={{ uri: profileImage }}
                alt="Driver Image"
                style={styles.profileImage}
              />
              <VStack alignItems="flex-start">
                <Text fontSize="sm" color="gray.600">
                  {carrierName}
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  {fullName}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  +1 {formatPhone(phone)}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {isTrailerEnclosed ? "Trailer: Enclosed" : "Trailer: Open"}
                </Text>
              </VStack>
            </HStack>
            <View my={6}>
              <Menu
                placement={"bottom"}
                w="300"
                trigger={(triggerProps) => {
                  return (
                    <Button variant="outline" {...triggerProps}>
                      Call
                    </Button>
                  );
                }}
              >
                <Menu.Item onPress={() => window.open(`tel:${phone}`)}>
                  Call Driver
                </Menu.Item>
                <Menu.Item onPress={() => window.open(`tel:${carrierPhone}`)}>
                  Call Carrier
                </Menu.Item>
              </Menu>
            </View>
          </Box>
        )}
        {isVerified ? (
          <VStack mt={4} space={2} alignItems="center">
            <Text fontSize="2xl" fontWeight="bold">
              Verification Successful
            </Text>
            <Text fontSize="md">
              Your driver has been verified. You may leave this page.
            </Text>
            <Text
              style={{
                fontSize: 36,
                letterSpacing: 4,
                lineHeight: 40,
              }}
            >
              {PIN}
            </Text>
            <Text fontSize="sm" mb={4}>
              Provide this PIN to the driver, if you didn't do that yet.
            </Text>
            {orderId && (
              <Button onPress={returnToOrder}>Return to order</Button>
            )}
          </VStack>
        ) : (
          <VStack mt={4} space={2}>
            <Text fontSize="lg" fontWeight="bold">
              Verification Steps:
            </Text>
            <Text fontSize="md">1) Please provide this PIN to the driver:</Text>
            <View style={{ alignItems: "center" }}>
              <Text
                style={{
                  fontSize: 36,
                  letterSpacing: 4,
                  lineHeight: 40,
                }}
              >
                {PIN}
              </Text>
            </View>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text fontSize="md">
              2) Please enter the code received from the driver:
            </Text>
            <OtpInput
              autofocus
              value={code}
              onChange={setCode}
              numInputs={4}
              inputType="tel"
              containerStyle={{ justifyContent: "center" }}
              inputStyle={styles.inputOpt}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input
                  {...props}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(); // Trigger verification on Enter
                    }
                  }}
                />
              )}
            />
            {/* <Button my={6} onPress={() => handleSubmit(code)}>
                Verify
              </Button> */}
            <View style={{ height: 10 }} />
          </VStack>
        )}
        {/* <Box mt="auto" mb={6}>
            <Button variant="outline">Call Support</Button>
          </Box> */}
      </Box>
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#ffffff" />
        </View>
      </Modal>
    </>
  );
}
