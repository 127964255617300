import * as React from "react";
import { Text, View, VStack, Heading, Divider, Link } from "native-base";
import styles from "./styles";

export default function HelpScreen() {
  return (
    <View flex={1} p={4} bg="white">
      <VStack space={4}>
        <Heading size="lg" color="primary.600">
          Help & Support
        </Heading>

        <Divider my={2} />

        {/* Company Information */}
        <Text fontSize="md" bold>
          Company Name: Skip Your Broker
        </Text>

        <Text fontSize="md">
          We’re here to help! Reach out to us if you have any questions.
        </Text>

        {/* Contact Information */}
        <VStack space={2} mt={4}>
          <Text fontSize="md" bold>
            Email Support:
          </Text>
          <Link
            href="mailto:support@myapp.com"
            isUnderlined
            _text={{ color: "blue.600" }}
          >
            support@myapp.com
          </Link>

          <Text fontSize="md" bold>
            Phone Support:
          </Text>
          <Link
            href="tel:+1234567890"
            isUnderlined
            _text={{ color: "blue.600" }}
          >
            +1 (234) 567-890
          </Link>
        </VStack>

        {/* FAQ Section */}
        <VStack space={3} mt={6}>
          <Heading size="md">Frequently Asked Questions</Heading>

          <Text fontSize="md" bold>
            1. How can I contact support?
          </Text>
          <Text fontSize="sm">
            You can email us at support@myapp.com or call us at +1 (234)
            567-890.
          </Text>

          <Text fontSize="md" bold>
            2. Where can I find the latest updates?
          </Text>
          <Text fontSize="sm">
            Check out our website or follow us on social media.
          </Text>
        </VStack>
      </VStack>
    </View>
  );
}
