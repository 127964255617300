import * as React from "react";
import Svg, { Circle, Line } from "react-native-svg";

function SearchSvg(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <Circle cx="11" cy="11" r="8"></Circle>
      <Line x1="21" y1="21" x2="16.65" y2="16.65"></Line>
    </Svg>
  );
}

export default SearchSvg;