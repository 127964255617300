const styles = {
  status: {
    borderRadius: 8,
    paddingLeft: 8,
    paddingRight: 4,
    color: "white",
    fontSize: 16,
  },
  statusRow: {
    flexDirection: "row",
  },
};

export default styles;
