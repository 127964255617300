import * as React from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  useToast,
  AlertDialog,
  Pressable,
  ScrollView,
  Popover,
  SearchIcon,
  Divider,
  TextArea,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { carsObj } from "../../../assets/data/carsList";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import PhoneInput from "react-phone-number-input/input";
import {
  toastErrorConfig,
  toastSuccessConfig,
  toastWarningConfig,
} from "../../../config/constants";
import Stepper from "../../../components/stepper";
import Autocomplete from "react-google-autocomplete";
import { Geo } from "../../../assets/images/svg";
import { createPlace } from "../../../networking/orders/ordersApi";
import { IoAlertCircleOutline } from "react-icons/io5";
import { parseLocation, formatPhone } from "../../../utils";
import { deletePlace } from "../../../networking/profile/profileApi";
import { removePlaceById } from "../../../networking/profile/profileSlice";

export default function ThirdStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isRecipientPhoneFocused, setIsRecipientPhoneFocused] = useState(false);
  const toast = useToast();
  const [deliveryLocationCoordinates, setDeliveryLocationCoordinates] =
    useState({});
  const [shownCancelPINModal, setShownCancelPINModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(""); // Track whether it's for pickup or delivery
  const orderState = useSelector((state) => state.order);
  const { fullName, email, phone, active, orders, places } = useSelector(
    (state) => state.profile
  );
  const profileOrder = orders.find((order) => order.id === id) || null;
  const { deliveryLocation, deliveryPINRequired } = orderState;
  const [localInputs, setLocalInputs] = useState({
    deliveryLocation: orderState.deliveryLocation || "",
    recipientContactName: orderState.recipientContactName || "",
    recipientContactPhone: orderState.recipientContactPhone || "",
    recipientContactEmail: orderState.recipientContactEmail || "",
    checkboxRecipient: orderState.checkboxRecipient || false,
    placeName1: orderState.placeName1 || "",
    pickUpDate1: orderState.pickUpDate1 || null,
    deliveryDate1: orderState.deliveryDate1 || null,
    flexibleDeliveryDate: orderState.flexibleDeliveryDate || false,
    hasDeliveryNotes: orderState.hasDeliveryNotes || false,
    deliveryNotes: orderState.deliveryNotes || "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [wasDeliveryChecked, setWasDeliveryChecked] =
    useState(deliveryPINRequired);
  const [isEdited, setIsEdited] = useState(false);
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const deliveryPlaces = places.filter((place) => place.type === "delivery");
  const autocompleteRef = useRef(null);
  const [validationErrors, setValidationErrors] = useState({});

  const handleOpenSavePlaceModal = () => {
    if (!localInputs.deliveryLocation) {
      toast.show({
        description: "Please fill in  delivery location",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactName ||
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      localInputs.recipientContactPhone &&
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (profileOrder?.status === "pickedUp") {
      Object.entries(profileOrder).forEach(([key, value]) => {
        if (
          key === "orderName" ||
          key === "vehicles" ||
          key === "pickUpLocation" ||
          key === "senderContactName" ||
          key === "senderContactPhone" ||
          key === "senderContactEmail" ||
          key === "pickUpDate1" ||
          key === "flexiblePickupDate" ||
          key === "pickupNotes"
        ) {
          dispatch(setInputValue({ key, value }));
        }
      });
    }
  }, []);

  const handleCloseSavePlaceModal = () => {
    setIsModalVisible(false);
    setIsDropdownVisible(false);
  };

  const addDays = (date, days) => {
    if (!date || isNaN(new Date(date).getTime())) {
      console.error("Invalid date provided:", date);
      return ""; // Return a default value or handle it as needed
    }
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
  };

  const handleConfirmSavePlaceModal = async () => {
    setIsDropdownVisible(false);
    const placeData = {
      placeName: localInputs.placeName1,
      fullName: localInputs.recipientContactName,
      phone: localInputs.recipientContactPhone,
      email: localInputs.recipientContactEmail,
      address: localInputs.deliveryLocation,
      type: "delivery",
    };
    try {
      await dispatch(createPlace(placeData)).unwrap();
      toast.show({
        description: "Place saved successfully!",
        ...toastSuccessConfig,
      });
      setIsEdited(false);
      handleCloseSavePlaceModal();
    } catch (error) {
      console.error("Failed to save place:", error);
      toast.show({
        description: "Failed to save place. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleChangePlaceName = (value) => {
    setLocalInputs((prev) => ({
      ...prev,
      placeName1: value,
    }));

    if (value.trim().length > 0) {
      const filtered = deliveryPlaces.filter(
        (place) =>
          place.placeName.toLowerCase().includes(value.toLowerCase()) ||
          place.address.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredPlaces(filtered);
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setLocalInputs((prev) => ({
      ...prev, // This spread operator retains the previous state properties
      [key]: value,
    }));
    setIsEdited(true);
  };

  const handleCloseModal = () => {
    setShownCancelPINModal(false);
  };

  const handleCancelPIN = () => {
    // Uncheck the box when user confirms PIN cancellation
    if (modalLocation === "pickup") {
      dispatch(setInputValue({ key: "pickUpPINRequired", value: false }));
      setWasPickupChecked(false);
    } else if (modalLocation === "delivery") {
      dispatch(setInputValue({ key: "deliveryPINRequired", value: false }));
      setWasDeliveryChecked(false);
    }
    setShownCancelPINModal(false);
  };

  const getDateDifference = (date1, date2) => {
    const diffTime = new Date(date2) - new Date(date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const validateSingleField = (fieldKey, fieldValue) => {
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // console.log("fieldKey", fieldKey, "fieldValue", fieldValue);
      const parsedDeliveryLocation = parseLocation(fieldValue);

      switch (fieldKey) {
        case "deliveryLocation":
          if (!fieldValue) {
            updatedErrors.deliveryLocation = true;
            toast.show({
              description: "Please enter a valid delivery location",
              ...toastErrorConfig,
            });
          } else if (
            !parsedDeliveryLocation.street ||
            !parsedDeliveryLocation.city ||
            !parsedDeliveryLocation.state ||
            !parsedDeliveryLocation.zipCode
          ) {
            updatedErrors.deliveryLocation = true;
            toast.show({
              description: "Please fill in  full address",
              ...toastErrorConfig,
            });
          } else {
            delete updatedErrors.deliveryLocation;
          }
          break;

        case "recipientContactName":
          if (!fieldValue.trim()) {
            updatedErrors.recipientContactName = true;
            toast.show({
              description: "Please enter recipient's full name",
              ...toastErrorConfig,
            });
          } else {
            delete updatedErrors.recipientContactName;
          }
          break;

        case "recipientContactEmail":
          if (
            !fieldValue ||
            !fieldValue.includes("@") ||
            !fieldValue.includes(".")
          ) {
            updatedErrors.recipientContactEmail = true;
            toast.show({
              description: "Recipient email is invalid",
              ...toastErrorConfig,
            });
          } else {
            delete updatedErrors.recipientContactEmail;
          }
          break;

        case "recipientContactPhone":
          const isValidPhone = fieldValue.replace(/\D/g, "").length === 10;
          console.log("isValidPhone", isValidPhone);
          if (!isValidPhone) {
            updatedErrors.recipientContactPhone = true;
            toast.show({
              description:
                "Please enter a valid 10-digit recipient's phone number",
              ...toastErrorConfig,
            });
          } else {
            delete updatedErrors.recipientContactPhone;
          }
          break;

        case "deliveryDate1":
          if (!fieldValue) {
            updatedErrors.deliveryDate1 = true;
            toast.show({
              description: "Please enter a delivery date",
              ...toastErrorConfig,
            });
          } else if (fieldValue < today) {
            updatedErrors.deliveryDate1 = true;
            toast.show({
              description: "Delivery date cannot be in the past",
              ...toastErrorConfig,
            });
          } else {
            delete updatedErrors.deliveryDate1;
          }
          break;

        default:
          break;
      }

      return updatedErrors;
    });
  };

  const validateAllFields = () => {
    let allValid = true;
    let newValidationErrors = {};

    if (!localInputs.deliveryLocation) {
      newValidationErrors.deliveryLocation = true;
      allValid = false;
    }

    const parsedDeliveryLocation = parseLocation(
      localInputs.deliveryLocation
    );

    if (
      !parsedDeliveryLocation.street ||
      !parsedDeliveryLocation.city ||
      !parsedDeliveryLocation.state ||
      !parsedDeliveryLocation.zipCode
    ) {
      toast.show({
        description: "Please fill in  full address",
        ...toastErrorConfig,
      });
      // return;
    }

    if (!localInputs.recipientContactName.trim()) {
      newValidationErrors.recipientContactName = true;
      allValid = false;
    }

    if (
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      newValidationErrors.recipientContactEmail = true;
      allValid = false;
    }

    const isValidPhone =
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;
    if (!isValidPhone) {
      newValidationErrors.recipientContactPhone = true;
      allValid = false;
    }

    if (!localInputs.deliveryDate1) {
      newValidationErrors.deliveryDate1 = true;
      allValid = false;
    } else if (localInputs.deliveryDate1 < today) {
      newValidationErrors.deliveryDate1 = true;
      allValid = false;
    }

    if (Object.keys(newValidationErrors).length > 0) {
      toast.show({
        description: "Please fill in all required fields",
        ...toastErrorConfig,
      });
    }

    setValidationErrors(newValidationErrors);
    return allValid;
  };

  const nextStep = () => {
    if (!validateAllFields()) return;

    // Dispatch localInputs to Redux
    Object.entries(localInputs).forEach(([key, value]) => {
      dispatch(setInputValue({ key, value }));
    });

    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/preview/${id}`
        : id
        ? `/edit/preview/${id}`
        : "/create/preview"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const prevStep = () => {
    if (profileOrder && profileOrder.status === "pickedUp") {
      navigate(`/order/${id}`);
    } else {
      navigate(
        location.pathname.includes("request-edit")
          ? `/order/${id}`
          : id
          ? `/edit/2/${id}`
          : "/create/2"
      );
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckboxClick = (location) => {
    if (location === "delivery" && wasDeliveryChecked) {
      setModalLocation("delivery");
      setShownCancelPINModal(true);
    } else {
      // Directly toggle without modal
      if (location === "delivery") {
        dispatch(
          setInputValue({
            key: "deliveryPINRequired",
            value: !deliveryPINRequired,
          })
        );
        setWasDeliveryChecked(!deliveryPINRequired);
      }
    }
  };

  const CancelPINAlert = () => {
    return (
      <AlertDialog
        isOpen={shownCancelPINModal}
        onClose={handleCloseModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            <Text>
              Cancel {modalLocation === "pickup" ? "pickup" : "delivery"} PIN
            </Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              By choosing to cancel the PIN code, you acknowledge and accept
              full responsibility for ensuring the
              {modalLocation === "pickup" ? " pickup" : " delivery"}
            </Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="outline"
                onPress={handleCloseModal}
              >
                Go back
              </Button>
              <Button
                style={styles.dcButton}
                onPress={handleCancelPIN}
              >
                Cancel PIN
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleSelectPlace = (place) => {
    setLocalInputs((prevState) => ({
      ...prevState,
      placeName1: place.placeName,
      deliveryLocation: place.address,
      recipientContactName: place.fullName,
      recipientContactPhone: place.phone,
      recipientContactEmail: place.email,
      checkboxRecipient: false,
    }));
    setIsDropdownVisible(false);
    setIsEdited(false);
  };

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     if (localInputs.placeName1.trim()) {
  //       const filtered = deliveryPlaces.filter(
  //         (place) =>
  //           (place.placeName
  //             .toLowerCase()
  //             .includes(localInputs.placeName1.toLowerCase()) ||
  //             place.address
  //               .toLowerCase()
  //               .includes(localInputs.placeName1.toLowerCase())) &&
  //           place.type === "delivery"
  //       );

  //       setFilteredPlaces(filtered);
  //     }
  //   }, 300); // Debounce for 300 milliseconds

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [localInputs.placeName1, deliveryPlaces]);

  useEffect(() => {
    if (localInputs.placeName1 === "" && isDropdownVisible) {
      setFilteredPlaces(deliveryPlaces);
    }
  }, [localInputs.placeName1, isDropdownVisible]);

  const handleDeletePlace = async (place) => {
    try {
      await dispatch(deletePlace(place.id)).unwrap();
      dispatch(removePlaceById(place.id));
    } catch (error) {
      console.log("error", error);
      toast.show({
        description: "Failed to delete place",
        ...toastErrorConfig,
      });
    }
  };

  useEffect(() => {
    // When pickUpLocation updates, set it in the Autocomplete input
    if (autocompleteRef.current) {
      autocompleteRef.current.value = localInputs.deliveryLocation;
    }
  }, [localInputs.deliveryLocation]);

  useEffect(() => {
    if (
      !orderState.orderName ||
      !orderState.vehicles.length ||
      Object.keys(orderState).length === 0
    ) {
      navigate("/orders", { replace: true }); // Redirect user to orders page
    }
  }, [orderState, navigate]);

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={3} />
        </View>
        <View>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 24,
              marginVertical: 10,
            }}
          >
            Delivery information
          </Text>
          {deliveryPlaces && deliveryPlaces.length > 0 && (
            <View>
              <FormControl.Label>
                Prefill with saved place (optional)
              </FormControl.Label>
              <View
                style={{
                  backgroundColor: "#F5F5F5", // Light gray background
                  borderRadius: 12,
                }}
              >
                <Input
                  placeholder="Type to find saved place..."
                  value={localInputs.placeName1}
                  onChangeText={handleChangePlaceName}
                  onFocus={() => {
                    setIsDropdownVisible(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => {
                      setIsDropdownVisible(false);
                    }, 100)
                  } // Delays hiding dropdown to allow selection
                  InputLeftElement={
                    <View style={styles.leftIcon}>
                      <SearchIcon />
                    </View>
                  }
                />
              </View>
            </View>
          )}
          {/* {isDropdownVisible && (
            <View
              style={styles.dropdownContainer}
              maxHeight={screenHeight * 0.5}
            >
              <ScrollView>
                {filteredPlaces.length > 0 ? (
                  filteredPlaces.map((place) => (
                    <Pressable
                      key={place.id}
                      onPress={() => handleSelectPlace(place)}
                    >
                      <View style={styles.dropdownItem}>
                        <View>
                          <Text style={{ flex: 1, fontWeight: "bold" }}>
                            {place.placeName}
                          </Text>
                          <Text style={{ flex: 1 }}>{place.address}</Text>
                        </View>
                        <Text
                          onPress={() => handleDeletePlace(place)}
                          style={{ color: "blue", alignContent: "center" }}
                        >
                          Delete
                        </Text>
                      </View>
                    </Pressable>
                  ))
                ) : (
                  <View style={styles.dropdownItem}>
                    <Text>No saved places found</Text>
                  </View>
                )}
              </ScrollView>
              <View style={styles.divider} />
            </View>
          )} */}

          {isDropdownVisible && (
            <View style={styles.dropdownContainer}>
              <ScrollView>
                {filteredPlaces.length > 0 ? (
                  filteredPlaces.map((place) => (
                    <Pressable
                      key={place.id}
                      onPress={() => handleSelectPlace(place)}
                    >
                      <View style={styles.dropdownItem}>
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontWeight: "bold" }}>
                            {place.placeName}
                          </Text>
                          <Pressable onPress={() => handleDeletePlace(place)}>
                            <Text style={{ color: "blue" }}>Delete</Text>
                          </Pressable>
                        </View>
                        <Text>{place.address}</Text>
                      </View>
                    </Pressable>
                  ))
                ) : (
                  // Show this if there are no places found
                  <View>
                    <Text style={styles.dropdownItem}>
                      No saved places found
                    </Text>
                  </View>
                )}
              </ScrollView>
            </View>
          )}
          <FormControl.Label>Delivery Location</FormControl.Label>
          <View
            style={[
              styles.dateButtonContainer,
              {
                borderColor: isAddressFocused
                  ? "#1A1A1A"
                  : validationErrors?.deliveryLocation
                  ? "#FF0000"
                  : "#A0A0A0",
                backgroundColor: isAddressFocused ? "#F5F5F5" : "ffffff",
              },
            ]}
          >
            <Geo />
            <Autocomplete
              ref={autocompleteRef}
              style={{
                width: "100%",
                border: 0,
                paddingLeft: 12,
                outline: "none",
                fontSize: "16px",
                backgroundColor: isAddressFocused ? "#F5F5F5" : "#FFFFFF",
              }}
              types={["address"]}
              fields={["address_components", "geometry.location"]}
              placeholder={localInputs.deliveryLocation || "Search"}
              onPlaceSelected={(details) => {
                if (details?.geometry?.location) {
                  const streetNumber = details.address_components.find(
                    (component) => component.types.includes("street_number")
                  )?.long_name;
                  if (!streetNumber) return;
                  const location = JSON.parse(
                    JSON.stringify(details?.geometry?.location)
                  );
                  location.title = "Delivery Location";
                  setDeliveryLocationCoordinates(location);
                  handleChange("deliveryLocation", details?.formatted_address);
                  validateSingleField(
                    "deliveryLocation",
                    details?.formatted_address
                  );
                }
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
              onFocus={() => setIsAddressFocused(true)}
              onBlur={() => setIsAddressFocused(false)}
            />
          </View>
          <View style={styles.row}>
            <Checkbox
              value="checkbox"
              isChecked={!deliveryPINRequired}
              onChange={() => handleCheckboxClick("delivery")}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer}>
              PIN not required at delivery
            </Text>
          </View>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Recipient information
          </Text>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.checkboxRecipient}
                // defaultIsChecked={localInputs.checkboxRecipient}
                onChange={(state) => {
                  handleChange("checkboxRecipient", state);
                  if (state) {
                    handleChange("recipientContactName", fullName);
                    handleChange("recipientContactEmail", email);
                    handleChange("recipientContactPhone", phone);
                    setValidationErrors((prevErrors) => {
                      const updatedErrors = { ...prevErrors };
                      delete updatedErrors.recipientContactName;
                      delete updatedErrors.recipientContactEmail;
                      delete updatedErrors.recipientContactPhone;
                      return updatedErrors;
                    });
                  } else {
                    handleChange("recipientContactName", "");
                    handleChange("recipientContactEmail", "");
                    handleChange("recipientContactPhone", "");
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer}>
              Will the account owner be the recipient?
            </Text>
          </View>
          <FormControl.Label>Recipient Name</FormControl.Label>
          <View
            style={[
              { borderRadius: "14px" },
              validationErrors?.recipientContactName
                ? { borderColor: "#FF0000", borderWidth: 1 }
                : {},
            ]}
          >
            <Input
              value={localInputs.recipientContactName}
              onChangeText={(value) => {
                handleChange("recipientContactName", value);
              }}
              type="text"
              isDisabled={localInputs.checkboxRecipient}
              onBlur={(e) =>
                validateSingleField("recipientContactName", e.nativeEvent.text)
              }
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <User />
                </View>
              }
              placeholder="Full Name"
            />
          </View>
          <View style={styles.divider} />
          <FormControl.Label>Recipient Email</FormControl.Label>
          <View
            style={[
              { borderRadius: "14px" },
              validationErrors?.recipientContactEmail
                ? { borderColor: "#FF0000", borderWidth: 1 }
                : {},
            ]}
          >
            <Input
              value={localInputs.recipientContactEmail}
              onChangeText={(value) => {
                handleChange("recipientContactEmail", value);
              }}
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              type="email"
              isDisabled={localInputs.checkboxRecipient}
              onBlur={(e) =>
                validateSingleField("recipientContactEmail", e.nativeEvent.text)
              }
              autoCorrect={false}
              autoCompleteType="email"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Email />
                </View>
              }
              placeholder="Email Address"
            />
          </View>
          <View style={styles.divider} />
          <FormControl.Label>Recipient Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isRecipientPhoneFocused
                  ? "#1A1A1A"
                  : validationErrors?.recipientContactPhone
                  ? "#FF0000"
                  : "#A0A0A0",
                backgroundColor: isRecipientPhoneFocused ? "#F5F5F5" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                // outline: isRecipientPhoneFocused ? "1px solid #1DCE80" : "none",
                opacity: localInputs.checkboxRecipient ? 0.5 : 1,
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <Text>+1 </Text>
            <PhoneInput
              value={localInputs.recipientContactPhone}
              onChange={(value) => {
                handleChange("recipientContactPhone", value);
              }}
              type="tel"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isRecipientPhoneFocused ? "#F5F5F5" : "white",
                fontSize: 16,
              }}
              onFocus={() => setIsRecipientPhoneFocused(true)}
              onBlur={(e) => {
                setIsRecipientPhoneFocused(false);
                validateSingleField("recipientContactPhone", e.target.value);
              }}
              disabled={localInputs.checkboxRecipient}
            />
          </View>
          <FormControl.Label style={{ marginTop: 20 }}>
            Save this information if you place orders frequently to deliver here
          </FormControl.Label>
          <View style={{ width: 127 }}>
            <Button
              isDisabled={
                !active ||
                !isEdited ||
                !(deliveryLocation || localInputs.deliveryLocation) ||
                !localInputs.recipientContactName ||
                !localInputs.recipientContactEmail ||
                !localInputs.recipientContactPhone
              }
              onPress={handleOpenSavePlaceModal}
              variant="outline"
            >
              Save place
            </Button>
          </View>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Desired delivery date
          </Text>
          {/* <View style={styles.divider} /> */}
          <FormControl.Label>Delivery Date</FormControl.Label>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <View
                style={{
                  borderWidth: 1,
                  borderRadius: 12,
                  borderColor: validationErrors?.deliveryDate1
                    ? "#FF0000"
                    : "#E5E5E5",
                  padding: 12,
                  minWidth: "150px",
                }}
              >
                <input
                  style={{
                    borderWidth: 0,
                    color: "#818181",
                    fontSize: 16,
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => {
                    const selectedDate = e.target.value;
                    const diffDays = getDateDifference(
                      localInputs.pickUpDate1,
                      selectedDate
                    );
                    if (diffDays > 12) {
                      //  handleChange("pickUpDate1", "");
                      toast.show({
                        description:
                          "Pickup date must be within 12 days before delivery",
                        ...toastWarningConfig,
                      });
                      return;
                    }
                    setLocalInputs((prev) => ({
                      ...prev,
                      ["deliveryDate1"]: selectedDate,
                    }));
                    validateSingleField("deliveryDate1", e.target.value);
                  }}
                  value={localInputs.deliveryDate1 || ""}
                  type="date"
                  name="deliveryDate1"
                  min={localInputs.pickUpDate1}
                  max={addDays(localInputs.pickUpDate1, 12)}
                />
              </View>
            </View>
            <View style={styles.checkbox}>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.flexibleDeliveryDate}
                onChange={(state) =>
                  setLocalInputs((prev) => ({
                    ...prev,
                    ["flexibleDeliveryDate"]: state,
                  }))
                }
                accessibilityLabel="checkbox"
              />
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Pressable {...triggerProps}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.textContainer}>Flexible Date </Text>
                        <IoAlertCircleOutline
                          size={18}
                          style={{ cursor: "pointer" }}
                        />
                      </View>
                    </Pressable>
                  );
                }}
                placement="top"
              >
                <Popover.Content maxWidth="350px">
                  <Popover.Arrow bg="gray.100" />
                  <Popover.CloseButton />
                  <Popover.Header bg="gray.100">
                    Flexible Delivery Date
                  </Popover.Header>
                  <Popover.Body bg="gray.100">
                    Choosing a flexible date allows the carrier to plan their
                    route, potentially leading to a more cost-effective
                    transportation offer for you.
                  </Popover.Body>
                </Popover.Content>
              </Popover>
            </View>
          </View>
          <FormControl.Label style={{ marginTop: 4 }}>
            Please ensure the delivery date is within 12 days after the pickup
            date.
          </FormControl.Label>

          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={
                  localInputs.hasDeliveryNotes || localInputs.deliveryNotes
                }
                onChange={(value) => {
                  handleChange("hasDeliveryNotes", value);
                  if (!value) {
                    handleChange("deliveryNotes", "");
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer}>
              I have delivery instructions
            </Text>
          </View>

          {(localInputs.hasDeliveryNotes || localInputs.deliveryNotes) && (
            <>
              <TextArea
                // value={vehicle.customChanges}
                // onChangeText={(value) => {
                //   handleChangeVehicleDetail(index, {
                //     customChanges: value,
                //   });
                // }}
                defaultValue={localInputs.deliveryNotes}
                onBlur={(e) => {
                  const newValue = e.nativeEvent.text.trim();
                  if (newValue !== localInputs.deliveryNotes) {
                    handleChange("deliveryNotes", newValue);
                  }
                }}
                placeholder="Please provide any information that carrier may need at delivery."
              />
            </>
          )}

          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={prevStep}
            prevText={
              profileOrder && profileOrder.status === "pickedUp"
                ? "Cancel"
                : "Previous"
            }
          />
        </View>
      </View>
      <CancelPINAlert />
      <AlertDialog
        isOpen={isModalVisible}
        onClose={handleCloseSavePlaceModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Name Your Delivery Place</AlertDialog.Header>
          <AlertDialog.Body>
            <Input
              placeholder="Enter a name for the place"
              value={localInputs.placeName1}
              onChangeText={handleChangePlaceName}
              autoFocus
            />
            <View style={{ height: 20 }}></View>
            <Text>{localInputs.deliveryLocation}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.recipientContactName}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.recipientContactEmail}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{formatPhone(localInputs.recipientContactPhone)}</Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={handleCloseSavePlaceModal}
              >
                Cancel
              </Button>
              <Button
                style={styles.confirmButton}
                onPress={handleConfirmSavePlaceModal}
              >
                Confirm
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
